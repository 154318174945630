import { AfterContentInit, Component, OnInit } from '@angular/core'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-revendedor',
	templateUrl: './revendedor.component.html',
	styleUrls: ['./revendedor.component.scss'],
})
export class RevendedorComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}
    lang: string = 'pt'
	dataCardDelaer: any = []
	dataSteps: any = [
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
		{
			icon: './assets/images/revendedor/icon4.png',
			desc: 'Preencha o formulário de cadastro',
		},
	]
	dataRequirements: any = [
		{
			icon: './assets/images/revendedor/icon5.png',
			text: 'Investimento Inicial entre R$ 100 mil e R$ 300 mil',
		},
		{
			icon: './assets/images/revendedor/icon5.png',
			text: 'Tamanho da Loja Mínimo de 45m²',
			subText: '* Loja Bandeira: mínimo 25m2 de showroom'
		},
		{
			icon: './assets/images/revendedor/icon5.png',
			text: 'Equipe mínima de 1 administrativo, 1 vendedor e 1 técnico instalador',
		},
	]
	numberInit:any = 0
	numberYears: any = 0
	numberDealers: any = 0
	ngOnInit(): void {
        this.lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage('pages/?type=cms.BeAResaller&fields=*&', this.lang, {
			fnSuccess(data) {
				self.dataBeSeller = data.items[0]				
				self.startCounting(self.dataBeSeller.your_business_counter_first_number, self.dataBeSeller.your_business_counter_second_number)
                self.title.setTitle(
					data.items[0].meta.seo_title || 'Seja um Revendedor'
				)
				self.meta.addTag({
					name: 'description',
					content: data.items[0].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[0].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					},
					"property='og:title'"
				)
				self.dataCardDelaer = [
					{
						title: self.dataBeSeller.store_model_title_first,
						subTitle: self.dataBeSeller.store_model_subtitle_first,
						text: self.dataBeSeller.store_model_description_first,
						img: self.dataBeSeller.store_model_image_first
					},
					{
						title: self.dataBeSeller.store_model_title_second,
						subTitle: self.dataBeSeller.store_model_subtitle_second,
						text: self.dataBeSeller.store_model_description_second,
						img: self.dataBeSeller.store_model_image_second
					},
					{
						title: self.dataBeSeller.store_model_title_third,
						subTitle: self.dataBeSeller.store_model_subtitle_third,
						text: self.dataBeSeller.store_model_description_third,
						img: self.dataBeSeller.store_model_image_third
					},
				]
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
	startCounting(endNumberYears: number, endNumberDealers:number) {
		if (endNumberYears > 0 || endNumberDealers > 0) {
		  let currentNumberYears = 0;
		  let currentNumberDealers = 0;
		  const intervalYears = setInterval(() => {
			this.numberYears = currentNumberYears
			if (currentNumberYears >= endNumberYears) {
			  clearInterval(intervalYears);
			}
			currentNumberYears++;
		  }, 50);
		  const intervalDealers = setInterval(() => {

			this.numberDealers = currentNumberDealers;
			if (currentNumberDealers >= endNumberDealers) {
			  clearInterval(intervalDealers);
			}
			currentNumberDealers++;
		  }, 5);
		  
		}
	  }
    toTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
        });
    }
	carrossel: any

	dataBeSeller: any
}
