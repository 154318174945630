import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-carousel-images',
  templateUrl: './carousel-images.component.html',
  styleUrls: ['./carousel-images.component.scss']
})
export class CarouselImagesComponent implements OnInit {

    @Input() data: any = []

    slideConfig = {
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        speed: 300,
        slidesToShow: 5,
        mobileFirst: true,
        swipeToSlide: true,
        variableWidth: true,
        touchThreshold: 4,
        centerMode: false,
        centerPadding: '100px',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                }
            },
        ]
    }
  constructor() { }

  ngOnInit(): void {
  }

}
