<div
	class="product-item"
	[ngClass]="{ 'clean-card': type === 'clean', 'ice-carousel': isIceCarousel, 'ice-card': isIceMachine }"
	*ngIf="item !== null"
>
	<div class="compare" *ngIf="isCarouselCard">
		<app-compare-button
			[compareBtn]="compareBtn"
			(compare)="onCompare(item)"
			[disable]="disable!"
			[product]="item.name"
		></app-compare-button>
	</div>
	<app-image
		*ngIf="item.gallery[0]"
		[path]="item.gallery[0].image.url"
		[title]="item.name"
	></app-image>
	<div class="line"></div>
	<h2 class="title-section" [innerHTML]="item.name"></h2>
    <div class="desc" *ngIf="!item.production_quantity">
        <p [innerHTML]="item.description"></p>
    </div>

    <button *ngIf="item.production_quantity" type="button" style="width: 100%;" (click)="onModalOpenInfo($event, item)">
        <app-mybutton
            type="responsive"
            [typeBtn]="'purchase'"
            text="Saiba Mais"
        ></app-mybutton>
    </button>
	<div
		class="button"
		*ngIf="isCarouselCard && !isIceMachine"
		[ngStyle]="{ display: isPlus ? 'none' : 'block' }"
	>
		<app-mybutton
			type="responsive"
			[typeBtn]="'purchase'"
			[gaProduct]="'Conhecer produto - ' + item.name"
			[text]="buyBtn"
			[href]="item.buy_now"
		></app-mybutton>
	</div>
	<div *ngIf="!item.details_page && !isIceCarousel" class="button">
		<app-mybutton
			type="responsive"
			[gaProduct]="'Conhecer produto - ' + item.name"
			[text]="knowBtn"
			[routerLink]="isIceMachine ? '/' + lang + '/produto/maquinas-de-gelo' : '/' + lang + '/produto/'"
		></app-mybutton>
	</div>
	<div *ngIf="isPurifierCard && item.details_page && !isIceCarousel" class="button">
		<app-mybutton
			[type]="btnType"
			[gaProduct]="'Conhecer produto - ' + item.name"
			[text]="knowBtn"
			[routerLink]="isIceMachine ? '/' + lang + '/produto/maquinas-de-gelo' : '/' + lang + '/produto/' + item.details_page.slug"
		></app-mybutton>
	</div>
</div>
