<div class="page" *ngIf="data && compareProductTitle">
	<app-purifiers-banner
		[title]="iceMachinePage ? iceMachineBannerText : purifiersBannerText"
		[subtitle]="purifiersBannerSubtext"
		[isIceMachinePage]="iceMachinePage"
		[filters]="iceMachinePage ? { ice_production, deposit, dimensions } : { temperatures, serves, sizes, colors }"
	></app-purifiers-banner>
<!--    Filtro de purificadores-->
<!--	<div class="filters" *ngIf="!iceMachinePage">-->
<!--		<div class="filters__card">-->
<!--			<app-filters-->
<!--				[data]="temperatures"-->
<!--				[allOpt]="filtersAllOpt"-->
<!--				[placeHolder]="filtersTemperaturePlaceholder"-->
<!--				[icon]="'./assets/images/i3.png'"-->
<!--				class="teste"-->
<!--				(changeValue)="setFilter($event, 1)"-->
<!--			></app-filters>-->
<!--			<app-filters-->
<!--				[data]="serves"-->
<!--				[placeHolderSelect]="filtersAllOpt"-->
<!--				[placeHolder]="filtersServesPlaceholder"-->
<!--				[icon]="'./assets/images/i4.png'"-->
<!--				class="teste"-->
<!--				(changeValue)="setFilter($event, 2)"-->
<!--			></app-filters>-->
<!--			<app-filters-->
<!--				[data]="sizes"-->
<!--				[placeHolderSelect]="filtersAllOpt"-->
<!--				[placeHolder]="filtersSizesPlaceholder"-->
<!--				[icon]="'./assets/images/i2.png'"-->
<!--				class="teste"-->
<!--				(changeValue)="setFilter($event, 3)"-->
<!--			></app-filters>-->
<!--			<app-filters-->
<!--				[data]="colors"-->
<!--				[placeHolderSelect]="filtersAllOpt"-->
<!--				[placeHolder]="filtersColorsPlaceholder"-->
<!--				[icon]="'./assets/images/i1.png'"-->
<!--				class="teste"-->
<!--				(changeValue)="setFilter($event, 4)"-->
<!--			></app-filters>-->
<!--		</div>-->
<!--	</div>-->
	<div class="filters" *ngIf="iceMachinePage">
		<div class="filters__card">
			<app-filters
				[data]="ice_production"
				[allOpt]="filtersAllOpt"
				[placeHolder]="filtersProductionPlaceholder"
				[icon]="'./assets/images/i3.png'"
				class="teste"
				(changeValue)="setFilter($event, 1)"
			></app-filters>
			<app-filters
				[data]="deposit"
				[placeHolderSelect]="filtersAllOpt"
				[placeHolder]="filtersDepositPlaceholder"
				[icon]="'./assets/images/i4.png'"
				class="teste"
				(changeValue)="setFilter($event, 2)"
			></app-filters>
			<app-filters
				[data]="dimensions"
				[placeHolderSelect]="filtersAllOpt"
				[placeHolder]="filtersDimensionsPlaceholder"
				[icon]="'./assets/images/i2.png'"
				class="teste"
				(changeValue)="setFilter($event, 3)"
			></app-filters>
		</div>
	</div>
	<app-compare
		[list]="compareList"
		id="target"
		(openTable)="onOpenTable()"
		[compareButtonOpenTable]="compareButtonOpenTable"
		[addButton]="compareButtonAdd"
		[compareProductTitle]="compareProductTitle"
		[compareProductPluralTitle]="compareProductPluralTitle"
	></app-compare>
	<app-compare-table
		[list]="compareList"
		[showTable]="showTable"
		(tableClose)="onTableClose()"
		[fields]="fields"
		[iceMachinePage]="iceMachinePage"
		[lang]="lang"
	></app-compare-table>
    <div class="">
        <div class="purifier-container" *ngIf="iceMachinePage && productsFiltered.length > 0">
            <div class="purifier-content" *ngFor="let purifier of productsFiltered">
                <app-card
                    [isIceMachine]="iceMachinePage"
                    [btnType]="'product'"
                    [buyBtn]="buyBtn"
                    [knowBtn]="knowBtn"
                    [isPurifierCard]="true"
                    [compareBtn]="compareButton"
                    [item]="purifier"
                    (compareItem)="onCompareItem($event)"
                    [disable]="compareList.length === 4"
                ></app-card>
            </div>
        </div>

        <div class="container pb-5" *ngIf="iceMachinePage && productsFiltered.length === 0">
            <h3 class="text-center">{{ noResultsFound }}</h3>
        </div>

    </div>
    <div *ngIf="!iceMachinePage">
        <div class="container">
            <ul class="nav desktop pt-5">
                <li *ngFor="let item of nav; index as i">
                    <a [href]="lang + '/purificadores' + item.path" (click)="toggleItem(i, item.path, $event)" class="nav-item" [ngClass]="{'active': active === i}">{{item.title}}</a>
                </li>
            </ul>
            <div class="allModels" id="allModel" >
                <h4 class="title">{{allModels}}. <span>{{chooseYours}}.</span></h4>
            </div>
        </div>
        <div
            class="container-left"
            *ngIf="productsFiltered.length == data.length && !iceMachinePage"
        >
            <div class="carousel">
                <ngx-slick-carousel
                    class="carousels"
                    #slickModal="slick-carousel"
                    [config]="slideConfig"
                    (afterChange)="onAfterChange($event)"
                >
                    <div ngxSlickItem *ngFor="let purifier of dataPurifers">
                        <app-card-purifiers
                            [isIceMachine]="iceMachinePage"
                            [btnType]="'responsive'"
                            [buyBtn]="buyBtn"
                            [knowBtn]="knowBtn"
                            [isPurifierCard]="true"
                            [compareBtn]="compareButton"
                            [item]="purifier"
                            (compareItem)="onCompareItem($event)"
                            [disable]="compareList.length === 4"
                            [lang]="lang"
                        ></app-card-purifiers>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <app-banner-blue [data]="dataPage"></app-banner-blue>
        <div class="container">
            <div class="highlights" id="highlight">
                <div class="title py-4" [innerHTML]="dataPage.card_text"></div>
                <ngx-slick-carousel
                    #slickCarousel
                    [config]="slideConfigHighlight"
                >
                    <div ngxSlickItem *ngFor="let item of dataPage.images_list_products; let i = index">
                        <app-card-highlight
                            [index]="i"
                            [data]="item">
                        </app-card-highlight>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <div class="bestSeller" id="bestSeller">
            <div class="title container pb-4" [innerHTML]="dataPage.text_products_most_sold"></div>
            <ngx-slick-carousel
                #slickCarousel
                [config]="slideConfigProducts"
            >
                <div ngxSlickItem *ngFor="let item of dataPage.carousels">
                    <app-card-products
                        [data]="item.product">
                    </app-card-products>
                </div>
            </ngx-slick-carousel>
        </div>
        <div class="title py-5 container" [innerHTML]="dataPage.banner_pre_title"></div>
        <app-banner-cta [data]="dataPage"></app-banner-cta>
    </div>
</div>
