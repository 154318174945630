<div class="container" *ngIf="data">
	<div class="banner">
		<div class="banner__content">
      <div class="banner__content__img" *ngIf="data.banner_primary_image">
        <img [src]="data.banner_primary_image.url" alt="maquinas de gelo" />
      </div>
      <div class="banner__content__txt">
        <div class="banner__content__txt__title">
          <h1 [innerHTML]="data.banner_primary_title">Máquinas de Gelo</h1>
        </div>
        <div class="banner__content__txt__desc">
          <h2 [innerHTML]="data.banner_primary_subtitle"></h2>
        </div>
        <!-- <div class="banner__content__txt__btn">
          <button type="button" (click)="onClick($event)">
            <app-mybutton
              type="responsive"
              [typeBtn]="'purchase'"
              [text]="data.banner_primary_label"
            ></app-mybutton>
          </button>
        </div> -->
      </div>
    </div>
	</div>
</div>
