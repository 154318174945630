<div class="banner-blue" >
    <div class="banner-blue-image">
        <picture class="desktop">
            <img [src]="data.banner_primary_image.url">
        </picture>
        <picture class="mobile">
            <img  class="w-100" [src]="data.banner_primary_image_extra.url">
        </picture>
        <div class="banner-blue-content">
            <div class="banner-blue-text mb-4" [innerHTML]="data.banner_primary_title"></div>
            <div style="max-width: 218px">
                <app-mybutton
                    *ngIf="data.banner_primary_link && data.banner_primary_label"
                    type="responsive"
                    [typeBtn]="'purchase'"
                    [text]="data.banner_primary_label"
                    [href]="data.banner_primary_link"
                ></app-mybutton>
            </div>
        </div>

    </div>
</div>
