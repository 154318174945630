<div class="trust container" *ngIf="card" >
    <div class="trust-content">
        <div class="trust-overlap container">
            <div class="trust-drinking-water">
                <app-image [path]="card.block_image.url"></app-image>
            </div>
            <div class="trust-card">
                <app-white-card [title]="card.block_title" [body]="card.block_text" [cards]="card" type="clean" [showButton]="false"></app-white-card>
            </div>
        </div>
    </div>
</div>
