<section class="grid-text container">
    <div class="wrapper-content" *ngFor="let item of data">
        <div class="wrapper-content-text">
            <figure class="logo" *ngIf="item.logo">
                <img [src]="item.logo.url" alt="item.logo.title">
            </figure>
            <div class="content-text" [innerHTML]="item.description">
            </div>
        </div>
        <figure class="image">
            <img [src]="item.image.url" [alt]="item.image.title">
        </figure>
    </div>
</section>
