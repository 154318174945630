<div class="card" *ngIf="cards"
     [ngClass]="{'card-large': type === 'large',
     'card-small': type === 'small',
     'card-clean': type === 'clean',
     'card-clean2': type === 'clean2'}"
>
    <div class="card-body">

        <p *ngIf="cards.subtitle" class="subtitle" [innerHtml]="cards.subtitle || subtitle"></p>
        <h1 class="title" [innerHTML]="title || cards.title"></h1>
        <p class="body1" [innerHTML]="body || cards.body"></p>
        <table *ngIf="type === 'large'">
            <tr *ngFor="let card of cards.text_list">
                <td>
                    <img *ngIf="card.icon" class="image" [src]="card.icon.url || './assets/images/table-diferencial.png'">
                </td>
                <td class="body1" [innerHTML]="card.title"></td>
            </tr>
        </table>
        <div style="max-width: 218px">
            <app-mybutton [text]="cards.ctaLabel" type="responsive" [routerLink]="cards.ctaLink" [ga]="title || cards.title + ' - ' + cards.ctaLabel" *ngIf="showButton"></app-mybutton>
        </div>
    </div>
</div>
