<div class='modal-body'>
    <h5>Esqueci minha senha</h5>
    <div class='wrapper-form'>
        <app-forgot-password (finalized)='close()'></app-forgot-password>
    </div>
</div>
<!--<div class='modal-footer'>-->
<!--    <button-->
<!--        type='button'-->
<!--        class='btn outline'-->
<!--        (click)='close()'-->
<!--    >-->
<!--        Cancelar-->
<!--    </button>-->
<!--    <button type='button' class='btn btn-xs' (click)='confirmation()'>-->
<!--        Confirmar-->
<!--    </button>-->
<!--</div>-->
