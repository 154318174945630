import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewEncapsulation,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {IceMachineDetails2Component} from "../modals/ice-machine-details-2/ice-machine-details-2.component";

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
	@Input() item: any
	@Input() type = ''
	@Input() btnType = ''
	@Input() isPurifierCard? = false
	@Input() isCarouselCard? = true
	@Input() disable? = false
	@Input() compareBtn? = ''
	@Input() knowBtn = 'Conhecer produto'
	@Input() buyBtn = ''
	@Input() dataDictionary = {}
    @Input() lang: string = 'pt'
	isPlus: boolean = false
	@Input() isIceMachine: boolean = false
	@Input() isIceCarousel: boolean = false
    @Output() openModal: EventEmitter<{ showModal: boolean, name: string }> = new EventEmitter();
	constructor(private route: ActivatedRoute, private modal: NgbModal,) {}

	@Output() compareItem = new EventEmitter()

	onCompare(item: any) {
		this.compareItem.emit(item)
	}
    onModalOpenInfo(event: any,data: any) {
        event.preventDefault();
        const modalOptions: NgbModalOptions = {
            size: 'lg'
        };
        const modalRef = this.modal.open(IceMachineDetails2Component, modalOptions)
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.lang = this.lang;
        modalRef.componentInstance.dictionary = this.dataDictionary;
    }
	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		if (this.item && this.item.line == 'PLUS') {
			this.isPlus = true
		}
	}
}
