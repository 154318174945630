import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-carousel-reseller',
	templateUrl: './carousel-reseller.component.html',
	styleUrls: ['./carousel-reseller.component.scss'],
})
export class CarouselResellerComponent implements OnInit {
	constructor() {}

	@Input() data: any
	ngOnInit(): void {}

	slideConfig = {
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 'calc((100% - 358px) / 2)',
		dots: false,
		arrows: false,
		infinite: false,
		speed: 300,
		slidesToShow: 1,
		mobileFirst: true,
		swipeToSlide: true,
	}

	progressBarValue = 33

	onAfterChange(event: any) {
		const slideCount = this.data.length
		const currentIndex = event.currentSlide
		const progressPercentage = ((currentIndex + 1) / slideCount) * 100
		this.progressBarValue = progressPercentage
	}
}
