import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from 'src/app/components/modals/modal-default/modal-default.component'
import { ContentService } from 'src/app/services/content/content.service'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'

@Component({
    selector: 'app-revenda',
    templateUrl: './revenda.component.html',
    styleUrls: ['./revenda.component.scss'],
})
export class RevendaComponent implements OnInit {
    constructor(
        private formBuilder: FormBuilder,
        private content: ContentService,
        private modal: NgbModal,
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            name: [
                null,
                [Validators.required, GenericValidatorService.completeName],
            ],
            document_number: [
                null,
                [Validators.required, GenericValidatorService.isValidCpfCnpj],
            ],
            phone: [
                null,
                [Validators.required, GenericValidatorService.validatePhone],
            ],
            cell_phone: [
                null,
                [
                    Validators.required,
                    GenericValidatorService.validatePhone,
                ],
            ],
            email: [null, [Validators.required, Validators.email]],
            model: [null, [Validators.required]],
        })
    }

    models: any[] = [
        { value: 'everest plus', label: 'Everest Plus' },
        { value: 'everest star', label: 'Everest Star' },
        { value: 'everest slim', label: 'Everest Slim' },
        { value: 'everest fit', label: 'Everest Fit' },
    ]

    mapsText: string = ''
    mapsTextButton: string = ''
    whereToFindPageTitle: string = ''
    whereToFindResellerPageSubtitle: string = ''
    whereToFindResellerText: string = ''
    whereToFindPagePostal: string = ''
    whereToFindCategory: string = ''
    lang = 'pt'
    findAddress: string = ''
    mapsAddress: any = ''
    mapsNeighborhood: string = ''
    mapsCity: string = ''
    mapsState: string = ''
    mapsDistance: string = ''
    notKnowCep = ''
    dataTabs: any = {}

    ngOnInit(): void {
        this.lang = this.route.snapshot.params.lang
        window.localStorage.setItem('lang', this.lang)
        const self = this

        this.content.getPage('dictionary/?limit=9999&', this.lang, {
            fnSuccess: (data: any) => {
                data.items.map((item: any) => {
                    if (item.key === 'whereToFindPageTitle') {
                        this.whereToFindPageTitle = item.value
                    } else if (item.key === 'whereToFindResellerPageSubtitle') {
                        this.whereToFindResellerPageSubtitle = item.value
                    } else if (item.key === 'whereToFindCategory') {
                        this.whereToFindCategory = item.value
                    } else if (item.key === 'whereToFindResellerText') {
                        this.whereToFindResellerText = item.value
                    } else if (item.key === 'whereToFindPagePostal') {
                        this.whereToFindPagePostal = item.value
                    } else if (item.key === 'mapsTextButton') {
                        this.mapsTextButton = item.value
                    } else if (item.key === 'modalSuccessTitle') {
                        this.modalSuccessTitle = item.value
                    } else if (item.key === 'findAddress') {
                        this.findAddress = item.value
                    } else if (item.key === 'mapsAddress') {
                        this.mapsAddress = item.value
                    } else if (item.key === 'mapsNeighborhood') {
                        this.mapsNeighborhood = item.value
                    } else if (item.key === 'mapsCity') {
                        this.mapsCity = item.value
                    } else if (item.key === 'mapsState') {
                        this.mapsState = item.value
                    } else if (item.key === 'notKnowCep') {
                        this.notKnowCep = item.value
                    } else if (item.key === 'modalSuccessText') {
                        this.modalSuccessText = item.value
                    } else if (item.key === 'modalErrorTitle') {
                        this.modalErrorTitle = item.value
                    } else if (item.key === 'mapsDistance') {
                        this.mapsDistance = item.value
                    }
                })
            },
            fnError: (error: any) => console.warn(error),
        })
        this.content.getCategories('categories/', {
            fnSuccess(data) {
                self.dataTabs = data.items.filter((item: any) => item.slug !== 'elemento-filtrante')
            },
            fnError(err) {
                console.warn(err)
            },
        })
        this.title.setTitle('Onde Encontrar')
        this.meta.addTag({
            name: 'description',
            content: 'teste',
        })
        this.meta.updateTag(
            {
                property: 'og:description',
                content: 'teste',
            },
            "property='og:description'"
        )
        this.meta.updateTag(
            {
                property: 'og:title',
                content: 'teste',
            },
            "property='og:title'"
        )
    }
    onSubmit() {
        const self = this
        if (this.form.valid) {
            const { name, document_number, phone, cell_phone, email, model } =
                this.form.value
            this.content.sendForm('budget_form', {
                payload: {
                    name,
                    document_number,
                    phone,
                    cell_phone,
                    email,
                    model,
                },
                fnSuccess(data: any) {
                    self.form.reset()
                    self.openModal(
                        self.modalSuccessTitle,
                        self.modalSuccessText
                    )
                },
                fnError(err: Error) {
                    self.openModal(self.modalErrorTitle, self.modalErrorText)
                    console.warn(err)
                },
            })
        }
    }

    modalSuccessTitle: string = ''
    modalSuccessText: string = ''
    modalErrorTitle: string = ''
    modalErrorText: string = ''

    openModal(
        title: string = '',
        description: string = '',
        icon: string = 'alert.svg'
    ): void {
        const modalRef = this.modal.open(ModalDefaultComponent)
        modalRef.componentInstance.title = title || 'Opa!'
        modalRef.componentInstance.desc =
            description ||
            'Algo não aconteceu como deveria, por favor tente novamente.'
        modalRef.componentInstance.figure = '/assets/images/' + icon
        modalRef.componentInstance.showFooter = false
    }

    modalActive: boolean = false
    routeAddress: string = ''
    routeLocalAddress: string = ''

    onOpen(event: any) {
        this.modalActive = true
        this.routeAddress = `${event.address} - ${event.neighborhood}, ${event.city} - ${event.state}, ${event.zip_code}`
    }

    closeModal() {
        this.modalActive = false
    }

    getRoute() {
        const from: string = this.routeLocalAddress.split(' ').join('+')
        const to: string = this.routeAddress.split(' ').join('+')
        const url: string = `https://www.google.com.br/maps/dir/${from}/${to}`
        window.open(url, '_blank')!.focus()
    }

    form: FormGroup
}
