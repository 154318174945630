<div class="modal-container container">
	<div class="modal-close" (click)="close()">
		<button class="close">
			<img src="./assets/images/Cross.svg" alt="" />
		</button>
	</div>
	<div class="modal-header">
		<img src="./assets/images/gelos.png" alt="" />
		<p [innerHTML]="inputFieldLabels.title"></p>
	</div>
	<div class="modal-body">
		<form
			action="#"
			autocomplete="off"
			(ngSubmit)="onSubmit()"
			[formGroup]="form"
		>
			<div class="row">
				<div class="col-md-12">
					<app-input
						[placeholder]="''"
						lettersOnly
						[type]="'revenda'"
						id="revenda-name"
						name="name"
						[labelName]="inputFieldLabels.inputName"
						[placeholder]="''"
						[form]="form"
					>
					</app-input>
				</div>
				<div class="col-md-12">
					<app-input
						[placeholder]="inputFieldLabels.onlyNumbersPlaceHolder"
						[type]="'revenda'"
						id="revenda-cnpj"
						name="cnpj"
						[labelName]="inputFieldLabels.inputCnpj"
						[form]="form"
						[inputMask]="'00.000.000/0000-00'"
					>
					</app-input>
				</div>
				<div class="col-md-12">
					<app-input
						[placeholder]="''"
						[type]="'revenda'"
						id="revenda-email"
						name="email"
						typeInput="email"
						[labelName]="inputFieldLabels.inputEmail"
						[form]="form"
					>
					</app-input>
				</div>
				<div class="col-md-12">
					<app-input
						[placeholder]="''"
						[type]="'revenda'"
						id="revenda-phone"
						name="phone"
						numbersOnly
						[labelName]="inputFieldLabels.inputPhone"
						[form]="form"
					>
					</app-input>
				</div>
				<div class="col-md-12">
					<app-input
						[placeholder]="inputFieldLabels.onlyNumbersPlaceHolder"
						[type]="'revenda'"
						id="revenda-cep"
						name="cep"
						[inputMask]="'00000-000'"
						[labelName]="inputFieldLabels.inputCep"
						[form]="form"
					>
					</app-input>
				</div>
				<div class="col-md-12">
					<app-checkbox
						id="revenda-privacy_policy"
						name="privacy_policy"
						[labelName]="inputFieldLabels.inputTerms"
						[form]="form"
					>
					</app-checkbox>
				</div>
				<button
					type="submit"
					[disabled]="form.invalid"
					class="button"
					[innerHTML]="inputFieldLabels.inputSend"
				></button>
			</div>
		</form>
	</div>
</div>
