import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[lettersOnly]',
})
export class LettersOnlyDirective {
	@HostListener('input', ['$event'])
	onInput(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement
		const value = input.value
		const sanitizedValue = value.replace(
			/[^A-Za-záàâããéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑüÜ´`^~¨'"\s]+$/g,
			''
		)

		if (value !== sanitizedValue) {
			input.value = sanitizedValue
      return { textInvalid: true }
		}
    return null
	}
}
