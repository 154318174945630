<div class="container">
    <div class="carousel">
        <ngx-slick-carousel
            class="carousel"
            #slickCarousel
            [config]="slideConfig"
            (afterChange)="onAfterChange($event)"
        >
            <div ngxSlickItem *ngFor="let data of data.differential" class="slide">
                <app-post-differential [data]="data"></app-post-differential>
            </div>
        </ngx-slick-carousel>
    </div>
    <div class="slick-progress-bar-container">
        <div class="slick-progress-bar" [style.width.%]="progressBarValue"></div>
    </div>
</div>
