<app-banner *ngIf="dataHome" [banner]="dataHome.banner" [summary]="dataHome.summary"></app-banner>
<section class="home page" *ngIf="dataHome">
    <app-whatsapp></app-whatsapp>
    <app-highlight-product [data]="dataHome"></app-highlight-product>

    <div class="carousel">
        <app-tabs>
            <app-tab *ngFor="let tab of dataTabs; let i = index" [tabTitle]="lang == 'es' ? tab.name_es : tab.name" [active]="i === 0">
                <app-carousel [data]="dataHome.carousel" [buyBtn]="buyBtn" [knowBtn]="knowBtn"></app-carousel>
            </app-tab>
        </app-tabs>
    </div>

    <app-product-banner [data]="dataHome"></app-product-banner>
    <app-know-more [data]="dataHome"></app-know-more>
    <!-- <app-reseller [data]="dataHome"></app-reseller> -->
    <!-- <app-procure-aqui></app-procure-aqui> -->
</section>