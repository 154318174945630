import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from 'src/app/services/content/content.service'

@Component({
	selector: 'app-economy-table',
	templateUrl: './economy-table.component.html',
	styleUrls: ['./economy-table.component.scss'],
})
export class EconomyTableComponent implements OnInit {
	constructor(
		private title: Title,
		private meta: Meta,
		private content: ContentService,
		private route: ActivatedRoute
	) {}
	economyTitle: string = ''
	economyGallons: string = ''
	economyWeeks: string = ''
	economyGallonsForYear: string = ''
	economyGallonsCost: string = ''
	economyResultText1: string = ''
	economyResultText2: string = ''
	economyFooterText: string = ''
	economyFooterButton: string = ''
	economyPageTitle: string = ''

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		this.content.getPage('dictionary/?limit=9999&', lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'economyTitle') {
						this.economyTitle = item.value
					} else if (item.key === 'economyGallons') {
						this.economyGallons = item.value
					} else if (item.key === 'economyWeeks') {
						this.economyWeeks = item.value
					} else if (item.key === 'economyGallonsForYear') {
						this.economyGallonsForYear = item.value
					} else if (item.key === 'economyGallonsCost') {
						this.economyGallonsCost = item.value
					} else if (item.key === 'economyResultText1') {
						this.economyResultText1 = item.value
					} else if (item.key === 'economyResultText2') {
						this.economyResultText2 = item.value
					} else if (item.key === 'economyFooterText') {
						this.economyFooterText = item.value
					} else if (item.key === 'economyFooterButton') {
						this.economyFooterButton = item.value
					} else if (item.key === 'economyPageTitle') {
						this.economyPageTitle = item.value
					}
				})
			},
			fnError: (error) => console.warn(error),
		})
		this.title.setTitle('Tabela de Ecônomia')
	}
}
