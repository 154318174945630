import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-economy-calculator',
	templateUrl: './economy-calculator.component.html',
	styleUrls: ['./economy-calculator.component.scss'],
})
export class EconomyCalculatorComponent implements OnInit {
	constructor(private route: ActivatedRoute) {}
	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
	}

	galoes: number
	media: number = 0
	atualizarResultado() {
		this.media = this.galoes * 52
		if (this.media > 9999) {
			this.media = 9999
		}
		if (this.preco != null) {
			this.atualizarMedia()
		}
	}
	validarNumeroNegativo() {
		if (this.galoes < 0) {
			this.galoes = 0
		}
	}

	preco: string
	valor: number = 0

	atualizarMedia() {
		const precoNum = parseFloat(this.preco)
		this.valor = precoNum * this.media
	}

	formatarReal(valor: number): string {
		const formatoBrasileiro = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			style: 'currency',
			currency: 'BRL',
		}
		return valor.toLocaleString('pt-BR', formatoBrasileiro)
	}

	lang: string = 'pt'

	@Input() economyTitle =
		'Ainda com dúvidas se o purificador de água é mais vantajoso? Então faça o teste e comprove!'
	@Input() economyGallons = 'Galões por semana'
	@Input() economyWeeks = 'Semanas (1 ano)'
	@Input() economyGallonsForYear = 'Galões consumidos por ano'
	@Input() economyGallonsCost = 'Custo médio do seu galão'
	@Input() economyResultText1 = 'Você gasta em média'
	@Input() economyResultText2 = 'com garrafões de água.'
	@Input() economyFooterText =
		'Agora que você já sabe que os purificadores Everest são muito mais econômicos, além de muito melhores para o meio ambiente,'
	@Input() economyFooterButton = 'Conheça nossa linha completa'
}
