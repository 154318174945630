import { Component, Input, OnInit } from '@angular/core'

export interface IReseller {
	reseller_title: string
	reseller_description: string
	reseller_cta_link: null | string
	reseller_cta_label: string
	reseller_image: null | string
}

@Component({
	selector: 'app-reseller',
	templateUrl: './reseller.component.html',
	styleUrls: ['./reseller.component.scss'],
})
export class ResellerComponent implements OnInit {
	@Input() data: IReseller

	item = {
		img: './assets/images/reseller.png',
		text: 'reseller',
	}

	constructor() {}

	ngOnInit(): void {}
}
