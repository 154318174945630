import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-product-banner',
	templateUrl: './product-banner.component.html',
	styleUrls: ['./product-banner.component.scss'],
})
export class ProductBannerComponent implements OnInit {
	constructor() {}

	@Input() data: any = []

	ngOnInit(): void {}
}
