<ul class="nav nav-tabs" [ngClass]="{'orange': type === 'images', 'blue-tabs': type == 'whereToFind', 'faq-tabs': type == 'faq'}">
	<li
		*ngFor="let tab of tabs"
		(click)="selectTab(tab)"
		[class.active]="tab.active"
	>
		<button>{{ tab.title }}</button>
		<span *ngIf="tab.alerts">{{
			tab.alerts <= 9 ? tab.alerts : '+9'
		}}</span>
	</li>
	<div class="line"></div>
</ul>
<ng-content></ng-content>
