<div class="page">
    <app-banner-internal [title]="faqBannerText"></app-banner-internal>
    <div class="tabs">
        <app-tabs [type]="'faq'">
            <app-tab [active]="true" [tabTitle]="tabTitle">
                <div class="wrapper-faq">
                    <div *ngFor="let faq of data; index as i" class="questions">
                        <app-accordion [title]='faq.question' [description]='faq.answer'>
                        </app-accordion>
                    </div>
                </div>
            </app-tab>
            <!-- <app-tab [active]="false" [tabTitle]="'Maquinas de gelo'">
                <div class="wrapper-faq">
                    <div *ngFor="let faq of listFaq; index as i" class="questions">
                        <app-accordion
                            [title]='faq.title'
                            [description]='faq.description'>
                        </app-accordion>
                    </div>
                </div>
            </app-tab> -->
        </app-tabs>
    </div>
</div>