import { Component, OnInit } from '@angular/core'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-elemento-filtrante',
	templateUrl: './elemento-filtrante.component.html',
	styleUrls: ['./elemento-filtrante.component.scss'],
})
export class ElementoFiltranteComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}

	dataFilter: any = {}
	lang: string = 'pt'

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage(
			'pages/?type=cms.FilteringElement&fields=%2A&',
			this.lang,
			{
				fnSuccess(data) {
					self.dataFilter = data.items[0]
					self.title.setTitle(
						data.items[0].meta.seo_title || 'Elemento Filtrante'
					)
					self.meta.addTag({
						name: 'description',
						content: data.items[0].meta.search_description,
					})
					self.meta.updateTag(
						{
							property: 'og:description',
							content: data.items[0].meta.search_description,
						},
						"property='og:description'"
					)
					self.meta.updateTag(
						{
							property: 'og:title',
							content: data.items[0].meta.seo_title,
						},
						"property='og:title'"
					)
				},
				fnError(err) {
					console.warn(err)
				},
			}
		)
	}
}
