import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from '../modal-default/modal-default.component'
@Component({
	selector: 'app-ice-machine-details-2',
	templateUrl: './ice-machine-details-2.component.html',
	styleUrls: ['./ice-machine-details-2.component.scss'],
})
export class IceMachineDetails2Component implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() dictionary: any = {}
    @Input() lang: string = 'pt'
    @Input() data: any;
    @Input() name: any;

	constructor(
		public activeModal: NgbActiveModal,
		private modal: NgbModal,
	) {}

	ngOnInit(): void {
    }

	close() {
		this.activeModal.close()
	}


	openModal(
		title: string = '',
		description: string = '',
		buttonLabel: string = 'confirmar'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.labelButtonConfirmation =
			buttonLabel || 'Confirmar'
		modalRef.componentInstance.showFooter = false
	}
}
