<app-ice-machine-banner  *ngIf="data" [data]="data" (openModal)="onModalOpen()"></app-ice-machine-banner>
<app-ice-images *ngIf="data"  [data]="data"></app-ice-images>
<app-ice-tabs  *ngIf="data" [data]="data.tabs" (openModal)="onModalOpenInfo($event, dataProduct)" [dataDictionary]="dictionary" [lang]="lang"></app-ice-tabs>
<app-ice-format  *ngIf="data" [data]="data"></app-ice-format>
<app-ice-machine-benefits *ngIf="data"  [data]="data"></app-ice-machine-benefits>
<div *ngIf="data"  class="highlight__product">
	<app-intern-highlight-product
		*ngIf="data.featured_image"
		[title]="data.content_title"
		[description]="data.content_text"
		[highlightTitle]="data.featured_title"
		[image]="data.featured_image.url"
	></app-intern-highlight-product>
</div>
