import { Component, Input, OnInit } from '@angular/core'
import { HelpersService } from '../../services/helpers/helpers.service'
@Component({
	selector: 'app-news',
	templateUrl: './news.component.html',
	styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
    @Input() lang: string = 'pt'
    @Input() type: string = 'default'
    @Input() data: any = {}
    constructor(private helpers: HelpersService,) {
    }

	ngOnInit(): void {

    }
    getDate(date: any) {
        return this.helpers.getDate(date)
    }
}
