import { Injectable } from '@angular/core'
import { Callbacks } from '../../models/callbacks'
import { environment } from '../../../environments/environment'
import { finalize } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(
        private http: HttpClient
    ) {
    }

    getAddressByZipCode(zipCode: string, callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}/addresses/${zipCode}/cep`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        console.log('GET ADDRESSES BY ZIP CODE SERVICE: ', data)
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }

    getStates(callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}/states/`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }

    getCities(uf: string, callback: Callbacks): void {
        this.http
            .get<any>(
                `${environment.api}/cities/?state=${uf}`
            )
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                    next: (data) => {
                        callback.fnSuccess(data)
                    },
                    error: (err) => {
                        console.warn(err)
                        callback.fnError(err)
                    }
                }
            )
    }
}
