<div class="card container" *ngIf="data">
    <div class="card-title mb-3">
        <p class="body1">{{ data.title }}</p>
        <p class="body2" *ngIf="data.subtitle">{{ data.subtitle }}</p>
    </div>
    <div class="card-body">
        <div class="card-img">
            <app-image [path]="data.image.url"></app-image>
        </div>
    </div>
    <div class="d-flex align-items-center flex-column">
        <div class="card-text">
            <a style="text-decoration: none;" *ngIf="data.file" [href]="data.file.url" [download]="data.file.title" target="_blank">
                <app-mybutton [text]="data.btn_label" type="large" [download]="true"></app-mybutton>
            </a>
        </div>
    </div>
</div>
