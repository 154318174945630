<div class="container" *ngIf="data">
    <div class="content">
        <div class="content-text">
            <p class="body1">
                {{data.image_text_first_text}}
            </p>
        </div>
        <div class="content-img">
            <app-image *ngIf="data.filter_second_image !== undefined" [path]="data.image_text_image.url || './assets/images/gota-filtrante.png'"></app-image>
        </div>
        <div class="content-text">
            <p class="body1">
                {{data.image_text_second_text}}
            </p>
        </div>
    </div>
</div>
