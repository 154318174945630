import { Component, Input, OnInit } from '@angular/core'

export interface Post {
	image: { url: string; alt: string }
	data: any
	title: string
	description: string
}

@Component({
	selector: 'app-post-differential',
	templateUrl: './post-differential.component.html',
	styleUrls: ['./post-differential.component.scss'],
})
export class PostDifferentialComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	@Input() data: Post
}
