import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from '../../models/callbacks'
import { finalize } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { ForgotUserPassword, RegisterUser, ResetUserPassword } from '../../models/user'

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private http: HttpClient
    ) {
    }

    registerUser(payload: RegisterUser, callback: Callbacks): void {
        this.http
            .post<any>(`${environment.api}/account/`, payload)
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    console.log('POST ACCOUNT REGISTER SERVICE: ', data)
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    callback.fnError(err)
                    console.warn(err)
                }
            })
    }

    me(callback?: Function): void {
        this.http
            .get<any>(`${environment.api}/account/me/`)
            .pipe()
            .subscribe({
                next: (data) => {
                    console.log('GET ME SERVICE: ', data)
                    if (callback) callback(data)
                },
                error: (err) => {
                    console.warn(err)
                }
            })
    }

    accountVerify(token: string, callback: Callbacks): void {
        this.http
            .get<any>(`${environment.api}/account/email-verify/`, {
                params: {
                    token
                }
            })
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    console.log('GET ACCOUNT VERIFY SERVICE: ', data)
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    callback.fnError(err)
                    console.warn(err)
                }
            })
    }

    resetPassword({ uidb64, token, password }: ResetUserPassword, callback: Callbacks): void {
        this.http
            .patch<any>(`${environment.api}/account/password-reset`, {
                token,
                uidb64,
                password
            })
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    console.log('PATCH ACCOUNT RESET PASSWORD SERVICE: ', data)
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    callback.fnError(err)
                    console.warn(err)
                }
            })
    }

    forgotPassword({ cpf, email }: ForgotUserPassword, callback: Callbacks): void {
        this.http
            .post<any>(`${environment.api}/account/request-reset-email/`, {
                cpf,
                email
            })
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    console.log('POST ACCOUNT FORGOT PASSWORD SERVICE: ', data)
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    callback.fnError(err)
                    console.warn(err)
                }
            })
    }

    updateUser(id: string, payload: any, callback: Callbacks): void {
        this.http
            .patch<any>(`${environment.api}/users/${id}`, payload)
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe(
                (data) => {
                    console.log('UPDATE USER SERVICE: ', data)
                    callback.fnSuccess(data)
                },
                (err) => {
                    console.warn(err)
                    callback.fnError(err)
                }
            )
    }
}
