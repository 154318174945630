<div
	class="product"
	[ngClass]="{ 'clean-card': type === 'clean', 'ice-carousel': isIceCarousel, 'ice-card': isIceMachine }"
	*ngIf="item !== null"
>
    <h2 class="title-section" [innerHTML]="item.name"></h2>
    <div>
        <picture  *ngIf="item.gallery[0].image">
            <img [src]="item.gallery[0].image.url" [alt]="item.gallery[0].name">
        </picture>
    </div>
    <div class="wrapper-colors">
        <div *ngFor="let item of item.color" class="colors">
            <svg *ngIf="item.name === 'Branco'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28" fill="none">
                <g filter="url(#filter0_i_178_2219)">
                    <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#DBDBDB"/>
                </g>
                <defs>
                    <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                    </filter>
                </defs>
            </svg>
            <svg *ngIf="item.name === 'Prata'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28" fill="none">
                <g filter="url(#filter0_i_178_2219)">
                    <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#949799"/>
                </g>
                <defs>
                    <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                    </filter>
                </defs>
            </svg>
            <svg *ngIf="item.name === 'Preto'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" fill="none">
                <g filter="url(#filter0_i_178_2219)">
                    <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#0A0A0A"/>
                </g>
                <defs>
                    <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                    </filter>
                </defs>
            </svg>
            <svg *ngIf="item.name === 'Vermelho'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 28" fill="none">
                <g filter="url(#filter0_i_178_2219)">
                    <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#B1090C"/>
                </g>
                <defs>
                    <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                    </filter>
                </defs>
            </svg>
        </div>
    </div>


	<div class="desc">
		<p *ngIf="item.price && lang === 'pt'">A partir de R$ {{ getPrice(item.price) }}</p>
	</div>
    <div
        class=" w-50"
        *ngIf="isCarouselCard && !isIceMachine"
        [ngStyle]="{ display: isPlus ? 'none' : 'block' }"
    >
        <a [href]="item.buy_now" class="button-purifiers" *ngIf="lang === 'pt'" target="_blank"> {{buyBtn}}</a>
    </div>
    <div *ngIf="isPurifierCard && item.details_page && !isIceCarousel" class="w-50">
        <button class="w-100 p-0" (click)="openModal(item)">
            <app-mybutton
                type="white-border"
                [gaProduct]="'Conhecer produto - ' + item.name"
                [text]="knowBtn"
            ></app-mybutton>
        </button>
    </div>
	<div *ngIf="!item.details_page && !isIceCarousel" class="button">
		<app-mybutton
			type="responsive"
			[gaProduct]="'Conhecer produto - ' + item.name"
			[text]="knowBtn"
			[routerLink]="isIceMachine ? '/' + lang + '/produto/maquinas-de-gelo' : '/' + lang + '/produto/'"
		></app-mybutton>
	</div>

</div>
