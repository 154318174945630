import { Component, Inject, PLATFORM_ID } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { isPlatformBrowser } from '@angular/common'

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
	lang: string = 'pt'
	platformId: Object
	constructor(
		private activeRoute: ActivatedRoute,
		private store: Store<AppState>,
		private content: ContentService,
		private meta: Meta,
		private title: Title,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		this.platformId = platformId
		if (isPlatformBrowser(this.platformId)) {
			this.lang = this.activeRoute.snapshot.params.lang
			this.getData(this.lang)
		}
	}

	dataHome: any
	dataTabs: any
	dataCarousel: any
	DataProduct: any
	filteredProducts: any
	buyBtn: string = ''
	knowBtn: string = ''
	getData(lang: string): void {
		const self = this
		self.store.dispatch(ShowLoading())
		self.content.getPage(
			'pages/?type=cms.HomePage&fields=%2A,carousel(product(*))&',
			lang,
			{
				fnSuccess: (data) => {
					self.dataHome = data.items[0]
					self.title.setTitle(data.items[0].meta.seo_title)
					self.meta.updateTag({
						property: 'og:description',
						content: data.items[0].meta.search_description,
					})
					self.meta.updateTag({
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					})
					self.meta.updateTag({
						property: 'og:url',
						content: window.location.href,
					})
				},
				fnError: (err) => {
					console.warn(err)
				},
				fnFinalized() {
					self.store.dispatch(HiddenLoading())
				},
			}
		)
		this.content.getCategories('categories/', {
			fnSuccess(data) {
				self.dataTabs = data.items.filter(
					(item: any) => item.name == 'Purificadores Everest'
				)
			},
			fnError(err) {
				console.warn(err)
			},
		})

		this.content.getPage('dictionary/?limit=9999&', lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'buyBtn') {
						this.buyBtn = item.value
					} else if (item.key === 'knowBtn') {
						this.knowBtn = item.value
					}
				})
			},
			fnError(error) {
				console.warn(error)
			},
		})
	}
}
