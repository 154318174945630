import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-modal-default',
    templateUrl: './modal-forgot-password.html',
    styleUrls: ['./modal-forgot-password.scss']
})
export class ModalForgotPassword implements OnInit {
    @Input() callbackConfirmation?: Function

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    close() {
        this.activeModal.close()
    }
}
