import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-dealer',
  templateUrl: './card-dealer.component.html',
  styleUrls: ['./card-dealer.component.scss']
})
export class CardDealerComponent implements OnInit {

  @Input() data: any
  slideConfig = {
    "slidesToShow": 1.5, 
    "slidesToScroll": 1,
    arrows: false,
    centerMode: true,
    infinite: false,
    dots: true,
  };
  constructor() { }
  ngOnInit(): void {
  }
}
