import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { finalize } from 'rxjs'
import { Callbacks } from 'src/app/models/callbacks'
import { environment } from 'src/environments/environment'
import { sendForm } from 'src/app/models/sendForm'

@Injectable({
	providedIn: 'root',
})
export class ContentService {
	constructor(private http: HttpClient) {}

	getPage(page: string, lang: string, callback: Callbacks) {
		return this.http
			.get(`${environment.api}/${page}locale=${lang}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
				},
			})
	}

	getCategories(page: string, callback: Callbacks) {
		return this.http
			.get(`${environment.api}/${page}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
				},
			})
	}
    getComparisonPage(page: string, lang: string, callback: Callbacks) {
        return this.http
            .get(`${environment.api}/${page}locale=${lang}`)
            .pipe(
                finalize(() => {
                    if (callback.fnFinalized) callback.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    callback.fnSuccess(data)
                },
                error: (err) => {
                    callback.fnError(err)
                },
            })
    }

	getMap(page: string, callback: Callbacks) {
		return this.http
			.get(`${environment.api}/${page}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
				},
			})
	}

	getProduct(slug: string, lang: string, callback: Callbacks) {
		return this.http
			.get(
				`${environment.api}/pages/?type=cms.Product&fields=*&slug=${slug}&locale=${lang}`
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
				},
			})
	}

	sendForm(page: string, body: sendForm) {
		return this.http
			.post(`${environment.api}/${page}/`, body.payload)
			.subscribe({
				next: (data) => body.fnSuccess(data),
				error: (err) => body.fnError(err),
			})
	}
    mapsForm(page: string, body: sendForm) {
		return this.http
			.post(`${environment.api}/${page}`, body.payload)
			.subscribe({
				next: (data) => body.fnSuccess(data),
				error: (err) => body.fnError(err),
			})
	}
}
