import { Component, OnInit, Input } from '@angular/core'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'

@Component({
	selector: 'app-card-download',
	templateUrl: './card-download.component.html',
	styleUrls: ['./card-download.component.scss'],
})
export class CardDownloadComponent {
	constructor(private store: Store<AppState>) {}

	@Input() data: any

	async downloadPDF(pdfUrl: string, fileName: string) {
		const self = this
		self.store.dispatch(ShowLoading())
		fetch(pdfUrl)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob)
				const a = document.createElement('a')
				a.href = url
				a.download = fileName
				document.body.appendChild(a)
				a.click()
				a.remove()
			})
		self.store.dispatch(HiddenLoading())
	}
}
