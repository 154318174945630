import { Component, OnInit } from '@angular/core'
import { FaqItem } from '../../models/faq-item'
import { ContentService } from 'src/app/services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
	listFaq: FaqItem[] = []

	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {
		this.listFaq = [
			{
				title: 'Lorem ipsum dolor sit amet consectetur.',
				description:
					'Lorem ipsum dolor sit amet consectetur. Urna quam mi quam morbi. Magnis quis sed amet sed lacus nulla tempus pharetra.',
			},
			{
				title: 'Lorem ipsum dolor sit amet consectetur.',
				description:
					'Lorem ipsum dolor sit amet consectetur. Urna quam mi quam morbi. Magnis quis sed amet sed lacus nulla tempus pharetra.',
			},
			{
				title: 'Lorem ipsum dolor sit amet consectetur.',
				description:
					'Lorem ipsum dolor sit amet consectetur. Urna quam mi quam morbi. Magnis quis sed amet sed lacus nulla tempus pharetra.',
			},
			{
				title: 'Lorem ipsum dolor sit amet consectetur.',
				description:
					'Lorem ipsum dolor sit amet consectetur. Urna quam mi quam morbi. Magnis quis sed amet sed lacus nulla tempus pharetra.',
			},
		]
	}

	tabTitle = 'Purificadores'
	data: any
	faqBannerText: string = ''

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		this.title.setTitle('Faq')
		this.meta.addTag({
			name: 'description',
			content: 'teste',
		})
		this.meta.updateTag(
			{
				property: 'og:description',
				content: 'teste',
			},
			"property='og:description'"
		)
		this.meta.updateTag(
			{
				property: 'og:title',
				content: 'teste',
			},
			"property='og:title'"
		)
		const self = this
		this.content.getPage('dictionary/?limit=9999&', lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'faqBannerText') {
						this.faqBannerText = item.value
					}
				})
			},
			fnError: (error: any) => console.warn(error),
		})
		this.content.getPage('faq/?', lang, {
			fnSuccess(data) {
				self.data = data.items
			},
			fnError(error) {
				console.warn(error)
			},
		})
	}
}
