<div class="mission-container">
  <div class="content" *ngFor="let item of data" [ngStyle]="{'background': item.bg}">
    <div class="content__icon">
      <app-image [path]="item.icon" [title]="item.title"></app-image>
    </div>
    <div class="content__text">
      <div class="title">
        <h2>
          <div class="title__border"></div>
          {{ item.title }}
        </h2>
      </div>
      <div class="subtitle" [innerHTML]="item.subtitle"></div>
    </div>
    <div class="content__img">
      <img [src]="item.image">
    </div>
  </div>
</div>
