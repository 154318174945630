import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ContentService } from 'src/app/services/content/content.service'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from 'src/app/components/modals/modal-default/modal-default.component'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-exportation',
	templateUrl: './exportation.component.html',
	styleUrls: ['./exportation.component.scss'],
})
export class ExportationComponent implements OnInit {
	form: FormGroup
	modalSuccessTitle: string = ''
	modalSuccessText: string = ''
	modalErrorTitle: string = ''
	modalErrorText: string = ''
    ModalErrorAlreadyExists: string = ''

	constructor(
		private formBuilder: FormBuilder,
		private content: ContentService,
		private modal: NgbModal,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {
		this.form = this.formBuilder.group({
			name: [
				null,
				[Validators.required, GenericValidatorService.lettersOnly, GenericValidatorService.completeName],
			],
			email: [null, [Validators.required, Validators.email]],
			phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			cell_phone: [
				null,
				[
					Validators.required,
					GenericValidatorService.validateCellPhone,
				],
			],
			company: [null, [Validators.required]],
			country: [null, [Validators.required, GenericValidatorService.lettersOnly]],
			city: [null, [Validators.required, GenericValidatorService.lettersOnly]],
			product: [null, [Validators.required]],
			messenger: [null, [Validators.required]],
		})
	}
	options: any[] = [
		{ value: 'everest plus', label: 'Everest Plus' },
		{ value: 'everest star', label: 'Everest Star' },
		{ value: 'everest slim', label: 'Everest Slim' },
		{ value: 'everest fit', label: 'Everest Fit' },
	]

	lang = 'pt'
	exportationBannerText: string = 'Exportação'
	exportationInputName = 'Nome'
	exportationInputEmail = 'E-mail'
	exportationInputPhone = 'Telefone'
	exportationInputCellPhone = 'Celular'
	exportationInputCompany = 'Empresa'
	exportationInputCountry = 'País'
	exportationInputCity = 'Cidade'
	exportationInputProduct = 'Produto de interesse'
	exportationInputProductPlaceholder = 'Selecione um produto'
	exportationInputMessage = 'Mensagem'
	exportationInputMessagePlaceholder = 'Digite a sua mensagem aqui'
	exportationSendButton = 'Enviar'
	onlyNumbersPlaceHolder = ''

	onSubmit() {
		const self = this
		if (this.form.valid) {
			const {
				name,
				email,
				phone,
				cell_phone,
				company,
				country,
				city,
				product,
				messenger,
			} = this.form.value
			this.content.sendForm('export_form', {
				payload: {
					name,
					email,
					phone,
					cell_phone,
					company,
					country,
					city,
					product,
					messenger,
				},
				fnSuccess(data: any) {
					self.form.reset()
					self.openModal(
						self.modalSuccessTitle,
						self.modalSuccessText
					)
				},
                fnError(err: any) {
                    if(err.status === 400) {
                        self.openModal(self.modalErrorTitle, self.ModalErrorAlreadyExists)
                    }else {
                        self.openModal(
                            self.modalErrorTitle,
                            self.modalErrorText,
                        )
                        console.warn(err)
                    }

                },
			})
		}
	}

	openModal(
		title: string = '',
		description: string = '',
		icon: string = 'alert.svg'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.figure = '/assets/images/' + icon
		modalRef.componentInstance.showFooter = false
	}

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		window.localStorage.setItem('lang', this.lang)

		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {

				data.items.map((item: any) => {
					if (item.key === 'modalSuccessTitle') {
						this.modalSuccessTitle = item.value
					} else if (item.key === 'modalSuccessText') {
						this.modalSuccessText = item.value
					} else if (item.key === 'onlyNumbersPlaceHolder') {
						this.onlyNumbersPlaceHolder = item.value
					} else if (item.key === 'modalErrorTitle') {
						this.modalErrorTitle = item.value
					} else if (item.key === 'exportationBannerText') {
						this.exportationBannerText = item.value
					} else if (item.key === 'exportationInputName') {
						this.exportationInputName = item.value
					} else if (item.key === 'exportationInputEmail') {
						this.exportationInputEmail = item.value
					} else if (item.key === 'exportationInputPhone') {
						this.exportationInputPhone = item.value
					} else if (item.key === 'exportationInputCellPhone') {
						this.exportationInputCellPhone = item.value
					} else if (item.key === 'exportationInputCompany') {
						this.exportationInputCompany = item.value
					} else if (item.key === 'exportationInputCountry') {
						this.exportationInputCountry = item.value
					} else if (item.key === 'exportationInputCity') {
						this.exportationInputCity = item.value
					} else if (item.key === 'exportationInputProduct') {
						this.exportationInputProduct = item.value
					} else if (
						item.key === 'exportationInputProductPlaceholder'
					) {
						this.exportationInputProductPlaceholder = item.value
					} else if (item.key === 'exportationInputMessage') {
						this.exportationInputMessage = item.value
					} else if (
						item.key === 'exportationInputMessagePlaceholder'
					) {
						this.exportationInputMessagePlaceholder = item.value
					} else if (item.key === 'exportationSendButton') {
						this.exportationSendButton = item.value
					} else if (item.key === 'modalErrorAlreadyExists') {
                        this.ModalErrorAlreadyExists = item.value
                    }
				})
			},
			fnError: (error: any) => console.warn(error),
		})
		this.title.setTitle('Exportação')
		this.meta.addTag({
			name: 'description',
			content: 'teste',
		})
		this.meta.updateTag(
			{
				property: 'og:description',
				content: 'teste',
			},
			"property='og:description'"
		)
	}
}
