<div class="container">
  <div class="benefits">
    <div class="benefits__txt">
      <p [innerHTML]="data.vantages_list_title"></p>
    </div>
    <div class="carousel" #scrollContainer carousel>
      <!-- <ngx-slick-carousel [config]="slideConfig" class="carousel">
        <div ngxSlickItem class="slide" *ngFor="let item of data.vantages_list"> -->
        <div class="slide" *ngFor="let item of data.vantages_list">
          <div class="slide__card">
            <div class="slide__card__img" *ngIf="item.image">
              <img [src]="item.image.url" alt="" />
            </div>
            <div class="slide__card__title">
              <p [innerHTML]="item.title"></p>
            </div>
            <div class="slide__card__line"></div>
            <div class="slide__card__text">
              <p [innerHTML]="item.text"></p>
            </div>
          </div>
        </div>
      <!-- </ngx-slick-carousel> -->
    </div>
  </div>
</div>
