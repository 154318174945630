<div class="container">
    <div class="search">
        <form autocomplete="off">
            <input type="text" name="cep" [(ngModel)]="cep" [placeholder]="placeHolderCep" [mask]="'00000-000'" minlength="8">
            <button type="submit" (click)="searchByCep()"><app-image [path]="'assets/images/search-menu.svg'"></app-image></button>
        </form>
        <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">{{ notKnowCep }}</a>
    </div>
    <div class="address-wrapper">
        <div class="map">
            <agm-map id="mapId" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                <agm-marker *ngFor="let address of dataAdresses let i = index" [latitude]="address.lat" [longitude]="address.long" iconUrl="../assets/images/icon-blue.png" [openInfoWindow]="true">
                    <agm-info-window [disableAutoPan]="true" [maxWidth]="250">
                        <h6 class="upper">{{ address.name }}</h6>
                        <p>{{ address.address }}, {{ address.city }} / {{ address.state }}</p>
                        <p>{{ address.neighborhood }}, {{ address.zip_code }}</p>
                        <p><strong>{{ address.phone_number }}</strong><strong *ngIf="address.phone_number_secondary !== 'nan'"> | {{ address.phone_number_secondary }}</strong></p>
                        <p><strong>{{ address.email }}</strong></p>
                        <span (click)="clickRota(address)" class="btnRota">{{ mapsTextButton }}</span>
                    </agm-info-window>
                </agm-marker>
            </agm-map>
        </div>
        <div class="adresses">
            <div *ngIf="!dataAdresses.length">
                <h4 class="upper">{{ lang === 'pt' ? 'Nenhum resultado' : 'No hay resultados' }}</h4>
                <a *ngIf="lang === 'pt'" href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank">Não sabe seu CEP?</a>
            </div>
            <div *ngIf="dataAdresses.length">
                <div *ngFor="let address of dataAdresses let i = index" class="address">
                    <h2 class="upper">{{ address.name }}</h2>
                    <p><strong>{{ mapsAddress }}:</strong> {{ address.address }}, {{ address.neighborhood }}</p>
                    <p><strong>{{ mapsNeighborhood }}:</strong> {{ address.neighborhood }} / <strong>{{ mapsCity }}:</strong> {{ address.city }}</p>
                    <p><strong>{{ mapsState }}:</strong> {{ address.state }}</p>
                    <!--                <p *ngIf="hasCep && (address.distance || mapsDistance)"><strong>{{ mapsDistance }}:</strong> ≅ {{ address.distance.toFixed(2) }} km</p>-->
                    <p><strong>{{ address.phone_number }} <strong *ngIf="address.phone_number_secondary !== 'nan'"> | {{ address.phone_number_secondary }}</strong> / {{ address.email }}</strong></p>

                    <div class="btns">
                        <span (click)="focusPin(address.lat, address.long)">{{ findAddress }}</span>
                        <span (click)="clickRota(address)">{{ mapsTextButton }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
