import { Component, OnInit, HostListener, ElementRef, Renderer2 } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'

@Component({
	selector: 'app-interna-produto',
	templateUrl: './interna-produto.component.html',
	styleUrls: ['./interna-produto.component.scss'],
})
export class InternaProdutoComponent implements OnInit {
	constructor(
		private content: ContentService,
		private route: ActivatedRoute,
		private title: Title,
		private meta: Meta,
		private store: Store<AppState>,
        private el: ElementRef,
        private renderer: Renderer2
	) {
		this.store.dispatch(ShowLoading())
	}

    dataProduct: any
    isPlus: boolean = false
    @HostListener('window:scroll', [])


    onWindowScroll() {
        const buttonElement = this.el.nativeElement.querySelector('#btn');

        if (window.pageYOffset > 100) { // Você pode ajustar o valor 100 para a quantidade de scroll que desejar
            this.renderer.addClass(buttonElement, 'float'); // Adicione a classe CSS desejada aqui
        } else {
            this.renderer.removeClass(buttonElement, 'float'); // Remova a classe se não atender às condições
        }
    }

	ngOnInit(): void {
		const slug = this.route.snapshot.paramMap.get('slug')!
		const lang = this.route.snapshot.paramMap.get('lang')!
		const self = this
		self.content.getProduct(slug, lang,{
			fnSuccess(data) {
				self.title.setTitle(data.items[0].meta.seo_title || slug)
				self.meta.addTag({
					name: 'description',
					content: data.items[0].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[0].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					},
					"property='og:title'"
				)
				self.dataProduct = data.items[0]
				if (self.dataProduct.title == 'Everest Plus') {
					self.isPlus = true
				}
			},
			fnError(err) {
				console.warn(err)
			},
			fnFinalized() {
				self.store.dispatch(HiddenLoading())
			},
		})
	}
}
