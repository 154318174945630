<div class='control'>
  <div class='form-group'>
      <label [for]="'i-' + id" [class.default]='!labelName' [formGroup]='form'>
          <span *ngIf='labelName' class='custom-label control-label' [ngClass]="{'has-value': hasValue}">{{ labelName }}</span>
          <textarea
              #element
              [id]="'i-' + id"
              [attr.name]='name'
              [formControlName]='name'
              [placeholder]='placeholder || ""'
              [setValue]='inputModel'
              (keyup)='onChange($event, element.value)'
              class='custom-text-area'
          ></textarea>
      </label>
      <span *ngIf='callout' class='callout'>{{ callout }}</span>
      <span
          *ngIf='form.controls[name || ""].invalid &&
           (form.controls[name || ""].touched || form.controls[name || ""].dirty)'
          class='control-error'
          [setFormControlError]='{objError: form.controls[name || ""].errors, control: labelName}'
      >
      </span>
  </div>
</div>