import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'app-compare-modal',
	templateUrl: './compare-modal.component.html',
	styleUrls: ['./compare-modal.component.scss'],
})
export class CompareModalComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() desc?: string
	@Input() allButtons: boolean = true
	@Input() labelButtonConfirmation: string = 'Confirmar'

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	confirmation() {
		if (this.callbackConfirmation) this.callbackConfirmation()
		this.close()
	}
}
