<div class="container">
    <div class="card">
        <div class="card-header">
            <p>{{data.quality_title}}</p>
        </div>
        <div class="card-body">
            {{data.quality_description}}
        </div>
    </div>
    <div class="carousel">
        <div class="carousel">
            <ngx-slick-carousel
                class="carousel"
                #slickModal="slick-carousel"
                [config]="slideConfig"
                (afterChange)="onAfterChange($event)"
            >
                <div ngxSlickItem *ngFor="let data of data.images_list" class="slide">
                    <img class="image" [src]="data.image.url">
                </div>
            </ngx-slick-carousel>
        </div>
        <div class="slick-progress-bar-container">
            <div class="slick-progress-bar" [style.width.%]="progressBarValue"></div>
        </div>
    </div>
</div>
