<div id="know-more" class="know-more container">
    <div class="know-more-content container">
        <div class="know-more-body " *ngFor="let cards of cards; let i = index">
            <app-post
                [cards]="cards"
                type="{{ i === 1 ? 'reverse' : '' }}"
                [ngClass]="{'item1' : i === 0, 'item2' : i === 1}"
                parallax [ratio]="-.10" [side]="i == 0 ? 'left' : 'right'"
            >
            </app-post>
        </div>
    </div>
</div>

