<div class="card-product">
    <div>
        <picture>
            <img [src]="data.gallery[0].image.url" [alt]="data.name ">
        </picture>
        <span class="name"> {{ data.name }}</span>
        <div class="d-flex justify-content-between">
            <div class="wrapper-colors">
                <div *ngFor="let item of data.color" class="colors">
                    <svg *ngIf="item.name === 'Branco'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                        <g filter="url(#filter0_i_178_2219)">
                            <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#DBDBDB"/>
                        </g>
                        <defs>
                            <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg *ngIf="item.name === 'Prata'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                        <g filter="url(#filter0_i_178_2219)">
                            <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#949799"/>
                        </g>
                        <defs>
                            <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg *ngIf="item.name === 'Preto'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                        <g filter="url(#filter0_i_178_2219)">
                            <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#0A0A0A"/>
                        </g>
                        <defs>
                            <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                            </filter>
                        </defs>
                    </svg>
                    <svg *ngIf="item.name === 'Vermelho'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28 28" fill="none">
                        <g filter="url(#filter0_i_178_2219)">
                            <circle cx="14" cy="14" r="14" transform="matrix(1 0 0 -1 0 27.9999)" fill="#B1090C"/>
                        </g>
                        <defs>
                            <filter id="filter0_i_178_2219" x="0" y="0" width="28" height="31.9999" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="4"/>
                                <feGaussianBlur stdDeviation="2"/>
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_178_2219"/>
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
            <div *ngIf="data.price" class="price">R$ {{data.price}}</div>
        </div>
    </div>
    <app-mybutton
        type="responsive"
        [typeBtn]="'purchase'"
        [text]="data.buy_now_label"
        [href]="data.buy_now"
    ></app-mybutton>
</div>
