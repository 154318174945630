import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-ice-images',
	templateUrl: './ice-images.component.html',
	styleUrls: ['./ice-images.component.scss'],
})
export class IceImagesComponent implements OnInit {
  @Input() data: any = {}

	slideConfig = {
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 'calc((100% - 405px) / 2)',
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        mobileFirst: true,
        swipeToSlide: true,
        touchThreshold: 4,
    }

    progressBarValue = 33;

    onAfterChange(event: any) {
        const slideCount = this.data.images_list.length;
        const currentIndex = event.currentSlide;
        const progressPercentage = ((currentIndex + 1) / slideCount) * 100;
        this.progressBarValue = progressPercentage;
    }

	constructor() {}

	ngOnInit(): void {}
}
