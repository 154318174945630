import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'

@Component({
	selector: 'app-ice-tabs',
	templateUrl: './ice-tabs.component.html',
	styleUrls: ['./ice-tabs.component.scss'],
})
export class IceTabsComponent implements OnInit {
	@Input() data: any = {}
    @Input() lang: string = ''
	@Input() dataDictionary: any = {}
    @Output() openModal: EventEmitter<{ showModal: boolean, name: string }> = new EventEmitter();
	constructor() {}
    onClick(event: any, name:string) {
        event.preventDefault();
        this.openModal.emit({ showModal: true, name: name });
    }
	ngOnInit(): void {}
}
