import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-ice-machine-benefits',
	templateUrl: './ice-machine-benefits.component.html',
	styleUrls: ['./ice-machine-benefits.component.scss'],
})
export class IceMachineBenefitsComponent implements OnInit {
	// slideConfig = {
	// 	slidesToScroll: 3,
	// 	slidesToShow: 4,
	// 	dots: false,
	// 	infinite: false,

	// 	responsive: [
	// 		{
	// 			breakpoint: 768, // Limite de largura para o dispositivo móvel
	// 			settings: {
	// 				dots: true, // Remove os pontos de navegação
	// 				slidesToScroll: 1,
	// 				slidesToShow: 1,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1321, // Limite de largura para o dispositivo móvel
	// 			settings: {
	// 				// dots: true, // Remove os pontos de navegação
	// 				slidesToScroll: 1,
	// 				slidesToShow: 2,
	// 			},
	// 		},
	// 		{
	// 			breakpoint: 1750, // Limite de largura para o dispositivo móvel
	// 			settings: {
	// 				// dots: true, // Remove os pontos de navegação
	// 				slidesToScroll: 2,
	// 				slidesToShow: 3,
	// 			},
	// 		},
	// 		// {
	// 		// 	breakpoint: 1200, // Limite de largura para o dispositivo móvel
	// 		// 	settings: {
	// 		// 		// Remove os pontos de navegação
	// 		// 		slidesToScroll: 1,
	// 		// 		slidesToShow: 2,
	// 		// 	},
	// 		// },
	// 		// {
	// 		// 	breakpoint: 1200, // Limite de largura para o dispositivo móvel
	// 		// 	settings: {
	// 		// 		slidesToScroll: 3,
	// 		// 		slidesToShow: 3,
	// 		// 	},
	// 		// },
	// 	],
	// }
	@Input() data: any = {}
	// @ViewChild('scrollContainer', { static: true }) scrollContainer!: ElementRef<HTMLDivElement>;

	// private isDragging = false;
	// private startX = 0;
	// private scrollLeft = 0;
	// width = window.innerWidth

	constructor() { }


	ngOnInit(): void {
		// if(this.width >= 1024) {
		// 	this.dragCarousel()
		// }
	}

	// dragCarousel() {
	// 	const container = this.scrollContainer.nativeElement;

	// 	const end = () => {
	// 		this.isDragging = false;
	// 		container.classList.remove('active');
	// 	};

	// 	const start = (e: MouseEvent | TouchEvent) => {
	// 		this.isDragging = true;
	// 		container.classList.add('active');
	// 		this.startX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX - container.offsetLeft;
	// 		this.scrollLeft = container.scrollLeft;
	// 	};

	// 	const move = (e: MouseEvent | TouchEvent) => {
	// 		if (!this.isDragging) return;

	// 		e.preventDefault();
	// 		const x = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX - container.offsetLeft;
	// 		const dist = x - this.startX;
	// 		container.scrollLeft = this.scrollLeft - dist;
	// 	};

	// 	container.addEventListener('mousedown', start);
	// 	container.addEventListener('touchstart', start);

	// 	container.addEventListener('mousemove', move);
	// 	container.addEventListener('touchmove', move);

	// 	container.addEventListener('mouseleave', end);
	// 	container.addEventListener('mouseup', end);
	// 	container.addEventListener('touchend', end);
	// }
}
