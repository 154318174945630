import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-highlight',
  templateUrl: './card-highlight.component.html',
  styleUrls: ['./card-highlight.component.scss']
})
export class CardHighlightComponent implements OnInit {
    @Input() data: any
    @Input() index: any
  constructor() { }

  ngOnInit(): void {
  }

}
