<div class='wrapper-forgot-password'>
    <form
        action='#'
        autocomplete='off'
        (ngSubmit)='onSubmit()'
        [formGroup]='form'
    >
        <app-input
            id='cpf'
            name='cpf'
            typeInput='text'
            labelName='CPF*'
            placeholder='Digite seu cpf*'
            [form]='form'
            inputMask='000.000.000-00'
        ></app-input>
        <app-input
            id='email'
            name='email'
            typeInput='email'
            labelName='E-mail*'
            placeholder='Digite seu e-mail'
            [form]='form'
        ></app-input>
        <button
            type='submit'
            class='btn w-100'
            [disabled]='form.invalid'
        >
            Enviar
        </button>
    </form>
</div>
