import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-blue',
  templateUrl: './banner-blue.component.html',
  styleUrls: ['./banner-blue.component.scss']
})
export class BannerBlueComponent implements OnInit {
    @Input() data: any
  constructor() { }

  ngOnInit(): void {
  }

}
