import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'
import { ModalDefaultComponent } from '../modal-default/modal-default.component'
import { ContentService } from 'src/app/services/content/content.service'

@Component({
	selector: 'app-ice-form',
	templateUrl: './ice-form.component.html',
	styleUrls: ['./ice-form.component.scss'],
})
export class IceFormComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() desc?: string
	@Input() allButtons: boolean = true
	@Input() labelButtonConfirmation: string = 'Confirmar'
	@Input() inputFieldLabels: any = {}

	constructor(
		public activeModal: NgbActiveModal,
		private formBuilder: FormBuilder,
		private modal: NgbModal,
		private content: ContentService
	) {
		this.form = this.formBuilder.group({
			name: [
				null,
				[Validators.required, GenericValidatorService.lettersOnly, GenericValidatorService.completeName],
			],
			cnpj: [
				null,
				[Validators.required, GenericValidatorService.numbersOnly, GenericValidatorService.isValidCnpj],
			],
			email: [null, [Validators.required, Validators.email]],
			phone: [
				null,
				[Validators.required, GenericValidatorService.numbersOnly, GenericValidatorService.validatePhone],
			],
			cep: [
				null,
				[Validators.required, GenericValidatorService.numbersOnly, GenericValidatorService.validateCep],
			],
			privacy_policy: [null, [Validators.requiredTrue]],
		})
	}
	form: FormGroup

	ngOnInit(): void {}

	close() {
		this.activeModal.close()
	}

	onSubmit() {
		if (this.form.valid) {
			const self = this
			const { email, name, cnpj, phone, cep } =
				this.form.value
			this.content.sendForm('ice_machine_form', {
				payload: { email, name , cnpj, phone, cep },
				fnSuccess(data: any) {
					self.close()
					self.openModal(
						self.inputFieldLabels.modalSuccessTitle,
						self.inputFieldLabels.modalSuccessText,
						self.inputFieldLabels.modalButton
					)
					self.form.reset()
				},
				fnError(err: Error) {
					self.close()
					self.openModal(
						self.inputFieldLabels.modalErrorTitle,
						self.inputFieldLabels.modalErrorText,
						self.inputFieldLabels.modalButton
					)
					console.warn(err)
				},
			})
		}
	}

	openModal(
		title: string = '',
		description: string = '',
		buttonLabel: string = 'confirmar'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.labelButtonConfirmation =
			buttonLabel || 'Confirmar'
		modalRef.componentInstance.showFooter = false
	}
}
