import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-ice-products',
  templateUrl: './carousel-ice-products.component.html',
  styleUrls: ['./carousel-ice-products.component.scss']
})
export class CarouselIceProductsComponent implements OnInit {
  @Input() data: any = []
  @Input() dataDictionary: any = {}
  @Input() lang: string = ''
  @Input() dataHome: any = []
  slideConfig = {
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    lazyLoad: 'ondemand',
    speed: 300,
    slidesToShow: 1,
    mobileFirst: true,
    swipeToSlide: true,
    variableWidth: true,
    touchThreshold: 4,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  }

  constructor() { }

  ngOnInit(): void {

  }

  progressBarValue = 20

  onAfterChange(event: any) {
    const slideCount = this.data.length
    const currentIndex = event.currentSlide
    const progressPercentage = ((currentIndex + 2) / slideCount) * 100
    this.progressBarValue = progressPercentage
  }
}
