<div class="banner">
	<ngx-slick-carousel
		class="carousel"
		#slickModal
		[config]="slideConfig"
		(afterChange)="afterChange()"
	>
		<div ngxSlickItem *ngFor="let banner of banner" class="slide">
			<div class="carousel-item active">
				<a [href]="banner.cta_link" target="_blank">
					<div class="banner__content container">
						<div class="banner__body">
							<h1 class="title">{{ banner.title }}</h1>
							<p class="body1" [innerHTML]="banner.description"></p>
							<a href="#" *ngIf="banner.cta_label"
								><app-mybutton
									[text]="banner.cta_label"
									[ga]="'Banner - ' + banner.cta_label"
									type="white"
								></app-mybutton
							></a>
						</div>
					</div>
				
				
					<img
						class="image-desktop"
						[src]="banner.image.url || './assets/images/banner.png'"
						title="banner"
						alt="banner"
					/>
				</a>
				<div class="image-mobile" *ngIf="banner.image_mobile !== null">
					<a [href]="banner.cta_link" target="_blank">
						<img
						class="image-mobile"
						[src]="banner.image_mobile.url"
						title="banner"
						alt="banner"
					/>
					</a>
				</div>
			</div>
		</div>
	</ngx-slick-carousel>
	<div class="slick-control container">
		<div *ngIf="this.banner.length > 1"  class="carousel-nav container">
			<div (click)="PrevSlide()">
				<button class="prev" (click)="slickModal.slickPrev()"></button>
			</div>
			<div class="progress-bar">
				<div
					class="slick-progress-bar"
					[style.width.%]="progressBarValue"
				></div>
			</div>
			<div (click)="NextSlide()">
				<button class="next" (click)="slickModal.slickNext()"></button>
			</div>
		</div>
		<div class="cards">
			<div
				*ngFor="let sum of summary; index as i"
				[routerLink]="
					'/' + lang + '/diferencial/' + sum.title.toLowerCase()
				"
			>
				<div class="card" [class.selected-card]="currentSlide === i">
					<div class="card__header">
						<div class="icon">
							<figure>
								<img [src]="sum.image.url" alt="" />
							</figure>
						</div>
						<!-- <p class="card__body body-header">veja mais +</p> -->
					</div>
					<p class="card__title">
						{{ sum.title }}
					</p>
					<p class="card__body">
						{{ sum.content }}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
