<app-filtrante-banner [data]="dataFilter"></app-filtrante-banner>
<div class="container" *ngIf="dataFilter">
    <div class="content">
        <div class="content-main">
            <app-image *ngIf="dataFilter.filter_second_image !== undefined"
                [path]="dataFilter.filter_second_image.url"></app-image>
            <app-image *ngIf="dataFilter.filter_second_image == undefined"
                [path]="'/assets/images/Group_37705_1.original.png'"></app-image>
        </div>
    </div>
    <div class="content-mid">
        <div class="body" [innerHTML]="dataFilter.filter_sub_text"></div>
    </div>
    <app-gota-filtrante [data]='dataFilter'></app-gota-filtrante>
    <div class="retangulo" style="height: 165px"></div>
    <app-blue-card [data]="dataFilter"></app-blue-card>
    <div class="footer">
        <p class="body1 body5" [innerHTML]="dataFilter.footer_text"></p>
    </div>
</div>