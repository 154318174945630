import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SlickCarouselComponent } from 'ngx-slick-carousel'
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PurifiersDetailsComponent} from "../modals/purifiers-details/purifiers-details.component";
import { HelpersService } from '../../services/helpers/helpers.service'

@Component({
	selector: 'app-card-purifiers',
	templateUrl: './card-purifiers.component.html',
	styleUrls: ['./card-purifiers.component.scss'],
})
export class CardPurifiersComponent implements OnInit {
	@Input() item: any
	@Input() type = ''
	@Input() btnType = ''
	@Input() isPurifierCard? = false
	@Input() isCarouselCard? = true
	@Input() disable? = false
	@Input() compareBtn? = ''
	@Input() knowBtn = 'Saiba mais'
	@Input() buyBtn = ''
	lang: string = 'pt'
	isPlus: boolean = false
	@Input() isIceMachine: boolean = false
	@Input() isIceCarousel: boolean = false
    @ViewChild('slickModals', { static: false })
    slickCarousel: SlickCarouselComponent
	constructor(private route: ActivatedRoute, private helpers: HelpersService, private modal: NgbModal) {}

	@Output() compareItem = new EventEmitter()

	onCompare(item: any) {
		this.compareItem.emit(item)
	}
	getPrice(price: any) {
        return this.helpers.getPrice(price)
    }
	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		if (this.item && this.item.line == 'PLUS') {
			this.isPlus = true
		}
	}
    openModal(data: any) {
      console.log(data)
        const modalRef = this.modal.open(PurifiersDetailsComponent)
        modalRef.componentInstance.data = data
        modalRef.componentInstance.lang = this.lang
    }
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    }
}
