import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { NgbModal, NgbModalOptions  } from '@ng-bootstrap/ng-bootstrap'
import { IceFormComponent } from 'src/app/components/modals/ice-form/ice-form.component'
import { IceMachineDetailsComponent } from 'src/app/components/modals/ice-machine-details/ice-machine-details.component'
import { ContentService } from 'src/app/services/content/content.service'

@Component({
	selector: 'app-ice-machine',
	templateUrl: './ice-machine.component.html',
	styleUrls: ['./ice-machine.component.scss'],
})
export class IceMachineComponent implements OnInit {
	constructor(
		private content: ContentService,
		private route: ActivatedRoute,
		private modal: NgbModal,
		private meta: Meta,
		private title: Title,
	) {}
	lang: string = ''
	iceFormTitle: string = ''
	inputName: string = ''
	inputEmail: string = ''
	inputCnpj: string = ''
	inputPhone: string = ''
	inputCep: string = ''
	inputTerms: string = ''
	inputSend: string = ''
	modalSuccessTitle: string = ''
	modalSuccessText: string = ''
	modalErrorTitle: string = ''
	modalErrorText: string = ''
	resellerModalButton: string = ''
	onlyNumbersPlaceHolder: string = ''
	data: any
	dataProduct: any
    dictionary: any = {
        production: '',
        size: '',
        voltage: '',
        power: '',
        frequency: '',
        stainlessSteel: '',
        refrigerantGas: '',
        dimensions: '',
    }


	onModalOpen() {
		this.openModal()
	}
    onModalOpenInfo(event: any, data: any) {
        const modalOptions: NgbModalOptions = {
            size: 'lg'
        };
        const modalRef = this.modal.open(IceMachineDetailsComponent, modalOptions)
        modalRef.componentInstance.data = data.filter((item: any) => item.name === event.name);
        modalRef.componentInstance.name = event.name;
        modalRef.componentInstance.dictionary = this.dictionary;
        modalRef.componentInstance.lang = this.lang;
	}

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage(
			'pages/?type=cms.IceMachine&fields=*,tabs(products(product(*)))&',
			this.lang,
			{
				fnSuccess: (data) => {
					self.title.setTitle(data.items[0].meta.seo_title || 'Máquinas de Gelo')
					self.meta.updateTag({
						property: 'og:description',
						content: data.items[0].meta.search_description,
					})
					self.meta.updateTag({
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					})
					self.meta.updateTag({
						property: 'og:url',
						content: window.location.href,
					})
					self.data = data.items[0]
				},
				fnError: (error) => {
					console.warn(error)
				},
			}
		)

		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'resellerInputFullName') {
						this.inputName = item.value
					} else if (item.key === 'resellerInputEmail') {
						this.inputEmail = item.value
					} else if (item.key === 'resellerInputPhone') {
						this.inputPhone = item.value
					} else if (item.key === 'resellerInputCNPJ') {
						this.inputCnpj = item.value
					} else if (item.key === 'newsletterButton') {
						this.inputSend = item.value
					} else if (item.key === 'iceFormTitle') {
						this.iceFormTitle = item.value
					} else if (item.key === 'iceFormCep') {
						this.inputCep = item.value
					} else if (item.key === 'iceFormTerms') {
						this.inputTerms = item.value
					} else if (item.key === 'modalSuccessTitle') {
						this.modalSuccessTitle = item.value
					} else if (item.key === 'modalSuccessText') {
						this.modalSuccessText = item.value
					} else if (item.key === 'modalErrorTitle') {
						this.modalErrorTitle = item.value
					} else if (item.key === 'modalErrorText') {
						this.modalErrorText = item.value
					} else if (item.key === 'resellerModalButton') {
						this.resellerModalButton = item.value
					} else if (item.key === 'onlyNumbersPlaceHolder') {
						this.onlyNumbersPlaceHolder = item.value
					} else if (item.key === 'iceMachineProduction') {
						this.dictionary.production = item.value
					} else if (item.key === 'iceMachineSize') {
						this.dictionary.size = item.value
					} else if (item.key === 'iceMachineVoltage') {
						this.dictionary.voltage = item.value
					} else if (item.key === 'iceMachinePower') {
						this.dictionary.power = item.value
					} else if (item.key === 'iceMachineFrequency') {
						this.dictionary.frequency = item.value
					} else if (item.key === 'iceMachineStainlessSteel') {
						this.dictionary.stainlessSteel = item.value
					} else if (item.key === 'iceMachineRefrigerantGas') {
						this.dictionary.refrigerantGas = item.value
					}else if (item.key === 'iceMachineDimensions') {
						this.dictionary.dimensions = item.value
					}
				})
			},
			fnError: (error: any) => console.warn(error),
		})
        this.content.getPage('products/?fields=*&category=3&', this.lang, {
            fnSuccess: (data: any) => {
                self.dataProduct = data.items
            },
            fnError: (error: any) => console.warn(error),
        })
	}

	openModal(): void {
		const modalRef = this.modal.open(IceFormComponent)
		modalRef.componentInstance.inputFieldLabels = {
			title: this.iceFormTitle,
			inputName: this.inputName,
			inputCnpj: this.inputCnpj,
			inputEmail: this.inputEmail,
			inputPhone: this.inputPhone,
			inputCep: this.inputCep,
			inputTerms: this.inputTerms,
			inputSend: this.inputSend,
			modalSuccessTitle: this.modalSuccessTitle,
			modalSuccessText: this.modalSuccessText,
			modalErrorTitle: this.modalErrorTitle,
			modalErrorText: this.modalErrorText,
			modalButton: this.resellerModalButton,
			onlyNumbersPlaceHolder: this.onlyNumbersPlaceHolder
		}
	}
}
