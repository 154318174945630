<div class="banner-cta" >
    <div class="banner-cta-image">
        <picture class="desktop">
            <img [src]="data.banner_image.url">
        </picture>
        <picture class="mobile">
            <img [src]="data.banner_extra_image.url">
        </picture>
        <div class="banner-cta-content container">
            <div class="banner-cta-text mb-4" [innerHTML]="data.banner_title"></div>
            <div style="max-width: 218px">
                <app-mybutton
                    *ngIf="data.banner_label && data.banner_link"
                    type="responsive"
                    [typeBtn]="'purchase'"
                    [text]="data.banner_label"
                    [href]="data.banner_link"
                ></app-mybutton>
            </div>
        </div>

    </div>
</div>
