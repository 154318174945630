<div class="container">
	<div class="carousel" #scrollContainer carousel>
		<!-- <ngx-slick-carousel [config]="slideConfig" class="carousel">
        <div ngxSlickItem class="slide" *ngFor="let item of data.vantages_list"> -->
		<div class="slide" *ngFor="let item of data" class="slide">
			<app-card
                [dataDictionary]="dataDictionary"
				[isPurifierCard]="true"
				[isCarouselCard]="false"
				[btnType]="'responsive'"
				[item]="item.product"
				[isIceCarousel]="true"
                [lang]="lang"
			></app-card>
		</div>
		<!-- </ngx-slick-carousel> -->
	</div>
	<!-- <ngx-slick-carousel
		#slickModal="slick-carousel"
		(afterChange)="onAfterChange($event)"
		[config]="slideConfig"
	>
		<div ngxSlickItem *ngFor="let item of data" class="slide">
			<app-card
				[isPurifierCard]="true"
				[isCarouselCard]="false"
				[btnType]="'responsive'"
				[item]="item.product"
				[isIceCarousel]="true"
			></app-card>
		</div>
	</ngx-slick-carousel> -->
</div>
<!-- <div class="slick-progress-bar-container">
	<div class="slick-progress-bar" [style.width.%]="progressBarValue"></div>
</div> -->
