<div class="products__table" [ngClass]="showTable ? 'active' : ''">
	<div class="table">
		<div class="header">
			<button (click)="closeTable()">
				<app-image [path]="'/assets/images/close.svg'"></app-image>
			</button>
		</div>
		<div class="top">
			<div class="text">
				<h2>{{ fields.compareTableTitle }}</h2>
			</div>
			<!-- <div class="buttons">
                <button class="actionButton"><img src="/assets/images/share.svg"
                        class="icon" /><span>Compartilhar</span></button>
                <button class="actionButton"><img src="/assets/images/download.svg"
                        class="icon" /><span>Download</span></button>
            </div> -->
		</div>
		<div class="wt">
			<table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th *ngFor="let item of list">
							<div class="img">
								<app-image
									[path]="item.gallery[0].image.url"
								></app-image>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="propertyTitle">
							{{
								iceMachinePage
									? fields.compareTableModel
									: fields.compareTableName
							}}
						</td>
						<td *ngFor="let item of list" class="productTitle">
							{{ item.name }}
						</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableIceShape }}</td>
						<td *ngFor="let item of list">
							{{ item.ice_cube_shape }}
						</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableCabinet }}</td>
						<td *ngFor="let item of list">
							{{ item.cabinet }}
						</td>
					</tr>
					<tr *ngIf="!iceMachinePage">
						<td class="propertyTitle">
							{{ fields.compareTableColor }}
						</td>
						<td *ngFor="let item of list">{{ item.color }}</td>
					</tr>
					<tr *ngIf="!iceMachinePage">
						<td class="propertyTitle">
							{{ fields.compareTableTemperature }}
						</td>
						<td *ngFor="let item of list">
							{{ item.temperature }}
						</td>
					</tr>
					<tr *ngIf="!iceMachinePage">
						<td class="propertyTitle">
							{{ fields.compareTableSize }}
						</td>
						<td *ngFor="let item of list">{{ item.size }}</td>
					</tr>
					<tr>
						<td class="propertyTitle">
							{{ fields.compareTableHeight }}
						</td>
						<td *ngFor="let item of list">{{ item.height }}</td>
					</tr>
					<tr>
						<td class="propertyTitle">
							{{ fields.compareTableWidth }}
						</td>
						<td *ngFor="let item of list">{{ item.width }}</td>
					</tr>
					<tr>
						<td class="propertyTitle">
							{{ fields.compareTableDepth }}
						</td>
						<td *ngFor="let item of list">{{ item.depth }}</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">
							{{ fields.compareTableProduction }}
						</td>
						<td *ngFor="let item of list">
							{{ item.production_quantity }}
						</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">
							{{ fields.compareTableDeposit }}
						</td>
						<td *ngFor="let item of list">
							{{ item.deposit_capacity }}
						</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableWeight }}</td>
						<td *ngFor="let item of list">{{ item.weight }}</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableWeightBrute }}</td>
						<td *ngFor="let item of list">{{ item.weight_brute }}</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<!-- <td class="propertyTitle">{{ fields.compareTableHp }}</td> -->
						<td class="propertyTitle">Tensão</td>
						<td *ngFor="let item of list">{{ item.voltage }}</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableHp }}</td>
						<td *ngFor="let item of list">{{ item.hp_nominal }}</td>
					</tr>
					<tr *ngIf="iceMachinePage">
						<td class="propertyTitle">{{ fields.compareTableVolumes }}</td>
						<td *ngFor="let item of list">{{ item.volumes }}</td>
					</tr>
					<!-- <tr>
						<td class="propertyTitle">
							{{ fields.compareTableTechnicalSpecification }}
						</td>
						<td
							*ngFor="let item of list"
							[innerHTML]="item.technical_specification"
						></td>
					</tr> -->
				</tbody>
				<tfoot>
					<tr>
						<td>&nbsp;</td>
						<td *ngFor="let item of list">
							<a *ngIf="!iceMachinePage"
								[href]="item.buy_now"
								target="_blank"
								class="actionButton ga-buy-product"
								[attr.data-btnBuyProduct]="item.name"
								>{{ fields.compareTableBuyButton }}</a
							>
							<a *ngIf="iceMachinePage"
								[routerLink]="'/' + lang + '/produto/maquinas-de-gelo'"
								class="actionButton ga-buy-product"
								[attr.data-btnBuyProduct]="item.name"
								>{{ fields.compareTableKnowButton }}</a
							>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</div>
