import {
    Component,
    EventEmitter,
    OnInit,
    ViewChild,
    Output,
    Input,
} from '@angular/core'
import { MapInfoWindow, MapMarker } from '@angular/google-maps'
import { HttpClient } from '@angular/common/http'
import { ContentService } from '../../services/content/content.service'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
    @Output() btnClick: EventEmitter<any> = new EventEmitter()

    @Input() type: any = ''
    @Input() placeHolderCep: string = 'Digite o seu CEP'
    @Input() mapsTextButton: string = 'Realizar rota'
    @Input() findAddress: string = 'Localizar endereço'
    @Input() mapsAddress: string = 'Endereço'
    @Input() mapsNeighborhood: string = 'Bairro'
    @Input() mapsCity: string = 'Cidade'
    @Input() mapsState: string = 'Estado'
    @Input() mapsDistance: string = 'Distância'
    @Input() notKnowCep: string = 'Não sei meu CEP'

    constructor(
        private http: HttpClient,
        private content: ContentService,
        private route: ActivatedRoute
    ) {}

    dataAdresses: any
    latitude: any
    longitude: any

    ngOnInit(): void {
        this.lang = this.route.snapshot.params.lang
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => this.getLocation(position),
                error => this.tratarErro(error)
            );
        } else {
            this.loadAdresses();
        }
    }

    lang = 'pt'

    clickRota(address: any) {
        this.btnClick.emit(address)
    }
    getLocation(position: GeolocationPosition) {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.loadAdresses();
    }

    tratarErro(erro: GeolocationPositionError) {
        switch (erro.code) {
            case erro.PERMISSION_DENIED:
                this.loadAdresses();
                break;
            case erro.POSITION_UNAVAILABLE:
                alert("Informações de localização estão indisponíveis.");
                break;
            case erro.TIMEOUT:
                alert("Tempo limite expirado ao obter a localização.");
                break;
            default:
                alert("Erro desconhecido ao obter a localização.");
        }
    }
    @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined
    display: any
    markerOptions: google.maps.MarkerOptions = {
        draggable: false,
    }
    markerPositions: google.maps.LatLngLiteral[] = []
    lat = -8.601828655466965
    lng = -49.05587238503323
    zoom = 5

    addMaker(event: google.maps.MapMouseEvent) {
        if (event.latLng != null)
            this.markerPositions.push(event.latLng.toJSON())
    }

    openInfoWindow(marker: MapMarker) {
        if (this.infoWindow != undefined) this.infoWindow.open(marker)
    }

    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = event.latLng.toJSON()
    }

    move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON()
    }

    focusPin(lat: any, long: any) {
        if (window.innerWidth <= 900) {
            const to = document.querySelector<any>('#mapId').offsetTop
            window.scroll({
                top: to + 600,
                behavior: 'smooth',
            })
        }
        this.zoom = 15
        this.lat = lat
        this.lng = long
    }

    searchByCep() {
        this.loadAdresses(this.cep)
        this.zoom = 12
    }

    loadAdresses(cep: any = '60000-000') {
        const self = this
        this.content.getMap('partners/addresses/?&fields=*&' +
            (self.latitude !== undefined && self.longitude !== undefined ? '&lat=' + self.latitude + '&long=' + self.longitude : 'cep=' + cep), {
            fnSuccess(data) {
                if (self.type.length) {
                    if (self.type == 'revenda-purificador') {
                        self.dataAdresses = data.items.filter((item: any) => {
                            return item.is_water_purifier_resaller
                        })
                    } else if (self.type == 'revenda-gelo') {
                        self.dataAdresses = data.items.filter((item: any) => {
                            return item.is_ice_machine_resaller
                        })
                    } else if (self.type == 'assistencia-purificador') {
                        self.dataAdresses = data.items.filter((item: any) => {
                            return item.is_water_purifier_assistance
                        })
                    } else if (self.type == 'assistencia-gelo') {
                        self.dataAdresses = data.items.filter((item: any) => {
                            return item.is_ice_machine_assistance
                        })
                    }
                    self.lat = data.items[0].lat
                    self.lng = data.items[0].long
                    if(self.cep) {
                        self.hasCep = true
                    }
                }
            },
            fnError(err) {
                console.warn(err)
            },
        })
    }

    center: google.maps.LatLngLiteral = { lat: -12.809667, lng: -50.515036 }
    cep: string
    hasCep: boolean = false
    location: google.maps.LatLngLiteral = { lat: 0, lng: 0 }
    CEPposition: google.maps.LatLngLiteral
}
