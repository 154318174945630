<div class='modal-body'>
    <div class="img">
        <img src="/assets/images/gota.svg" alt="">
    </div>
    <div class="title">
        <h5 *ngIf='title'>{{ title }}</h5>
    </div>
    <div class="desc">
        <p *ngIf='desc'>{{ desc }}</p>
    </div>
    <div class="button">
        <app-mybutton (click)='confirmation()' [text]="labelButtonConfirmation"></app-mybutton>
    </div>
</div>