<section class="carousel" >
    <div class="container">
        <ngx-slick-carousel
            #slickModal="slick-carousel"
            (afterChange)="onAfterChange($event)"
            [config]="slideConfig">
            <div ngxSlickItem *ngFor="let item of data" class="slide">
                <app-card [isPurifierCard]="true" [buyBtn]="buyBtn" [knowBtn]="knowBtn" [isCarouselCard]="false" [btnType]="'responsive'" [item]="item.product"></app-card>
            </div>
        </ngx-slick-carousel>
    </div>
    <div class="slick-progress-bar-container">
        <div class="slick-progress-bar" [style.width.%]="progressBarValue"></div>
    </div>
</section>
