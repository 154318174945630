<div class="compare" *ngIf="list.length > 0" [ngClass]="{'active': showCards, 'closed': !showCards}">
  <div class="compare__header" (click)="showCardsToggle()">
    <div class="compare__header__icon">
      <app-image [path]="'/assets/images/compare.svg'"></app-image>
    </div>
    <div class="compare__header__text">
      <h3>{{ list.length }}</h3>
      <span>{{ list.length | i18nPlural: itemPluralMapping.product }}</span>
    </div>
    <div class="compare__header__arrow">
      <img [src]="'/assets/images/arr.png'" />
    </div>
  </div>

  <div class="compare__body">
    <div class="compare__body__content">
      <div class="compare__body__content__cards" *ngFor="let purifier of list let i = index">
        <div class="compare__body__content__cards__img">
          <app-image type="compare" [path]="purifier.gallery[0].image.url"></app-image>
        </div>
        <div class="compare__body__content__cards__txt">
          <h4>{{ purifier.name }}</h4>
        </div>
        <div class="compare__body__content__cards__btn">
          <button (click)="delete(i)">
            <app-image [path]="'/assets/images/delete.svg'"></app-image>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="compare__footer">
    <button class="addButton" (click)="showCardsToggle()" [ngStyle]="{'display': list.length === 4 ? 'none': 'block'}">
      <img src="/assets/images/add.svg" />
      {{ addButton }}
    </button>
    <button class="compareButton" (click)="tableOpen()" [ngStyle]="{'display': list.length <= 1 ? 'none': 'block'}">{{
      compareButtonOpenTable }}</button>
  </div>
</div>