<app-banner-internal title="{{ data.title }}" type="economia"></app-banner-internal>
<div class="container">
    <div class="selo">
        <div class="selo-img" *ngIf="data.stamp_image">
            <app-image [path]="data.stamp_image.url"></app-image>
        </div>
        <div class="selo-text">
            <p class="title" [innerHTML]="data.stamp_title"></p>
            <p class="body1" [innerHTML]="data.stamp_text"></p>
        </div>
    </div>
</div>