<div class="container">
    <div class="container-images">
       <app-image [path]="data[0].img"></app-image>
       <app-image [path]="data[1].img"></app-image>
       <app-image [path]="data[2].img"></app-image>
    </div>
    <div class="container-carousel">
        <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
            (afterChange)="onAfterChange($event)"
        >
            <div ngxSlickItem *ngFor="let card of data" class="slide">
                <img class="image" [src]="card.img">
            </div>
        </ngx-slick-carousel>
        <div class="slick-progress-bar-container">
            <div class="slick-progress-bar" [style.width.%]="progressBarValue"></div>
        </div>
    </div>
</div>
