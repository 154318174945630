import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
	@Input() data: any = []
	@Input() placeHolder: string = ''
	@Input() allOpt = 'TODOS'
	@Input() icon: string = ''
	@Output() changeValue: EventEmitter<any> = new EventEmitter()
	isOpen: boolean = false
	@Input() placeHolderSelect: string = 'TODOS'

	openToggle() {
		this.isOpen = !this.isOpen
	}

	constructor() {}

	select(option: string) {
		this.changeValue.emit(option)
		if (option) {
			this.placeHolderSelect = option
		} else {
			this.placeHolderSelect = this.allOpt
		}
		this.isOpen = false
	}

	ngOnInit(): void {
		// this.placeHolderSelect = this.data.placeholder
	}
}
