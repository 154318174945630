import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-products',
  templateUrl: './card-products.component.html',
  styleUrls: ['./card-products.component.scss']
})
export class CardProductsComponent implements OnInit {
    @Input() data: any
  constructor(        private cdr: ChangeDetectorRef,) { }
    renderComponent: boolean = true
  ngOnInit(): void {
        this.forceRerender()
  }
    forceRerender(): void {
        this.renderComponent = false;
        this.cdr.detectChanges(); // Detecção de alterações manual
        setTimeout(() => {
            this.renderComponent = true;
            this.cdr.detectChanges(); // Detecção de alterações manual
        }, 0);
    }
}
