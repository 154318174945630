import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-compare',
	templateUrl: './compare.component.html',
	styleUrls: ['./compare.component.scss'],
})
export class CompareComponent implements OnInit {
	@Input() list: any[] = []
	@Output() openTable: EventEmitter<boolean> = new EventEmitter()
	@Input() addButton = 'adicionar mais'
	@Input() compareButtonOpenTable = 'Comparar Produtos'
	@Input() compareProductTitle = 'produto adicionado'
	@Input() compareProductPluralTitle = 'produtos adicionados'
	showCards = false
	itemPluralMapping = {
		product: {
			'=1': this.compareProductTitle,
			other: this.compareProductPluralTitle,
		},
	}

	delete(index: number) {
		this.list.splice(index, 1)
		if (this.list.length === 0) {
			this.showCards = false
		}
	}

	showCardsToggle() {
		this.showCards = !this.showCards
	}

	tableOpen() {
		this.showCards = !this.showCards
		this.openTable.emit(true)
	}

	constructor() {}

	ngOnInit(): void {
		this.itemPluralMapping = {
			product: {
				'=1': this.compareProductTitle,
				other: this.compareProductPluralTitle,
			},
		}
	}
}
