<div class="container">
    <div class="content">
        <div class="content-post">
            <div class="content-post-card">
                <h2 class="title">{{data.information_first_block_title}}</h2>
                <p class="body1">
                    {{data.information_first_block_text}}
                </p>
            </div>
            <div class="content-post-img img1">
                <img *ngIf="data.information_first_block_image !== undefined" [src]="data.information_first_block_image.url || './assets/images/card-filtro1.png'">
            </div>
        </div>
        <div class="content-post post2">
            <div class="content-post-card card2">
                <h2 class="title">{{data.information_second_block_title}}</h2>
                <p class="body1">{{data.information_second_block_text}}</p>
            </div>
            <div class="content-post-img img2">
                <img *ngIf="data.information_second_block_image !== undefined" [src]="data.information_second_block_image.url || './assets/images/card-filtro2.png'">
            </div>
        </div>
    </div>
</div>
