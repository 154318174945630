<div class="container">
	<div class="row">
		<div class="col-md-6">
			<img src="/assets/images/404.png" />
		</div>
		<div class="col-md-6 text">
			<h1>Parece que tem <br />algo de errado!</h1>
			<p>Estamos melhorando para resolver esse problema</p>
		</div>
	</div>
</div>
