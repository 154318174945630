<div [class]="type === 'card' ? 'news news--card' : 'news'">
    <picture *ngIf="type === 'card'">
        <img [src]="data.image.url" [alt]="data.title">
    </picture>
    <div [class]="type === 'card' ? 'news__wrapper news__wrapper--card': 'news__wrapper'">
        <div [class]="type === 'card' ? 'news__infos news__infos--card': 'news__infos'">
            <div class="d-flex align-items-center">
                <img src="/assets/images/icon-calendar.svg" alt="calendario" class="mr-1">
                <span [class]="type === 'card' ? 'news__date news__date--card': 'news__date'">{{ getDate(data.date) }}</span>
            </div>
            <img *ngIf="data.icon" [src]="data.icon.url" alt="fonte">
        </div>
        <div class="news__content">
            <a [href]="type !== 'card' ? data.button_link : '#'" target="_blank">
                <h3 [class]="type === 'card' ? 'news__title news__title--card' : (type === 'highlight' ? 'news__title news__title--highlight' : (type === 'small' ? 'news__title news__title--small' : 'news__title'))">
                    {{ data.title }}
                </h3>
                <p *ngIf="type === 'default' || type === 'small'" class="news__description">
                    {{data.description}}
                </p>
            </a>
            <div *ngIf="type === 'card'" style="max-width: 150px; margin-top: 30px;">
                <app-mybutton
                    [typeBtn]="'purchase'"
                    [text]="data.button_label"
                    [href]="data.button_link"
                ></app-mybutton>
            </div>
        </div>
    </div>
</div>
