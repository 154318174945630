import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from '../modal-default/modal-default.component'
import { HelpersService } from '../../../services/helpers/helpers.service'
@Component({
	selector: 'app-purifiers-details',
	templateUrl: './purifiers-details.component.html',
	styleUrls: ['./purifiers-details.component.scss'],
})
export class PurifiersDetailsComponent implements OnInit {
	@Input() callbackConfirmation?: Function
	@Input() dictionary: any = {}
    @Input() data: any;
    @Input() lang: any;
    @Input() name: any;

	constructor(
		public activeModal: NgbActiveModal,
		private helpers: HelpersService,
		private modal: NgbModal,
	) {}

	ngOnInit(): void {

    }

	close() {
		this.activeModal.close()
	}

	getPrice(price: any) {
        return this.helpers.getPrice(price)
    }
	openModal(
		title: string = '',
		description: string = '',
		buttonLabel: string = 'confirmar'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.labelButtonConfirmation =
			buttonLabel || 'Confirmar'
		modalRef.componentInstance.showFooter = false
	}
}
