import { Component, Input, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { Mission } from 'src/app/components/mission-vision-values/mission-vision-values.component'
import { ContentService } from 'src/app/services/content/content.service'

export interface IMission {
	title: string
	mission_short_text: string
	mission_long_text: string
	vision_short_text: string
	vision_long_text: string
	values_short_text: string
	values_long_text: string
}

@Component({
	selector: 'app-mission',
	templateUrl: './mission.component.html',
	styleUrls: ['./mission.component.scss'],
})
export class MissionComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}
	@Input() data: any

	dataItem: Mission[] = []

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage(
			'pages/?type=cms.MissionVisionValues&fields=*&',
			lang,
			{
				fnSuccess(data) {
					self.title.setTitle(
						data.items[0].meta.seo_title ||
							'Missão, visão e valores'
					)
					self.meta.addTag({
						name: 'description',
						content: data.items[0].meta.search_description,
					})
					self.meta.updateTag(
						{
							property: 'og:description',
							content: data.items[0].meta.search_description,
						},
						"property='og:description'"
					)
					self.meta.updateTag(
						{
							property: 'og:title',
							content: data.items[0].meta.seo_title,
						},
						"property='og:title'"
					)
					self.dataItem = [
						{
							title: data.items[0].mission_short_text,
							subtitle: data.items[0].mission_long_text,
							icon: '/assets/images/target.svg',
							bg: '#0068A5',
							image: '/assets/images/shutterstock_2120695241.png',
						},
						{
							title: data.items[0].vision_short_text,
							subtitle: data.items[0].vision_long_text,
							icon: '/assets/images/eye1.svg',
							bg: '#11C4D1',
							image: '/assets/images/shutterstock_2125930679.png',
						},
						{
							title: data.items[0].values_short_text,
							subtitle: data.items[0].values_long_text,
							icon: '/assets/images/star.svg',
							image: '/assets/images/shutterstock_3872951173.png',
							bg: '#079EE8',
						},
					]
					self.data = data.items[0]
				},
				fnError(err) {
					console.warn(err)
				},
			}
		)
	}
}
