import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SlickCarouselComponent } from 'ngx-slick-carousel'

@Component({
	selector: 'app-carousel-differential',
	templateUrl: './carousel-differential.component.html',
	styleUrls: ['./carousel-differential.component.scss'],
})
export class CarouselDifferentialComponent implements OnInit {
	@Input() data: any
	@Input() selectedDifferential: string | null = ''
	@ViewChild('slickCarousel', { static: false })
	slickCarousel: SlickCarouselComponent
	slideConfig = {
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 'calc((100% - 620px) / 2)',
		dots: false,
		arrows: false,
		infinite: false,
		speed: 300,
		slidesToShow: 1,
		mobileFirst: true,
		swipeToSlide: true,
		touchThreshold: 4,
	}

	constructor() {}
	progressBarValue = 25 // largura da barra de progresso para cada slide

	currentIndex = 0

	onAfterChange(event: any) {
		const slideCount = this.data.differential.length
		const currentIndex = event.currentSlide
		const progressPercentage = ((currentIndex + 1) / slideCount) * 100
		this.progressBarValue = progressPercentage

	}

	ngOnInit(): void {
		if (
			this.selectedDifferential !== '' &&
			this.selectedDifferential !== null
		) {
			const index = this.data.differential.findIndex(
				(differential: any) =>
					differential.title.toLowerCase() ==
					this.selectedDifferential
			)
			if (index !== -1) {
				setTimeout(() => {
					this.slickCarousel.slickGoTo(index)
				}, 1)
			}
		}
	}
}
