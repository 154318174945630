import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ice-format',
  templateUrl: './ice-format.component.html',
  styleUrls: ['./ice-format.component.scss']
})
export class IceFormatComponent implements OnInit {
  @Input() data: any = {}
  constructor() { }

  ngOnInit(): void {  }
}
