import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-purifiers-banner',
	templateUrl: './purifiers-banner.component.html',
	styleUrls: ['./purifiers-banner.component.scss'],
})
export class PurifiersBannerComponent implements OnInit {
	@Input() filters: any = {}
	@Input() title: any = {}
	@Input() subtitle: any = {}
	@Input() isIceMachinePage: boolean = false
	
	constructor() {}

	ngOnInit(): void {}
}
