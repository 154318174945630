import { Component, Input, OnInit } from '@angular/core'

export interface Mission {
	icon: string
	title: string
	subtitle: string
	image: string
	bg: string
}

@Component({
	selector: 'app-mission-vision-values',
	templateUrl: './mission-vision-values.component.html',
	styleUrls: ['./mission-vision-values.component.scss'],
})
export class MissionVisionValuesComponent implements OnInit {
	@Input() data: Mission[]

	constructor() {}

	ngOnInit(): void {}
}
