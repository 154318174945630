import { of } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { finalize, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import * as moment from 'moment'
import { environment } from '../../../environments/environment'
import { AuthResult } from './auth-result'
import { AddUser, ClearUser } from '../../state/user/user.action'
import { AppState } from '../../state/app-state'
import { Login } from '../../models/login'
import { Router } from '@angular/router'
import { HelpersService } from '../helpers/helpers.service'
import { ToastrService } from 'ngx-toastr'
import { UserService } from '../user/user.service'

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isLogin = false

    constructor(
        private http: HttpClient,
        private store: Store<AppState>,
        private router: Router,
        private helpers: HelpersService,
        private toast: ToastrService,
        private userService: UserService
    ) {
    }

    loginHasSessionUser(body: Login) {
        return this.http
            .post<any>(`${environment.api}/account/session`, body.payload)
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    body.fnSuccess(data)
                }, error: body.fnError
            })
    }

    login(body: Login) {
        body.payload = {
            ...body.payload,
            cpf: this.helpers.removeMaskCpf(body.payload.cpf)
        }

        return this.http
            .post<any>(`${environment.api}/account/login/`, body.payload)
            .pipe(
                finalize(() => {
                    if (body.fnFinalized) body.fnFinalized()
                })
            )
            .subscribe({
                next: (data) => {
                    const resultData: AuthResult = data
                    this.setSession(resultData)
                    body.fnSuccess()
                }, error: body.fnError
            })
    }

    refreshToken() {
        const token = this.getRefreshToken()
        return this.http
            .post<any>(`${environment.api}/account/token/refresh/`, {
                refresh: token
            })
            .pipe(
                tap((data) => {
                    const resultData: AuthResult = data
                    this.setSession(resultData)
                })
            )
    }

    logout() {
        this.logOutSectionLocal()
        return this.http.delete(`${environment.api}/account/logout/`)
    }

    logOutSectionLocal() {
        this.isLogin = false
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('expires_at')
        this.store.dispatch(ClearUser())
        return of({ success: this.isLogin })
    }

    private setSession({ access, refresh }: AuthResult) {
        const { exp } = this.helpers.decodePayloadJWT(access)
        const expiresAt = moment().add(exp, 'second')

        this.isLogin = true
        sessionStorage.setItem('token', access)
        sessionStorage.setItem('refresh_token', refresh)
        sessionStorage.setItem(
            'expires_at',
            JSON.stringify(expiresAt.valueOf())
        )
    }

    me(): void {
        const token = sessionStorage.getItem('token') || ''
        if (token) {
            const user = sessionStorage.getItem('user') || ''

            if (!user) {
                this.userService.me((data: string) => {
                    this.setUserState(data)
                })
            } else {
                this.setUserState(user)
            }

        } else {
            this.store.dispatch(ClearUser())
        }
    }

    setUserState(data: string): void {
        sessionStorage.setItem('user', data)
        let user = this.helpers.decodePayloadJWT(data)
        user = this.helpers.converterSnakeToCamelCase(user)
        user.address = this.helpers.converterSnakeToCamelCase(user.address)

        this.store.dispatch(
            AddUser({
                ...this.helpers.converterSnakeToCamelCase(user),
            })
        )
    }

    getToken(): string {
        return sessionStorage.getItem('token') || ''
    }

    getRefreshToken(): string {
        return sessionStorage.getItem('refresh_token') || ''
    }

    getExpiration() {
        const expiration = sessionStorage.getItem('expires_at')
        const expiresAt = expiration ? JSON.parse(expiration) : ''
        return expiresAt ? moment(expiresAt) : ''
    }

    isLoggedIn() {
        this.isLogin = moment().isBefore(this.getExpiration())
        return this.isLogin
    }

    redirectAuth(): void {
        let redirectUrl = environment.redirects.roles['consumer']
        this.router.navigate([redirectUrl])
    }
}
