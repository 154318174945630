// gallery.component.ts
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
    @Input() data: any;
    @Input() width: any;
    @Input() preview: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() dots: boolean = false;
    id: number = 0;

    clickImages(id: number) {
        this.id = id;
    }

    next() {
        if (this.id < this.data.gallery.length - 1) {
            this.id++;
        } else {
            this.id = 0;
        }
    }

    prev() {
        if (this.id > 0) {
            this.id--;
        } else {
            this.id = this.data.gallery.length - 1;
        }
    }
}
