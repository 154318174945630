<div class="container">
	<div class="description mb-1">
		<p class="title" [innerHTML]="title"></p>
		<p class="body1" [innerHTML]="description"></p>
	</div>
	<div class="product-highlight">
		<div class="product-highlight-content">
			<div class="product-highlight-text1">
				<p class="title title-white">{{ highlightTitle }}</p>
			</div>
			<div class="product-highlight-img">
				<img [src]="image" />
			</div>
			<div class="product-highlight-text2">
				<p class="title title-blue">{{ highlightTitle }}</p>
			</div>
		</div>
	</div>
</div>
