<div class="modal-body">
	<h5>Saiba como esses dados serão utilizados</h5>
	<p class="pt-3">
		Seu score vai de 0 até 100, quanto mais você interagir na plataforma,
		dando feedbacks, informando CIDs de consultas, responder as opções
		fornecidas nos questionários e participando ativamente das pesquisas,
		você soma pontos.
	</p>
	<p>
		Então, fique sempre atento, quanto maior o seu engajamento mais destaque
		você terá como um excelente profissional.
	</p>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-xs" (click)="close()">Fechar</button>
</div>
