import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-highlight-pantanal',
  templateUrl: './highlight-pantanal.component.html',
  styleUrls: ['./highlight-pantanal.component.scss']
})
export class HighlightPantanalComponent implements OnInit {
    @Input() data: any = []
  constructor() { }

  ngOnInit(): void {
  }

}
