import { Component, Inject, PLATFORM_ID } from '@angular/core'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from './state/app-state'
import { AuthService } from './services/auth/auth.service'
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router'
import { Location, isPlatformBrowser } from '@angular/common'
import { HiddenLoading, ShowLoading } from './state/loading/loading.action'
import { ContentService } from './services/content/content.service'

declare global {
	interface Window {
		ga: any
		gtag: any
		dataLayer: any
	}
}
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	private loading$?: Observable<boolean>
	loading?: boolean
	currentLang: string = ''

	// HeaderFooter
	dataMenu: any
	dataHelp: any
	dataMenuFooter: any
	dataSocials: any
	dataContact: any

	platformId: Object
	constructor(
		private authService: AuthService,
		private store: Store<AppState>,
		private location: Location,
		private router: Router,
		private content: ContentService,
		@Inject(PLATFORM_ID) platformId: Object
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.loading$ = this.store.select('loading')
			this.loading$.subscribe((state) => {
				this.loading = state
				document.body.style.overflow = state ? 'hidden' : ''
			})
		}

		// this.router.events.subscribe((event: Event) => {
		//     if (event instanceof NavigationEnd) {
		// let url = this.location.path()
		// if (
		//     url.includes('ativar-conta') ||
		//     url.includes('resetar-senha')
		// ) {
		//     url = url.split('?')[0]
		// }
		// window.ga('send', 'pageview', url, {
		//     location: url,
		// })
		// }
		// })
	}

	lang: string = ''
	path: string = ''

	footerBottom: string = ''
	newsletterSubText: string = ''
	sacDays: string = ''
    sacAlert: string = ''
	newsletterButton: string = ''

	getFooterText() {
		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'newsletterSubtext') {
						this.newsletterSubText = item.value
					} else if (item.key === 'newsletterButton') {
						this.newsletterButton = item.value
					} else if (item.key === 'footerBottom') {
						this.footerBottom = item.value
					} else if (item.key === 'modalSuccessTitle') {
						this.modalSuccessTitle = item.value
					} else if (item.key === 'modalSuccessText') {
						this.modalSuccessText = item.value
					} else if (item.key === 'modalSuccessNewsletter') {
						this.modalSuccessNewsletter = item.value
					} else if (item.key === 'modalErrorTitle') {
						this.modalErrorTitle = item.value
					} else if (item.key === 'modalErrorText') {
						this.modalErrorText = item.value
					}
                    else if (item.key === 'modalErrorAlreadyExists') {
						this.TextErrorAlreadyExists = item.value
					}
                    else if (item.key === 'sacDays') {
						this.sacDays = item.value
					}
                    else if (item.key === 'sacAlert') {
						this.sacAlert = item.value
					}
				})
			},
			fnError: (error: any) => console.warn(error),
		})
	}

	modalSuccessTitle = ''
	modalSuccessText = ''
    modalSuccessNewsletter = ''
	modalErrorTitle = ''
	modalErrorText = ''
    TextErrorAlreadyExists = ''

	ngOnInit(): void {
		const self = this
		self.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				const location = this.location.path().split('/')
				self.lang = location[1] && location[1].length ? location[1] : ''
				const path2 =
					location[2] && location[2].length ? location[2] : ''
				const path3 =
					location[3] && location[3].length ? location[3] : ''
				self.path = path2 + '/' + path3
				self.currentLang = self.path.length ? self.lang : 'pt'
				self.getMenus()
				self.getFooterText()
			}
		})
	}
	getMenus(): void {
		const self = this
		self.content.getPage('menus/?', this.lang, {
			fnSuccess(data) {
				data.items.map((item: any) => {
					if (item) {
						if (item.slug == 'socials') {
							self.dataSocials = item.menu_items
						} else if (item.slug == 'help') {
							self.dataHelp = item
						} else if (item.slug == 'menu') {
							self.dataMenu = item.menu_items
						} else if (item.slug == 'contacts') {
							self.dataContact = item.menu_items
						} else if (item.slug == 'menu-footer') {
							self.dataMenuFooter = item.menu_items
						}
					}
				})
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
}
