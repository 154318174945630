const api = 'https://everest-portal-back.valide.vc/api/v2'
// const api = 'https://cms.everest.ind.br/api/v2'

export const environment = {
	api: `${api}`,
	redirects: {
		roles: {
			consumer: '/dashboard',
			public: '/',
		},
	},
	env: 'hml',
	production: false,
}
