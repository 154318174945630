<div class="page" *ngIf="dataHistory">
  <app-banner-internal [title]="dataHistory.page_title"></app-banner-internal>
  <div class="cards">
    <div class="play" *ngIf="videoUrl">
      <iframe [src]="videoUrl" width="100%" height="334px" frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="text">
      <div class="content">
        <h2 [innerHtml]="dataHistory.quality_title"></h2>
        <p [innerHtml]="dataHistory.quality_text"></p>
      </div>
    </div>
  </div>
  <app-timeline [data]="dataHistory"></app-timeline>
</div>