import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filtrante-banner',
  templateUrl: './filtrante-banner.component.html',
  styleUrls: ['./filtrante-banner.component.scss']
})
export class FiltranteBannerComponent implements OnInit {
  @Input() data: any = {}
  constructor() { }

  ngOnInit(): void {
  }
}
