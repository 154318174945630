import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-compare-button',
	templateUrl: './compare-button.component.html',
	styleUrls: ['./compare-button.component.scss'],
})
export class CompareButtonComponent implements OnInit {
	@Output() compare = new EventEmitter()
	@Input() disable = false
	@Input() product: string = ''
	@Input() compareBtn? = 'Comparar'

	constructor() {}

	ngOnInit(): void {}
}
