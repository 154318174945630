import { Component, OnInit, ViewChild, ElementRef   } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ContentService } from 'src/app/services/content/content.service'
import { HiddenLoading, ShowLoading } from '../../state/loading/loading.action'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CompareModalComponent } from 'src/app/components/modals/compare-modal/compare-modal.component'
import { ActivatedRoute } from '@angular/router'
import {SlickCarouselComponent} from "ngx-slick-carousel";

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    @ViewChild('target') compare: HTMLElement
    @ViewChild('slickModal', { static: true }) slickModal: any;
    itemsToShow: number = 0;
    slickCarousel: SlickCarouselComponent
    compareList: any[] = []
    showTable = false
    star: any[] = []
    slim: any[] = []
    fit: any[] = []
    plus: any[] = []
    baby: any[] = []

    onOpenTable() {
        this.showTable = true
    }

    onTableClose() {
        this.showTable = false
    }

    onCompareItem(item: any) {
        if (this.compareList.length == 0) this.compareList.push(item)
        else {
            if (this.iceMachinePage) {
                const found = this.compareList.find((listItem) => {
                    if (listItem.name == item.name) return item
                    return undefined
                })
                if (found == undefined) this.compareList.push(item)
                else {
                    this.openModal(this.compareModal)
                }
            } else {
                const found = this.compareList.find((listItem) => {
                    if (listItem.line == item.line) return item
                    return undefined
                })
                if (found == undefined) this.compareList.push(item)
                else {
                    this.openModal(this.compareModal)
                }
            }
        }

        this.scrollToSection('target')
    }

	scrollToSection(id: string) {
		setTimeout(function () {
			const el = document.getElementById(id) || null
			if (el) {
				window.scrollTo({
					top: window.scrollY + el.getBoundingClientRect().top - 200,
					behavior: 'smooth',
				})
			}
		}, 300)
	}
    data: any[]
    dataPurifers: any[]
    mostSales: any[]
    categories: any[]
    dataPage: any = {}
    productsFiltered: any = []

    temperatures: any = []
    ice_production: any = []
    deposit: any = []
    dimensions: any = []
    serves: any = []
    sizes: any = []
    colors: any = []

    dataFilter: any = {
        temperature: '',
        serve: '',
        size: '',
        color: '',
    }

    iceFilters: any = {
        production_quantity: '',
        deposit_capacity: '',
        height: '',
    }
    slideConfig = {
        slidesToScroll: 1,
        slidesToShow: 4.3,
        dots: false,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    };
    slideConfig2 = {
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 5,
        swipeToSlide: true,
        touchThreshold: 4,
        asNavFor: '.carousels',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };
    slideConfig3 = {
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20px',
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 5,
        swipeToSlide: true,
        touchThreshold: 4,
        asNavFor: '.carousels',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };
    slideConfigHighlight = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        touchThreshold: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    slideConfigProducts = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        dots: true,
        centerMode: true,
        centerPadding: '0px 10px',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,

                    centerMode: false,
                },
            },
        ],
    };
    constructor(
        private content: ContentService,
        private title: Title,
        private meta: Meta,
        private store: Store<AppState>,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private elementRef: ElementRef,
    ) {
        this.lang = this.route.snapshot.params.lang
        const page = this.route.snapshot.routeConfig?.path
        if (page && page == 'maquinas-de-gelo') {
            this.categoryNumber = 3
            this.iceMachinePage = true
        }
        this.getData(this.lang, this.categoryNumber)
    }
    purifiersBannerText: string = ''
    lang: string = 'pt'
    iceMachineBannerText: string = 'Máquinas de Gelo'
    categoryNumber = 1
    purifiersBannerSubtext: string = ''
    purifiersTitle: string = ''
    purifiersOpt: string = ''
    compareModal: string = ''
    compareButtonOpenTable: string = ''
    compareButtonAdd: string = ''
    compareButton: string = ''
    compareTableTitle: string = ''
    compareTableBuyButton: string = ''
    compareTableName: string = ''
    compareTableColor: string = ''
    compareTableTemperature: string = ''
    compareTableSize: string = ''
    compareTableHeight: string = ''
    compareTableWidth: string = ''
    compareTableDepth: string = ''
    compareTableTechnicalSpecification: string = ''
    compareProductTitle: string = ''
    compareProductPluralTitle: string = ''
    filtersAllOpt: string = ''
    filtersTemperaturePlaceholder: string = ''
    filtersColorsPlaceholder: string = ''
    filtersSizesPlaceholder: string = ''
    filtersServesPlaceholder: string = ''
    iceMachineTitle: string = ''
    buyBtn: string = ''
    allModels: string = 'Todos os modelos'
    resume: string = 'Resumo'
    sizePurifier: string = 'Tamanho'
    highlights: string = 'Destaques'
    bestSellers: string = 'Mais vendidos'
    chooseYours: string = 'Escolha o seu'
    compareWeight: string = 'Peso'
    noResultsFound: string = 'Nenhum resultado encontrado'
    compareDepth: string = 'Profundidade'
    compareWidth: string = 'Largura'
    compareHeight: string = 'Altura'
    knowBtn: string = ''
    iceMachinePage: boolean = false
    compareTableModel: string = ''
    compareTableIceShape: string = ''
    compareTableProduction: string = ''
    compareTableDeposit: string = ''
    compareTableWeight: string = ''
    compareTableWeightBrute: string = ''
    compareTableVolumes: string = 'quant de vol'
    compareTableCabinet: string = 'Gabinete'
    compareTableHp: string = ''
    filtersProductionPlaceholder: string = ''
    filtersDepositPlaceholder: string = ''
    filtersDimensionsPlaceholder: string = ''
    fields: any = {}
    nav: any = []
    active: any = 0
    progressBarValue = 25
    currentSlideIndex: number = 0;
    ngOnInit(): void {
        if (!this.iceMachinePage) {
            this.title.setTitle('Purificadores')
            this.meta.addTag({
                name: 'description',
                content: 'Purificadores',
            })
            this.meta.updateTag(
                {
                    property: 'og:description',
                    content: 'Purificadores',
                },
                "property='og:description'"
            )
            this.meta.updateTag(
                {
                    property: 'og:title',
                    content: 'Purificadores',
                },
                "property='og:title'"
            )
        } else {
            this.title.setTitle('Máquinas de gelo')
            this.meta.addTag({
                name: 'description',
                content: 'Máquinas de gelo',
            })
            this.meta.updateTag(
                {
                    property: 'og:description',
                    content: 'Máquinas de gelo',
                },
                "property='og:description'"
            )
            this.meta.updateTag(
                {
                    property: 'og:title',
                    content: 'Máquinas de gelo',
                },
                "property='og:title'"
            )
        }
    }
    onAfterChange(event: any) {
        const slideCount = this.dataPurifers.length
        const currentIndex = event.currentSlide
        const progressPercentage = ((currentIndex + 1) / slideCount) * 100
        this.progressBarValue = progressPercentage
    }
    getData(lang: string, category: number): void {
        const self = this
        self.store.dispatch(ShowLoading())

        self.content.getComparisonPage(
            'pages/?type=cms.Products&fields=*&',
            lang,
            {
                fnSuccess: (data) => {
                    self.dataPage = data.items[0]
                },
                fnError: (err) => {
                    console.warn(err)
                },
                fnFinalized() {
                    self.store.dispatch(HiddenLoading())
                },
            }
        )
        self.content.getPage('dictionary/?limit=9999&', lang, {
            fnSuccess: (data: any) => {
                data.items.map((item: any) => {
                    if (item.key === 'purifiersBannerText') {
                        this.purifiersBannerText = item.value
                    } else if (item.key === 'buyBtn') {
                        this.buyBtn = item.value
                    } else if (item.key === 'knowBtn') {
                        this.knowBtn = item.value
                    } else if (item.key === 'iceMachineTitle') {
                        this.iceMachineTitle = item.value
                    } else if (item.key === 'iceMachineBannerText') {
                        this.iceMachineBannerText = item.value
                    } else if (item.key === 'purifiersBannerSubtext') {
                        this.purifiersBannerSubtext = item.value
                    } else if (item.key === 'purifiersTitle') {
                        this.purifiersTitle = item.value
                    } else if (item.key === 'purifiersOpt') {
                        this.purifiersOpt = item.value
                    } else if (item.key === 'compareModal') {
                        this.compareModal = item.value
                    } else if (item.key === 'compareButtonOpenTable') {
                        this.compareButtonOpenTable = item.value
                    } else if (item.key === 'compareButton') {
                        this.compareButton = item.value
                    } else if (item.key === 'compareButtonAdd') {
                        this.compareButtonAdd = item.value
                    } else if (item.key === 'compareTableBuyButton') {
                        this.compareTableBuyButton = item.value
                    } else if (item.key === 'compareTableTitle') {
                        this.compareTableTitle = item.value
                    } else if (item.key === 'compareTableName') {
                        this.compareTableName = item.value
                    } else if (item.key === 'compareTableColor') {
                        this.compareTableColor = item.value
                    } else if (item.key === 'compareTableTemperature') {
                        this.compareTableTemperature = item.value
                    } else if (item.key === 'compareTableSize') {
                        this.compareTableSize = item.value
                    } else if (item.key === 'compareTableModel') {
                        this.compareTableModel = item.value
                    } else if (item.key === 'compareTableIceShape') {
                        this.compareTableIceShape = item.value
                    } else if (item.key === 'compareTableProduction') {
                        this.compareTableProduction = item.value
                    } else if (item.key === 'compareTableDeposit') {
                        this.compareTableDeposit = item.value
                    } else if (item.key === 'compareTableWeight') {
                        this.compareTableWeight = item.value
                    } else if (item.key === 'compareTableVolumes') {
                        this.compareTableVolumes = item.value
                    } else if (item.key === 'compareTableCabinet') {
                        this.compareTableCabinet = item.value
                    } else if (item.key === 'compareTableWeightBrute') {
                        this.compareTableWeightBrute = item.value
                    } else if (item.key === 'compareTableHp') {
                        this.compareTableHp = item.value
                    } else if (item.key === 'filtersProductionPlaceholder') {
                        this.filtersProductionPlaceholder = item.value
                    } else if (item.key === 'filtersDepositPlaceholder') {
                        this.filtersDepositPlaceholder = item.value
                    } else if (item.key === 'filtersDimensionsPlaceholder') {
                        this.filtersDimensionsPlaceholder = item.value
                    } else if (item.key === 'compareTableHeight') {
                        this.compareTableHeight = item.value
                    } else if (item.key === 'compareTableWidth') {
                        this.compareTableWidth = item.value
                    } else if (item.key === 'compareTableDepth') {
                        this.compareTableDepth = item.value
                    } else if (
                        item.key === 'compareTableTechnicalSpecification'
                    ) {
                        this.compareTableTechnicalSpecification = item.value
                    } else if (item.key === 'compareProductTitle') {
                        this.compareProductTitle = item.value
                    } else if (item.key === 'compareProductPluralTitle') {
                        this.compareProductPluralTitle = item.value
                    } else if (item.key === 'filtersAllOpt') {
                        this.filtersAllOpt = item.value
                    } else if (item.key === 'filtersTemperaturePlaceholder') {
                        this.filtersTemperaturePlaceholder = item.value
                    } else if (item.key === 'filtersColorsPlaceholder') {
                        this.filtersColorsPlaceholder = item.value
                    } else if (item.key === 'filtersSizesPlaceholder') {
                        this.filtersSizesPlaceholder = item.value
                    } else if (item.key === 'allModels') {
                        this.allModels = item.value
                    }else if (item.key === 'resume') {
                        this.resume = item.value
                    }else if (item.key === 'sizePurifier') {
                        this.sizePurifier = item.value
                    }else if (item.key === 'highlights') {
                        this.highlights = item.value
                    }else if (item.key === 'bestSellers') {
                        this.bestSellers = item.value
                    } else if (item.key === 'chooseYours') {
                        this.chooseYours = item.value
                    } else if (item.key === 'compareHeight') {
                        this.compareHeight = item.value
                    } else if (item.key === 'compareDepth') {
                        this.compareDepth = item.value
                    } else if (item.key === 'compareWidth') {
                        this.compareWidth = item.value
                    } else if (item.key === 'compareWeight') {
                        this.compareWeight = item.value
                    } else if (item.key === 'noResultsFound') {
                        this.noResultsFound = item.value
                    }
                })
                this.nav = [
                    {
                        title: this.allModels,
                        path: 'allModel'
                    },
                    {
                        title: this.highlights,
                        path: 'highlight'
                    },
                    {
                        title: this.bestSellers,
                        path: 'bestSeller'
                    },
                ]
                this.fields = {
                    compareTableTitle: this.compareTableTitle,
                    compareTableName: this.compareTableName,
                    compareTableColor: this.compareTableColor,
                    compareTableTemperature: this.compareTableTemperature,
                    compareTableSize: this.compareTableSize,
                    compareTableHeight: this.compareTableHeight,
                    compareTableWidth: this.compareTableWidth,
                    compareTableDepth: this.compareTableDepth,
                    compareTableTechnicalSpecification:
                    this.compareTableTechnicalSpecification,
                    compareTableBuyButton: this.compareTableBuyButton,
                    compareTableModel: this.compareTableModel,
                    compareTableIceShape: this.compareTableIceShape,
                    compareTableProduction: this.compareTableProduction,
                    compareTableDeposit: this.compareTableDeposit,
                    compareTableWeight: this.compareTableWeight,
                    compareTableWeightBrute: this.compareTableWeightBrute,
                    compareTableHp: this.compareTableHp,
                    compareTableKnowButton: this.knowBtn,
                    compareTableVolumes: this.compareTableVolumes,
                    compareTableCabinet: this.compareTableCabinet,
                }
            },
            fnError: (error: any) => console.warn(error),
        })
        self.content.getCategories(
            `products/?fields=*&category=${category}&locale=${lang}`,
            {
                fnSuccess(data) {
                    self.data = data.items
                    self.dataPurifers = self.data.filter(item => item.featured === true).sort((a, b) => a.order - b.order)
                    if (category == 1) {
                        data.items.forEach((product: any) => {
                            if (
                                !self.temperatures.includes(
                                    product.temperature
                                ) &&
                                product.temperature
                            ) {
                                self.temperatures = [
                                    ...self.temperatures,
                                    product.temperature,
                                ]
                            }
                            if (
                                !self.serves.includes(product.serves_until) &&
                                product.serves_until
                            ) {
                                self.serves = [
                                    ...self.serves,
                                    product.serves_until,
                                ]
                            }
                            if (
                                !self.sizes.includes(product.size) &&
                                product.size
                            ) {
                                self.sizes = [...self.sizes, product.size]
                            }
                            if (
                                !self.colors.includes(product.color) &&
                                product.color
                            ) {
                                self.colors = [...self.colors, product.color]
                            }
                        })
                        self.data.map((product) => {
                            if (product.line == 'STAR') {
                                self.star.push(product)
                            } else if (product.line == 'SLIM') {
                                self.slim.push(product)
                            } else if (product.line == 'FIT') {
                                self.fit.push(product)
                            } else if (product.line == 'PLUS') {
                                self.plus.push(product)
                            } else if (product.line == 'BABY') {
                                self.baby.push(product)
                            }
                        })
                    } else {
                        data.items.forEach((product: any) => {
                            if (
                                !self.ice_production.includes(
                                    product.production_quantity
                                ) &&
                                product.production_quantity
                            ) {
                                self.ice_production = [
                                    ...self.ice_production,
                                    product.production_quantity,
                                ]
                            }
                            if (
                                !self.deposit.includes(
                                    product.deposit_capacity
                                ) &&
                                product.deposit_capacity
                            ) {
                                self.deposit = [
                                    ...self.deposit,
                                    product.deposit_capacity,
                                ]
                            }
                            if (
                                !self.dimensions.includes(product.height) &&
                                product.height
                            ) {
                                self.dimensions = [
                                    ...self.dimensions,
                                    product.height,
                                ]
                            }
                        })
                    }
                    self.setProductsFilter()
                    // self.mostSales = self.data.map((product) => {
                    // 	product.featured == true
                    // 	return product
                    // })
                },
                fnError(error) {
                    console.warn(error)
                },
                fnFinalized() {
                    self.store.dispatch(HiddenLoading())
                },
            }
        )
        self.content.getCategories('categories/', {
            fnSuccess(data) {
                self.categories = data
            },
            fnError(error) {
                console.warn(error)
            },
        })
    }

    setFilter(event: any, i: any) {
        if (!this.iceMachinePage) {
            if (i == 1) {
                this.dataFilter.temperature = event
            }
            if (i == 2) {
                this.dataFilter.serve = event
            }
            if (i == 3) {
                this.dataFilter.size = event
            }
            if (i == 4) {
                this.dataFilter.color = event
            }
            this.setProductsFilter()
        } else {
            if (i == 1) {
                this.iceFilters.production_quantity = event
            }
            if (i == 2) {
                this.iceFilters.deposit_capacity = event
            }
            if (i == 3) {
                this.iceFilters.height = event
            }
            this.setProductsFilter()
        }
    }

    setProductsFilter() {
        this.productsFiltered = this.data
        if (!this.iceMachinePage) {
            if (
                this.dataFilter.temperature != '' ||
                this.dataFilter.serve != '' ||
                this.dataFilter.size != '' ||
                this.dataFilter.color != ''
            ) {
                if (this.dataFilter.temperature != '') {
                    this.productsFiltered = this.data.filter((product: any) => {
                        if (product.temperature == this.dataFilter.temperature) {
                            return product
                        }
                    })
                }
                if (this.dataFilter.serve != '') {
                    this.productsFiltered = this.productsFiltered.filter(
                        (product: any) => {
                            if (product.serves_until == this.dataFilter.serve) {
                                return product
                            }
                        }
                    )
                }
                if (this.dataFilter.size != '') {
                    this.productsFiltered = this.productsFiltered.filter(
                        (product: any) => {
                            if (
                                this.dataFilter.size &&
                                product.size == this.dataFilter.size
                            ) {
                                return product
                            }
                        }
                    )
                }
                if (this.dataFilter.color != '') {
                    this.productsFiltered = this.productsFiltered.filter(
                        (product: any) => {
                            if (
                                this.dataFilter.color &&
                                product.color == this.dataFilter.color
                            ) {
                                return product
                            }
                        }
                    )
                }
            } else {
                this.productsFiltered = this.data
            }
        } else {
            if (
                this.iceFilters.production_quantity != '' ||
                this.iceFilters.deposit_capacity != '' ||
                this.iceFilters.height != ''
            ) {
                if (this.iceFilters.production_quantity != '') {
                    this.productsFiltered = this.data.filter((product: any) => {
                        if (product.production_quantity == this.iceFilters.production_quantity) {
                            return product
                        }
                    })
                }
                if (this.iceFilters.deposit_capacity != '') {
                    this.productsFiltered = this.productsFiltered.filter(
                        (product: any) => {
                            if (product.deposit_capacity == this.iceFilters.deposit_capacity) {
                                return product
                            }
                        }
                    )
                }
                if (this.iceFilters.height != '') {
                    this.productsFiltered = this.productsFiltered.filter(
                        (product: any) => {
                            if (
                                this.iceFilters.height &&
                                product.height == this.iceFilters.height
                            ) {
                                return product
                            }
                        }
                    )
                }
            } else {
                this.productsFiltered = this.data
            }
        }
    }

    openModal(description: string = '', icon: string = 'alert.svg'): void {
        const modalRef = this.modal.open(CompareModalComponent)
        modalRef.componentInstance.desc =
            description ||
            'Algo não aconteceu como deveria, por favor tente novamente.'
        modalRef.componentInstance.figure = '/assets/images/' + icon
        modalRef.componentInstance.showFooter = false
    }

    toggleItem(i: any, item: any, event: Event){
        this.active = i;
        event.preventDefault();
        const headerHeight = 100;
        const element = this.elementRef.nativeElement.querySelector(`#${item}`);
        if (element) {
            const yOffset = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: yOffset - headerHeight, behavior: 'smooth' });
        }
    }
}
