<div class="container">
    <div class="container-content">
        <div class="container-main">
            <div class="container-list">
                <ul  [ngStyle]="items.length > 1 ? {'justify-content': 'space-between'} : {'justify-content': 'center'}">
                    <li class="certification"
                        *ngFor="let item of items" (click)="changeImage(item)"
                        [ngClass]="{'selected-pdf': selectedPdf === item}">
                            <div class="icon"></div>
                            <p class="body1">
                                {{item.document.title}}
                            </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="container-download">
            <a *ngIf="!pdfAtual" disabled>
                <app-mybutton [download]="true" [disable]="true" [linkDownload]="selectedPdf" type="large" [text]="buttonLabel.btn_label"></app-mybutton>
            </a>
            <a *ngIf="pdfAtual" [href]="pdfAtual" [download]="pdfAtual" target="_blank">
                <app-mybutton [download]="true" [linkDownload]="selectedPdf" type="large" [text]="buttonLabel.btn_label"></app-mybutton>
            </a>
        </div>
    </div>
</div>
