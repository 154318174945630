<div class="page">
	<app-banner-internal
		[title]="whereToFindPageTitle"
		[subtitle]="whereToFindPageSubtitle"
	></app-banner-internal>
	<div class="text">
		<h2 class="title" [innerText]="[whereToFindText]"></h2>
	</div>

	<app-tabs [type]="'whereToFind'">
		<app-tab
			*ngFor="let tab of dataTabs; let i = index"
			[tabTitle]="
				lang == 'pt'
					? tab.name
					: lang == 'es'
					? tab.name_es
					: tab.name_en
			"
			[active]="i === 0"
		>
			<app-map
				(btnClick)="onOpen($event)"
				[type]="i == 0 ? 'assistencia-purificador' : 'assistencia-gelo'"
				[placeHolderCep]="whereToFindPagePostal"
				[mapsTextButton]="mapsTextButton"
				[findAddress]="findAddress"
				[mapsAddress]="mapsAddress"
				[mapsCity]="mapsCity"
				[mapsNeighborhood]="mapsNeighborhood"
				[mapsState]="mapsState"
				[notKnowCep]="notKnowCep"
			></app-map>
		</app-tab>
		<!-- <app-tab [active]="false" [tabTitle]="'maquinas de gelo'">
      <div class="form__container">
        <div class="form__container__header">
          <h3>Preencha o formulário, o revendedor da sua região entrará em contato em até 3 dias.</h3>
        </div>
        <div class="form__container__wave">
          <div class="form__container__wave__content">
            <img [src]="'/assets/images/wave-dropdown-blue.svg'" [alt]="'wave'" />
          </div>
        </div>
        <div class="form__container__body">
          <form action='#'
            autocomplete='off'
            (ngSubmit)='onSubmit()'
            [formGroup]='form'>
            <div class="row">
              <div class="col-md-12">
                <app-input
                  [type]="'whereToFind'"
                  id='find-name'
                  name='name'
                  labelName='nome/razão social'
                  [placeholder]="'nome/razão social'"
                  [form]='form'
                ></app-input>
              </div>
              <div class="col-md-12">
                <app-input
                  [type]="'whereToFind'"
                  id='find-document'
                  name='document_number'
                  labelName='CPF/CNPJ'
                  [placeholder]="'CPF/CNPJ'"
                  [form]='form'
                  [inputMask]="'000.000.000-00||00.000.000/0000-00'"
                ></app-input>
              </div>
              <div class="col-md-6">
                <app-input
                  [type]="'whereToFind'"
                  id='find-phone'
                  name='phone'
                  labelName='tel.comercial'
                  [placeholder]="'tel.comercial'"
                  [inputMask]="'(00) 0000-0000'"
                  [form]='form'>
                </app-input>
              </div>
              <div class="col-md-6">
                <app-input
                  [type]="'whereToFind'"
                  id='find-celular'
                  name='cell_phone'
                  labelName='celular'
                  [placeholder]="'celular'"
                  [form]='form'
                  [inputMask]="'(00) 00000-0000'"
                ></app-input>
              </div>
              <div class="col-md-12">
                <app-input
                  [type]="'whereToFind'"
                  id='find-email'
                  name='email'
                  [placeholder]="'email'"
                  typeInput="email"
                  labelName='E-mail'
                  [form]='form'
                ></app-input>
              </div>
              <div class="col-md-12">
                <app-select
                  [type]="'whereToFind'"
                  id='find-model'
                  name='model'
                  labelName='Modelo'
                  [form]='form'
                  [options]="models"
                ></app-select>
              </div>
            </div>
            <button type="submit" [disabled]="form.invalid">Enviar</button>
          </form>
        </div>
      </div>
    </app-tab> -->
	</app-tabs>
	<div *ngIf="modalActive" class="modal-wrapper">
		<div class="modal-card">
			<button (click)="closeModal()" class="close">
				<img src="./assets/images/Cross.png" />
			</button>
			<div class="frm">
				<img src="./assets/images/snip.png" />
				<input type="text" [(ngModel)]="routeLocalAddress" />
				<input type="text" [(ngModel)]="routeAddress" />
				<button (click)="getRoute()">{{ mapsTextButton }}</button>
			</div>
		</div>
	</div>
</div>
