<div class="pantanal">
   <app-highlight-pantanal [data]="dataHighlight[0]"></app-highlight-pantanal>
   <section class="pantanal__wrapper-video">
       <div class="container">
           <div class="play">
               <iframe [src]="dataUrlVideo" width="100%" height="518px" frameborder="0" allowfullscreen></iframe>
           </div>
       </div>
       <p class="pantanal__wrapper-video__description">
           {{ data?.section_video_text_description }}
       </p>
   </section>
   <app-grid-text-pantanal [data]="data?.gride"></app-grid-text-pantanal>
   <section class="pantanal__bioma container">
       <div class="pantanal__bioma__bg">

           <div class="content-text">
               <div class="title" [innerHTML]="data?.section_o_bioma_text_title"></div>
               <div class="text" [innerHTML]="data?.section_o_bioma_text"></div>
           </div>
       </div>
   </section>
   <section class="pantanal__missao container">
       <div class="pantanal__missao__title" [innerHTML]="data?.section_we_mission_text_title"></div>
       <div class="pantanal__missao__wrapper-icones">
           <div *ngFor="let item of data?.weMissionImage">
               <figure>
                   <img [src]="item.image.url" [alt]="item.image.title">
               </figure>
           </div>
       </div>
       <div class="pantanal__missao__content-text" [innerHTML]="data?.section_we_mission_text"></div>
   </section>
    <section class="pantanal__parceira">
        <div class="pantanal__parceira__wrapper-content container">
            <div class="pantanal__parceira__wrapper-content-text">
                <div class="pantanal__parceira__wrapper-content-text__title" [innerHTML]="data?.section_the_partners_text_title"></div>
                <div class="pantanal__parceira__wrapper-content-text__text" [innerHTML]="data?.section_the_partners_text_description"></div>
                <p class="pantanal__parceira__wrapper-content-text__autor">
                    {{data?.section_the_partners_text_author}}
                </p>
            </div>
            <figure>
                <img [src]="data?.section_the_partners_image.url" [alt]="data?.section_the_partners_image.title">
            </figure>
        </div>
    </section>
    <section class="pantanal__registro container">
        <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
        >
            <div ngxSlickItem *ngFor="let item of data?.projectsImage" class="slide">
                <img class="image" [src]="item.image.url" [alt]="item.image.title">
            </div>
        </ngx-slick-carousel>
    </section>
</div>
