import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-ice-machine-banner',
	templateUrl: './ice-machine-banner.component.html',
	styleUrls: ['./ice-machine-banner.component.scss'],
})
export class IceMachineBannerComponent implements OnInit {
	@Input() data: any = {}
	constructor() {}
	@Output() openModal: EventEmitter<boolean> = new EventEmitter()
  onClick(event: any) {
    event.preventDefault();
    this.openModal.emit(true);
  }

	ngOnInit(): void {}
}
