<div class="page">
  <app-banner-internal title="{{ exportationBannerText }}"></app-banner-internal>

  <div class="form container">
    <form action='#' autocomplete='off' (ngSubmit)='onSubmit()' [formGroup]='form'>
      <div class="row">
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-fullName' name='name' lettersOnly
            [labelName]='exportationInputName' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-email' name='email' typeInput='email'
            [labelName]='exportationInputEmail' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="onlyNumbersPlaceHolder" [type]="'contact'" id='contact-phone' name='phone'
            [labelName]='exportationInputPhone' [inputMask]="'(00) 0000-0000'" [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="onlyNumbersPlaceHolder" [type]="'contact'" id='contact-cell_phone' name='cell_phone'
            [labelName]='exportationInputCellPhone' [inputMask]="'(00) 00000-0000'" [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-company' name='company'
            [labelName]='exportationInputCompany' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-country' name='country' lettersOnly
            [labelName]='exportationInputCountry' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-city' name='city' lettersOnly
            [labelName]='exportationInputCity' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-select [placeholder]="''" [type]="'contact'" id='contact-product' name='product'
            [labelName]='exportationInputProduct' [placeholder]="exportationInputProductPlaceholder" [form]='form'
            [options]="options">
          </app-select>
        </div>
        <div class="col-md-12">
          <app-text-area id='contact-message' name='messenger' [placeholder]="exportationInputMessagePlaceholder"
            [labelName]='exportationInputMessage' [form]='form'>
          </app-text-area>
        </div>
      </div>
      <button type='submit' [disabled]='form.invalid' [innerText]="exportationSendButton"></button>
    </form>
  </div>
</div>