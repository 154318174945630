import { Component, OnInit } from '@angular/core'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-differential',
	templateUrl: './differential.component.html',
	styleUrls: ['./differential.component.scss'],
})
export class DifferentialComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		this.getDifferencial(lang)
	}
	dataDifferencial: any
	differential: string | null

	getDifferencial(lang: string) {
		this.differential = this.route.snapshot.paramMap.get('differential')
		const self = this
		this.content.getPage('pages/?type=cms.Differentials&fields=*&', lang, {
			fnSuccess(data) {
				self.title.setTitle(
					data.items[0].meta.seo_title || 'Diferenciais'
				)
				self.meta.addTag({
					name: 'description',
					content: data.items[0].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[0].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					},
					"property='og:title'"
				)
				self.dataDifferencial = data.items[0]
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
}
