<div class="gallery">
    <h3 *ngIf="showTitle" class="title">{{data.name}}</h3>
    <div class="d-flex flex-column align-items-center">
        <div class="image-main" [ngStyle]="{'max-width': width ? width : '100%'}">
            <button class="left" (click)="prev()">
                <img
                    draggable="false"
                    src="./assets/images/arrow-left-blue.png"
                    alt="prev"
                    title="prev"
                    class="prev"
                />
            </button>
            <ng-container *ngFor="let item of data.gallery; let k = index">

                <div
                    class="image-description"
                    [class.active]="id === k"
                >
                    <div>
                        <img
                            style="width: 350px;object-fit: cover"
                            draggable="false"
                            [src]="item.image.url ? item.image.url : ''"
                            [alt]="item.image.alt"
                            [title]="item.image.alt"
                        />
                    </div>
                </div>


            </ng-container>
            <button class="right" (click)="next()">
                <img
                    class="next"
                    draggable="false"
                    src="./assets/images/arrow-right-blue.png"
                    alt="next"
                    title="next"
                />
            </button>
        </div>
        <div *ngIf="dots" class="list-image">
            <div
                *ngFor="let item of data.gallery; let k = index"
                class="item-image"
                (click)="clickImages(k)"
            >
                <div
                    class="dots"
                    [class.active]="id === k"
                >
                </div>
            </div>
        </div>
        <div *ngIf="preview" class="list-image">
            <div
                *ngFor="let item of data.gallery; let k = index"
                class="item-image"
                (click)="clickImages(k)"
            >
                <figure
                    class="image"
                    [class.active]="id === k"
                >
                    <img
                        draggable="false"
                        [src]="item.image.url ? item.image.url : ''"
                        [alt]="item.image.alt"
                        [title]="item.image.alt"
                    />
                </figure>
            </div>
        </div>
    </div>
</div>
