import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[carousel]'
})
export class CarouselDirective implements OnInit {
  private isDragging = false;
  private startX = 0;
  private scrollLeft = 0;
  width = window.innerWidth
  constructor(private eleRef: ElementRef) { }

  ngOnInit() {
    if (this.width >= 1024) {
      this.dragCarousel()
    }
  }

  dragCarousel() {
    const container = this.eleRef.nativeElement;

    const end = () => {
      this.isDragging = false;
      container.classList.remove('active');
    };

    const start = (e: MouseEvent | TouchEvent) => {
      this.isDragging = true;
      container.classList.add('active');
      this.startX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX - container.offsetLeft;
      this.scrollLeft = container.scrollLeft;
    };

    const move = (e: MouseEvent | TouchEvent) => {
      if (!this.isDragging) return;

      e.preventDefault();
      const x = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX - container.offsetLeft;
      const dist = x - this.startX;
      container.scrollLeft = this.scrollLeft - dist;
    };

    container.addEventListener('mousedown', start);
    container.addEventListener('touchstart', start);

    container.addEventListener('mousemove', move);
    container.addEventListener('touchmove', move);

    container.addEventListener('mouseleave', end);
    container.addEventListener('mouseup', end);
    container.addEventListener('touchend', end);
  }
}
