<div class='control select'>
    <div class='form-group' [ngClass]="{'blueInput': type == 'whereToFind', 'contactSelect': type == 'contact', 'revendaSelect' : type == 'revenda'}">
        <label
            [for]='id || name'
            [class.default]='!labelName'
            [formGroup]='form'
            [ngClass]="{'filter-select': isFilterSelect}"
        >
            <span *ngIf="type != 'whereToFind'  || type == 'whereToFind' && select.value == ''" class='control-label'>{{ labelName }}</span>
            <select
                [id]='id || name'
                [attr.name]='name'
                [formControlName]='name'
                [setValue]='inputModel'
                (change)='onChange()'
                #select
            >
                <option value="" >{{ placeholder }}</option> 
                <option *ngFor='let opt of options' [value]='opt.value'>
                    {{ opt.label }}
                </option>
            </select>
            <figure *ngIf="type !== 'whereToFind' && type !== 'contact'">
                <img src='/assets/images/arrow-down.svg' alt='' />
            </figure>
            <figure *ngIf="type === 'whereToFind'">
                <img src='/assets/images/arrow-down.svg' alt='' />
            </figure>
        </label>
        <span
            *ngIf="
				form.controls[name || ''].invalid &&
				(form.controls[name || ''].touched ||
					form.controls[name || ''].dirty)
			"
            class='control-error'
            [setFormControlError]="{
				objError: form.controls[name || ''].errors,
				control: labelName
			}"
        >
		</span>
    </div>
</div>
