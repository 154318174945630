<header>
	<div class="header__top">
		<div class="container">
			<button class="hamburger" (click)="openMenu()">
				<figure>
					<img src="/assets/images/hamburger.svg" alt="menu" />
				</figure>
			</button>
			<div class="logo">
				<a [routerLink]="'/' + langSelected + '/home'">
					<figure>
						<img
							src="/assets/images/logo-slogan.svg"
							alt="Everest"
						/>
					</figure>
				</a>
			</div>
			<div class="header__center">
				<div class="container">
					<nav>
						<ul class="menu" *ngIf="dataMenu">
							<li
								*ngFor="let item of dataMenu; index as i"
								class="menu__item"
							>
								<div class="dropdown-hover">
									<button
										*ngIf="item.menu_sub_item.length"
										class="dropdown-hover__face"
									>
										<div class="dropdown-hover__text">
											{{ item.label }}
										</div>
										<div
											class="dropdown-hover__arrow"
										></div>
									</button>
									<a
										*ngIf="item.menu_sub_item.length < 1"
										[routerLink]="
											'/' + langSelected + '/' + item.url
										"
										[attr.data-menuHeader]="item.label"
										class="dropdown-hover__face ga-menu-header"
									>
										<div
											class="dropdown-hover__text ga-menu-header"
											[attr.data-menuHeader]="item.label"
										>
											{{ item.label }}
										</div>
									</a>
									<ul
										*ngIf="item.menu_sub_item.length"
										class="dropdown-hover__items"
									>
										<li
											*ngFor="
												let submenu of item.menu_sub_item
											"
										>
											<a
												*ngIf="submenu.open_in_new_tab"
												[href]="submenu.url"
												[attr.data-menuHeader]="
													submenu.label
												"
												class="ga-menu-header"
												target="_blank"
											>
												{{ submenu.label }}
											</a>
											<a
												*ngIf="!submenu.open_in_new_tab"
												[routerLink]="
													'/' +
													langSelected +
													'/' +
													submenu.url
												"
												class="ga-menu-header"
												[attr.data-menuHeader]="
													submenu.label
												"
											>
												{{ submenu.label }}
											</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</nav>
				</div>
			</div>
			<div class="right">
				<div class="languages">
					<ul>
						<li *ngFor="let lang of languages">
							<a
								[ngClass]="{
									active: langSelected === lang.lang
								}"
								(click)="changeLang(lang.lang)"
							>
								<figure>
									<img
										*ngIf="langSelected !== lang.lang"
										[src]="'/assets/images/' + lang.icon"
										[alt]="lang.lang"
									/>
									<img
										*ngIf="langSelected === lang.lang"
										[src]="
											'/assets/images/' + lang.iconActive
										"
										[alt]="lang.lang"
									/>
								</figure>
							</a>
                            <span>{{lang.title}}</span>
						</li>
					</ul>
				</div>
				<div
					class="dropdown-hover"
					*ngIf="dataHelp"
					style="z-index: 9999"
				>
					<button class="dropdown-hover__face">
						<div class="dropdown-hover__text">
							{{ dataHelp.title }}
						</div>
						<div class="dropdown-hover__arrow">
							<img
								src="/assets/images/arrow-down-blue.svg"
								alt="seta"
							/>
						</div>
					</button>
					<ul class="dropdown-hover__items" *ngIf="dataHelp">
						<li *ngFor="let submenu of dataHelp.menu_items">
							<a
								*ngIf="submenu.open_in_new_tab"
								[href]="submenu.url"
								class="ga-menu-header"
								[attr.data-menuHeader]="submenu.label"
								target="_blank"
							>
								{{ submenu.label }}
							</a>
							<a
								*ngIf="!submenu.open_in_new_tab"
								[routerLink]="
									'/' + langSelected + '/' + submenu.url
								"
								class="ga-menu-header"
								[attr.data-menuHeader]="submenu.label"
							>
								{{ submenu.label }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="showMenu" @fade class="header__menu header__menu--mobile">
		<div class="content">
			<button class="close" (click)="closeMenu()">
				<figure>
					<img src="/assets/images/close-menu.svg" alt="fechar" />
				</figure>
			</button>
			<div class="logo">
				<a routerLink="/" (click)="closeMenu()">
					<figure>
						<img
							src="/assets/images/logoNavBar.png"
							alt="Everest"
						/>
					</figure>
				</a>
			</div>
			<div class="container">
				<nav>
					<ul class="dropdown" *ngIf="dataMenu">
						<li
							*ngFor="let item of dataMenu; index as i"
							class="dropdown__item"
						>
							<button
								*ngIf="item.menu_sub_item.length"
								class="button arrow"
								(click)="toggleSubMenu(i)"
							>
								{{ item.label }}
							</button>
							<a
								*ngIf="item.menu_sub_item.length < 1"
								[routerLink]="
									'/' + langSelected + '/' + item.url
								"
								(click)="closeMenu()"
								[attr.data-menuHeader]="item.label"
								class="button ga-menu-header"
							>
								{{ item.label }}
							</a>
							<ul
								*ngIf="
									item.menu_sub_item.length &&
									submenuOpen === i
								"
								@slideInOut
							>
								<li *ngFor="let submenu of item.menu_sub_item">
									<a
										*ngIf="submenu.isExternal"
										[href]="submenu.url"
										[attr.data-menuHeader]="submenu.label"
										class="ga-menu-header"
										target="_blank"
									>
										{{ submenu.label }}
									</a>
									<a
										*ngIf="!submenu.isExternal"
										[routerLink]="
											'/' +
											langSelected +
											'/' +
											submenu.url
										"
										(click)="closeMenu()"
										[attr.data-menuHeader]="submenu.label"
										class="ga-menu-header"
									>
										{{ submenu.label }}
									</a>
								</li>
							</ul>
						</li>
						<li class="dropdown__item">
							<button
								*ngIf="dataHelp && dataHelp.length"
								class="button arrow"
								(click)="toggleSubMenu('help')"
							>
								{{ dataHelp.title }}
							</button>
							<ul
								*ngIf="
									dataHelp &&
									dataHelp.length &&
									submenuOpen === 'help'
								"
								@slideInOut
							>
								<li *ngFor="let submenu of dataHelp.menu_items">
									<a
										*ngIf="submenu.open_in_new_tab"
										[href]="submenu.url"
										[attr.data-menuHeader]="submenu.label"
										class="ga-menu-header"
										target="_blank"
									>
										{{ submenu.label }}
									</a>
									<a
										*ngIf="!submenu.open_in_new_tab"
										[routerLink]="
											'/' +
											langSelected +
											'/' +
											submenu.url
										"
										(click)="closeMenu()"
										[attr.data-menuHeader]="submenu.label"
										class="ga-menu-header"
									>
										{{ submenu.label }}
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
				<div class="languages">
					<ul>
						<li *ngFor="let lang of languages">
							<a
								[ngClass]="{
									active: langSelected === lang.lang
								}"
								(click)="changeLang(lang.lang)"
							>
								<figure>
									<img
										*ngIf="langSelected !== lang.lang"
										[src]="'/assets/images/' + lang.icon"
										[alt]="lang.lang"
									/>
									<img
										*ngIf="langSelected === lang.lang"
										[src]="
											'/assets/images/' + lang.iconActive
										"
										[alt]="lang.lang"
									/>
								</figure>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</header>
