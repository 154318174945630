import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'

@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnInit {
	@Input() data: any = []
	@Input() dataHome: any = []
	slideConfig = {
		slidesToScroll: 1,
		dots: false,
		infinite: false,
		lazyLoad: 'ondemand',
		speed: 300,
		slidesToShow: 1,
		mobileFirst: true,
		swipeToSlide: true,
		variableWidth: true,
		touchThreshold: 4,
		centerMode: false,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
				},
			},
		],
	}
	@Input() buyBtn = ''
	@Input() knowBtn = ''

	constructor() {}

	ngOnInit(): void {}

	progressBarValue = 20

	onAfterChange(event: any) {
		const slideCount = this.data.length
		const currentIndex = event.currentSlide
		const progressPercentage = ((currentIndex + 2) / slideCount) * 100
		this.progressBarValue = progressPercentage
	}
}
