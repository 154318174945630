<div class="banner-internal">
    <div class="banner_content container">
        <div class="banner_body">
            <h1 *ngIf="type == 'economia'" class="title"><b>{{ title.split(' ')[0] }}</b> {{ title.substr(title.indexOf(' ') + 1) }}</h1>
            <h1 *ngIf="type == ''" class="title" [innerHTML]="title"></h1>
            <h2 class="subtitle" [innerHTML]="subtitle"></h2>
        </div>
    </div>
    <figure>
        <app-image class="banner-image a1" path="./assets/images/banner-internald.png" title="banner" type="banner-internal"></app-image>
        <app-image class="banner-image a2" path="./assets/images/banner-internal.png" title="banner" type="banner-internal"></app-image>
    </figure>
</div>
