import { Component, Input, OnInit } from '@angular/core'

export interface IKnowMore {
	us_title: string
	us_description: string
	us_cta_link: string
	us_cta_label: string
	us_image: string | null
	learn_title: string
	learn_description: string
	learn_cta_link: string | null
	learn_cta_label: string
	learn_image: string | null
	us_subtitle: string | null
	learn_subtitle: string | null
}

@Component({
	selector: 'app-know-more',
	templateUrl: './know-more.component.html',
	styleUrls: ['./know-more.component.scss'],
})
export class KnowMoreComponent implements OnInit {
	@Input() data: IKnowMore

	cards: any = []
	parallax: any = {
		img1: 0,
		img2: 0,
	}

	constructor() {}

	ngOnInit(): void {
		const self = this
		self.cards = [
			{
				img: this.data.us_image || './assets/images/post2.png',
				text: 'post1',
				subtitle: this.data.us_subtitle,
				title: this.data.us_title,
				body: this.data.us_description,
				ctaLink: this.data.us_cta_link,
				ctaLabel: this.data.us_cta_label,
			},
			{
				img:
					this.data.learn_image ||
					'./assets/images/Rectangle 4340 (1).png',
				text: 'post2',
				subtitle: this.data.learn_subtitle,
				title: this.data.learn_title,
				body: this.data.learn_description,
				ctaLink: this.data.learn_cta_link,
				ctaLabel: this.data.learn_cta_label,
			},
		]
		// setTimeout(() => {
		// 	window.addEventListener('scroll', self.parallaxImages.bind(self))
		// }, 1500)
	}

	isScrolledIntoView(): boolean {
		const element = document.querySelector('#know-more')
		if (element) {
			const position = element.getBoundingClientRect()

			return position.top < window.innerHeight && position.bottom >= 0
		}
		return false
	}

	parallaxImages(): void {
		const self = this
		const img1 = document.querySelector('.item1 img')
		const img2 = document.querySelector('.item2 img')
		const sectionParent = document.querySelector('#know-more')
		const widthWindow = window.innerWidth

		if (self.isScrolledIntoView() && sectionParent) {
			const scrollWindow = Math.round(
				sectionParent.getBoundingClientRect().top
			)

			if (img1 && img2) {
				self.parallax.img1 = self.parallax.img1
					? self.parallax.img1
					: Math.round(img1.getBoundingClientRect().top)
				self.parallax.img2 = self.parallax.img2
					? self.parallax.img2
					: Math.round(img2.getBoundingClientRect().top)
			}
			if (img1) {
				const scrollElement = self.parallax.img1
				let height =
					Math.round(img1.getBoundingClientRect().height) * 1.5

				if (widthWindow > 767 && widthWindow <= 1199) {
					height =
						Math.round(img1.getBoundingClientRect().height) * 3.2
				} else if (widthWindow > 1199 && widthWindow <= 1280) {
					height =
						Math.round(img1.getBoundingClientRect().height) * 1.7
				} else if (widthWindow > 1279 && widthWindow <= 1365) {
					height =
						Math.round(img1.getBoundingClientRect().height) * 1.6
				} else if (widthWindow > 1365 && widthWindow <= 1439) {
					height =
						Math.round(img1.getBoundingClientRect().height) * 1.5
				} else if (widthWindow > 1439) {
					height =
						Math.round(img1.getBoundingClientRect().height) * 3.5
				}
				// @ts-ignore
				img1.style.top = scrollWindow - scrollElement + height + 'px'
			}
			if (img2) {
				const scrollElement = self.parallax.img2
				let height =
					Math.round(img2.getBoundingClientRect().height) * 4.3
				if (widthWindow === 375) {
					height = Math.round(img2.getBoundingClientRect().height) * 4
				} else if (widthWindow >= 414 && widthWindow < 768) {
					height =
						Math.round(img2.getBoundingClientRect().height) * 3.5
				} else if (widthWindow > 767 && widthWindow <= 1199) {
					height =
						Math.round(img2.getBoundingClientRect().height) * 3.3
				} else if (widthWindow > 1199 && widthWindow <= 1280) {
					height =
						Math.round(img2.getBoundingClientRect().height) * 2.1
				} else if (widthWindow > 1279 && widthWindow <= 1365) {
					height =
						Math.round(img2.getBoundingClientRect().height) * 2.3
				} else if (widthWindow > 1365 && widthWindow <= 1439) {
					height =
						Math.round(img2.getBoundingClientRect().height) * 1.8
				} else if (widthWindow > 1439) {
					height = Math.round(img2.getBoundingClientRect().height) * 4
				}
				// @ts-ignore
				img2.style.top = scrollWindow - scrollElement + height + 'px'
			}
		}
	}
}
