<section class="highlight">
    <figure>
        <img class="desktop" [src]="data.bannerDesktop.url"  alt="Pantanal SOS">
        <img class="mobile" [src]="data.bannerMobile.url"  alt="Pantanal SOS">
    </figure>
    <div class="overley">
        <picture>
            <img [src]="data.logo.url" alt="Pantanal SOS">
        </picture>
        <div class="title" [innerHTML]="data.title"></div>
        <div class="sub-title" [innerHTML]="data.text"></div>
    </div>
</section>
