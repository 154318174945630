import { Directive, Input, ElementRef, HostListener } from '@angular/core'

@Directive({
	selector: '[parallax]',
})
export class ParallaxDirective {
	@Input('ratio') parallaxRatio: number = 1
	@Input('side') parallaxSide: string = 'right'
	initialTop: number = 0
	width = 700

	constructor(private eleRef: ElementRef) {
		this.width = window.innerWidth
		if (window.innerWidth <= 1500) {
			this.initialTop =
				this.eleRef.nativeElement.getBoundingClientRect().top - 320
		} else if (window.innerWidth <= 1800) {
			this.initialTop =
				this.eleRef.nativeElement.getBoundingClientRect().top - 300
		} else {
			this.initialTop =
				this.eleRef.nativeElement.getBoundingClientRect().top - 95
		}
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event: any) {
		if (window.innerWidth >= 900) {
			this.eleRef.nativeElement.style[this.parallaxSide] =
				this.initialTop - window.scrollY * this.parallaxRatio + 'px'
		}
	}
}
