<div class="input-check control-checkbox">
	<label [for]="'c-' + id || name" [formGroup]="form">
		<input
			type="checkbox"
			[id]="'c-' + id || name"
			[attr.name]="name"
			[attr.disabled]="disabled ? true : null"
			[formControlName]="name"
			[setValue]="inputModel"
			[value]="value"
			(change)="fnChangeModel($event)"
		/>
		<div class="checkmark"></div>
		<span>
			{{ labelName }}
			<ng-content></ng-content>
		</span>
	</label>
	<span
		*ngIf="
			form.controls[name || ''].invalid &&
			(form.controls[name || ''].touched ||
				form.controls[name || ''].dirty)
		"
		class="control-error"
		[setFormControlError]="{
			objError: form.controls[name || ''].errors,
			control: labelName
		}"
	>
	</span>
</div>
