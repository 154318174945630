<section class="product-banner">
    <div class="bg">
        <app-image path="./assets/images/water-min.png"></app-image>
        <div class="bg-color">
            <div class="content">
                <div class="content-title" [innerHTML]="data.element_filter_title"></div>
                <div class="content-description mb-3" [innerHTML]="data.element_filter_description"></div>
                <a class="button" [href]="data.element_filter_cta_link">
                    <app-mybutton type="filter" [text]="data.element_filter_cta_label" [ga]="data.element_filter_title + ' - ' + data.element_filter_cta_label"></app-mybutton>
                </a>
            </div>
        </div>
    </div>
    <picture class="filtro">
        <img src="./assets/images/filtro-home.png" [alt]="data.element_filter_title">
    </picture>
</section>

