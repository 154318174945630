<section id="conference-timeline">
  <div class="timeline-start" [innerHTML]="data.timeline_title"></div>
  <div class="conference-center-line"></div>
  <div class="conference-timeline-content">
    <div class="timeline-article" *ngFor="let line of data.timeline let i = index">
      <div *ngIf="i % 2 == 0">
        <div class="content-left-container">
          <div class="image">
            <app-image *ngIf="line.image_one" [path]="line.image_one.url"></app-image>
            <app-image *ngIf="line.image_two" [path]="line.image_two.url"></app-image>
          </div>
        </div>
        <div class="content-right-container">
          <div class="content-right">
            <h3>{{ line.year }}</h3>
            <p>{{ line.text }}</p>
          </div>
        </div>
        <div class="line-right">
          <hr>
        </div>
      </div>

      <div *ngIf="i % 2 != 0">
        <div class="content-left-container">
          <div class="content-left">
            <h3>{{ line.year }}</h3>
            <p>{{ line.text }}</p>
          </div>
        </div>
        <div class="content-right-container">
          <div class="image">
            <app-image *ngIf="line.image_one" [path]="line.image_one.url"></app-image>
            <app-image *ngIf="line.image_two" [path]="line.image_two.url"></app-image>
          </div>
        </div>
        <div class="line-left">
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>