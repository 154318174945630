import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from 'src/app/services/content/content.service'

@Component({
	selector: 'app-selo-abrafipa',
	templateUrl: './selo-abrafipa.component.html',
	styleUrls: ['./selo-abrafipa.component.scss'],
})
export class SeloABRAFIPAComponent implements OnInit {
	constructor(
		private title: Title,
		private meta: Meta,
		private content: ContentService,
		private route: ActivatedRoute
	) {}

	data: any = {}

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage('pages/?type=cms.Stamp&fields=*&', lang, {
			fnSuccess(data) {
				self.data = data.items[0]
			},
			fnError(error) {
				console.warn(error)
			},
		})
		this.title.setTitle('Selo Abrafipa')
		this.meta.addTag({
			name: 'description',
			content: 'teste',
		})
		this.meta.updateTag(
			{
				property: 'og:description',
				content: 'teste',
			},
			"property='og:description'"
		)
		this.meta.updateTag(
			{
				property: 'og:title',
				content: 'teste',
			},
			"property='og:title'"
		)
	}
}
