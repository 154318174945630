<div class="container">
    <div class="wrapper-banner"  [style.background-image]="'url(' + data.ice_shape_image.url + ')'">
        <div class="row">
            <div class="col-lg-5 mb-3">
                <div class="wrapper-content container">
                    <div class="wrapper-content__title">
                        <p [innerHTML]="data.ice_shape_title"></p>
                    </div>
                    <div class="wrapper-content__desc">
                        <p [innerHTML]="data.ice_shape_description"></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 mb-5">
                <div class="wrapper-content container">
                    <div class="wrapper-content__subtitle">
                        <p
                            *ngIf="data.ice_shape_text"
                            [innerHTML]="data.ice_shape_subtitle"
                            class="mb-0 p-border title"
                        ></p>
                    </div>
                    <div class="wrapper-content__text">
                        <p [innerHTML]="data.ice_shape_text"></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 mb-3">
                <div class="wrapper-content container">
                    <img
                        *ngIf="data.ice_shape_thumb"
                        [src]="data.ice_shape_thumb.url"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
