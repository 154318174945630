import { Component, OnInit, Input } from '@angular/core'

@Component({
	selector: 'app-highlight-product',
	templateUrl: './highlight-product.component.html',
	styleUrls: ['./highlight-product.component.scss'],
})
export class HighlightProductComponent implements OnInit {
	@Input() data: any = {}

	constructor() {}
	ngOnInit() {}
}
