import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[numbersOnly]',
})
export class NumbersOnlyDirective {
	@HostListener('input', ['$event'])
	onInput(event: KeyboardEvent) {
		const input = event.target as HTMLInputElement
		const value = input.value
		const sanitizedValue = value.replace(/\D/g, '')

		if (value !== sanitizedValue) {
			input.value = sanitizedValue
			return { numberInvalid: true }
		}
		return null
	}
}
