import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-timeline',
	templateUrl: './timeline.component.html',
	styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
	@Input() data: any = []
	@Input() lines: any[] = [
		{
			year: 1970,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 1982,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
			image_two: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 1990,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 1998,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 2000,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
			image_two: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 2010,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
		},
		{
			year: 2023,
			text: 'Experiência e qualidade para te levar pro topo do mundo. Mais de 50 anos de experiência em água',
			image_one: '/assets/images/Maskgroup(2).png',
		},
	]

	constructor() {}

	ngOnInit(): void {}
}
