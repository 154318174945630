import {
	Component,
	ElementRef,
	Input,
	OnInit,
	HostListener,
} from '@angular/core'

@Component({
	selector: 'app-white-card',
	templateUrl: './white-card.component.html',
	styleUrls: ['./white-card.component.scss'],
})
export class WhiteCardComponent implements OnInit {
	@Input() type = ''
	@Input() showButton: boolean = true
	@Input() cards: any
	@Input() title: string
	@Input() body: string
	@Input() subtitle: string
	constructor() {}

	ngOnInit(): void {}
}
