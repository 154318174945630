import { Component, OnInit, ViewChild, ElementRef   } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ContentService } from 'src/app/services/content/content.service'
import { Store } from '@ngrx/store'
import { AppState } from '../../state/app-state'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute } from '@angular/router'
import {HiddenLoading, ShowLoading} from "../../state/loading/loading.action";

@Component({
	selector: 'app-press-room',
	templateUrl: './press-room.component.html',
	styleUrls: ['./press-room.component.scss'],
})
export class PressRoomComponent implements OnInit {
    lang: string = 'pt'
    dataPage: any = {}
    dataNews: any = {}
    dataLastNews: any = {}

    page: any = 1
    pageSize: any = 9

	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private store: Store<AppState>,
		private modal: NgbModal,
		private route: ActivatedRoute,
        private elementRef: ElementRef,
	) {
		this.lang = this.route.snapshot.params.lang
		this.getData(this.lang)
	}

	ngOnInit(): void {
        this.title.setTitle('Sala de Imprensa ')
        this.meta.addTag({
            name: 'description',
            content: 'Sala de Imprensa',
        })
        this.meta.updateTag(
            {
                property: 'og:description',
                content: 'Sala de Imprensa',
            },
            "property='og:description'"
        )
        this.meta.updateTag(
            {
                property: 'og:title',
                content: 'Sala de Imprensa',
            },
            "property='og:title'"
        )
	}
    getData(lang: string): void {
        const self = this
        self.store.dispatch(ShowLoading())
        self.content.getComparisonPage(
            `news/?fields=*&`,
            lang,
            {
                fnSuccess: (data) => {
                    self.dataNews = data.items
                    self.dataLastNews = data.items.splice(0, 4)
                },
                fnError: (err) => {
                    console.warn(err)
                },
                fnFinalized() {
                    self.store.dispatch(HiddenLoading())
                },
            }
        )
        self.content.getComparisonPage(
            'pages/?type=cms.CompanyRoom&fields=*&',
            lang,
            {
                fnSuccess: (data) => {
                    self.dataPage = data.items[0]
                },
                fnError: (err) => {
                    console.warn(err)
                },
                fnFinalized() {
                    self.store.dispatch(HiddenLoading())
                },
            }
        )
    }
}
