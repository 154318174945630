<div class="post" >
    <div class="container">
        <img
            class="post-image"
            [src]="data.image.url"
            [alt]="data.image.alt"
            title=""
        >
        <div class="post-blog">
            <app-white-card class="card-position" [title]="data.title" [cards]="data" type="large" [showButton]="false"></app-white-card>
        </div>
    </div>
</div>

