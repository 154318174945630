import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UserService } from '../../services/user/user.service'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ForgotUserPassword } from '../../models/user'

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    @Output() finalized = new EventEmitter<string>()
    form: FormGroup

    constructor(
        private userService: UserService,
        private toast: ToastrService,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            cpf: [null, [Validators.required, GenericValidatorService.isValidCpf]],
            email: [null, [Validators.required, Validators.email]]
        })
    }

    ngOnInit(): void {
        //
    }

    onSubmit(): void {
        const self = this

        if (self.form.valid) {
            const { cpf, email } = self.form.value

            const data: ForgotUserPassword = {
                cpf,
                email
            }
            self.userService.forgotPassword(data, {
                fnSuccess() {
                    self.toast.success('Senha enviada para o seu e-mail.', 'Sucesso!')
                    self.finalized.emit('true')
                },
                fnError() {
                    self.toast.error('Algo deu errado!', 'Ops!!!')
                },
                fnFinalized() {
                    //
                }
            })
        }
    }

}
