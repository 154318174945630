import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-intern-highlight-product',
	templateUrl: './intern-highlight-product.component.html',
	styleUrls: ['./intern-highlight-product.component.scss'],
})
export class InternHighlightProductComponent implements OnInit {
	@Input() image: string = ''
	@Input() highlightTitle: string = ''
	@Input() title: string = ''
	@Input() description: string = ''
	constructor() {}

	ngOnInit(): void {}
}
