import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SlickCarouselComponent } from 'ngx-slick-carousel'

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
	@Input() banner: any
	@Input() summary: any
	@ViewChild('slickModal', { static: false })
	slickCarousel: SlickCarouselComponent
	@ViewChild('progress') progressBar: ElementRef
	lang: string = 'pt'

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		this.afterChange()
	}

	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	}

	currentSlide: number = 0
	progressBarValue = 0
	interval = 0
	countDown: any

	afterChange() {
        if (this.banner.length > 1){
            clearInterval(this.countDown)
            this.progressBarValue = 0
            this.interval = 0
            this.countDown = setInterval(() => {
                if (this.interval <= 4) {
                    const progressPercentage = (this.interval / 1) * 25
                    this.progressBarValue = progressPercentage
                } else {
                    clearInterval(this.countDown)
                    this.progressBarValue = 0
                    this.interval = 0
                    if (this.currentSlide < 3) {
                        this.currentSlide += 1
                        this.slickCarousel.slickGoTo(this.currentSlide)
                    } else {
                        this.currentSlide = 0
                        this.slickCarousel.slickGoTo(this.currentSlide)
                    }
                }
                this.interval++
            }, 1000)
        }
	}

	changeCard(number: number) {
		this.currentSlide = number
	}

	mudarSlide(number: number) {
		this.slickCarousel.slickGoTo(number)
		this.currentSlide = number
	}

	NextSlide() {
		if (this.currentSlide < 3) {
			this.currentSlide += 1
		} else {
			this.currentSlide = 0
		}
	}
	PrevSlide() {
		if (this.currentSlide > 0) {
			this.currentSlide -= 1
		} else {
			this.currentSlide = 3
		}
	}
}
