<div class="text">
    <div class="container">
        <div class="text-body">
            <p class="body1">
                CERTIFICAÇÃO DE SAÚDE <br>
                conforme norma NBR 16.098:2012<br>
                <br>
                Desde 31 de outubro de 2010, os fabricantes de filtros, purificadores, bebedouros, ozonizadores e demais equipamentos para tratamento de água para consumo humano, devem fornecer produtos certificados. Toda a linha de purificadores de água Everest conta com o atendimento à norma especifica de avaliação da conformidade, a NBR 16.098:2012, que avalia o desempenho e eficiência dos aparelhos através de ensaios realizados em laboratório creditado pelo INMETRO.<br>
                <br>
                Toda a linha de purificadores Everest possui esta certificação desde 29 de Junho de 2004. A Everest, na busca pela excelência dos seus produtos e serviços, saiu na frente para atender às exigências do INMETRO. A conquista do selo de conformidade "Saúde" para toda a linha de purificadores demonstra o comprometimento da empresa com o mercado consumidor.<br>
                <br>
                Nossos purificadores de água gelada e natural atualmente são certificados pelo Instituto Falcão Bauer da Qualidade – IFBQ e contam com o selo de identificação estabelecido pelo INMETRO, que garante a qualidade da água oferecida.<br>
                <br>
                CERTIFICAÇÃO DE SEGURANÇA ELÉTRICA E MECÂNICA<br>
                conforme portaria 191 do INMETRO de 10 de dezembro de 2003.<br>
                * Com exceção do Everest Baby que produz somente água natural.<br>
                <br>
                Desde 01 de Agosto de 2004, os fabricantes e importadores de purificadores e bebedouros, devem fornecer produtos certificados. Este processo estabelece a execução de ensaios em laboratórios, bem como a avaliação do sistema de gestão da qualidade do fabricante por organismo certificador creditado pelo INMETRO. A linha de purificadores de água gelada da Everest possui esta certificação desde 30 de Julho de 2004. O selo de conformidade "SEGURANÇA" caracteriza que a Everest garante a segurança elétrica e mecânica de seus produtos.

            </p>
        </div>
        <app-mybutton type="large" text="Download"></app-mybutton>
    </div>
</div>
