<div class="page">
	<app-banner-internal
		[title]="whereToFindPageTitle"
		[subtitle]="whereToFindPageSubtitle"
	></app-banner-internal>
    <div [style]="noResults || result.length ? '' : 'margin-bottom: calc(356px - 5vw)'">
        <div class="container">
            <div class="wrapper-search">
                <figure>
                    <img src="/assets/images/icon-maps.png" [alt]="lang === 'pt' ? 'onde encontrar' : 'donde encontrar'">
                    <figcaption>{{lang === 'pt' ? 'Selecione o produto' : 'Seleccione el producto'}}</figcaption>
                </figure>
                <form autocomplete="on" class="wrapper-form">
                    <div class="wrapper-form__selected">
                        <input type="radio" id="water" name="type" value="water" [(ngModel)]="type" hidden (change)="onInputChange()">
                        <label for="water">{{ lang === 'pt' ? 'Purificadores' : 'Purificadores'}}</label>
    
                        <input type="radio" id="ice" name="type" value="ice" [(ngModel)]="type" hidden (change)="onInputChange()">
                        <label for="ice">{{ lang === 'pt' ? 'Máquinas de gelo' : 'Maquinas de hielo'}}</label>
                    </div>
                    <div class="wrapper-select">
                        <div class="">
                            <select name="optionService" id="optionService" [(ngModel)]="optionService">
                                <option value="assistance" selected >Assistência técnica</option>
                                <option value="resaller">Revendas Autorizadas</option>
                            </select>
                        </div>
                        <div class="">
                            <select name="ufSelected" id="" [(ngModel)]="ufSelected" (change)="getCities(ufSelected)">
                                <option value="" selected disabled>Estado</option>
                                <option *ngFor="let item of ufOptions" [ngValue]="item.value">{{item.label}}</option>
                            </select>
                        </div>
                        <div class="">
                            <select name="hasCity" id="" [(ngModel)]="hasCity" (change)="onChange(hasCity)">
                                <option value="" selected disabled>Cidade</option>
                                <option *ngFor="let item of cityOptions" [ngValue]="item">{{item}}</option>
                            </select>
                        </div>
                        <div class="">
                            <button type="submit" (click)="searchByCep(1)">
                                <img style="z-index: 10;" src="/assets/images/icon-search-white.png" alt="Buscar">
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="spinner" *ngIf="loading"></div>
        </div>
        <div class="container" *ngIf="!loading && !citiesNear.length && result.length">
            <div class="wrapper-results" [style]="result.length !== result.length ? '' : ''">
                <div *ngFor="let item of result; index as i" class="card-results">
                    <div class="row">
                        <div class="col">
                            <h3 class="card-results--title">{{item.name }}</h3>
                        </div>
                        <div class="col">
                            <p class="card-results--text"><span class="font-weight-bold">{{ mapsAddress }}:</span> {{ item.address }}</p>
                            <p class="card-results--text"><span class="font-weight-bold">{{ mapsNeighborhood }}:</span> {{ item.neighborhood }}</p>
                            <p class="card-results--text">
                                <span class="font-weight-bold">{{ mapsState }}: </span> {{ item.state }} /
                                <span class="font-weight-bold">{{ mapsCity }}: </span> {{ item.city }}
                            </p>
                            <p class="card-results--text font-weight-bold">{{ item.phone_number }} <span *ngIf="item.phone_number_secondary">|</span> {{ item.phone_number_secondary }}</p>
                            <p class="card-results--text font-weight-bold">{{ item.email }}</p>
                        </div>
                    </div>
                </div>
                <ngb-pagination
                    [collectionSize]='data.total_items || 0'
                    [(page)]='page'
                    [pageSize]='data.page_size'
                    [maxSize]='5'
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    (pageChange)='changePagination($event)'
                >
                <ng-template ngbPaginationFirst>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m13.707 4.707-1.414-1.414L3.586 12l8.707 8.707 1.414-1.414L6.414 12l7.293-7.293z"/><path d="m19.707 4.707-1.414-1.414L9.586 12l8.707 8.707 1.414-1.414L12.414 12l7.293-7.293z"/></svg>   
                </ng-template>
                <ng-template ngbPaginationLast>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m11.707 3.293-1.414 1.414L17.586 12l-7.293 7.293 1.414 1.414L20.414 12l-8.707-8.707z"/><path d="M5.707 3.293 4.293 4.707 11.586 12l-7.293 7.293 1.414 1.414L14.414 12 5.707 3.293z"/></svg>
                </ng-template>
                <ng-template ngbPaginationPrevious>
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85875 13.5062C7.51417 13.8507 6.9555 13.8507 6.61092 13.5062L0.104646 6.99988L6.61092 0.493609C6.9555 0.149029 7.51417 0.149029 7.85875 0.493609C8.20333 0.838189 8.20333 1.39686 7.85875 1.74144L2.60032 6.99988L7.85875 12.2583C8.20333 12.6029 8.20333 13.1616 7.85875 13.5062Z" />
                    </svg>                        
                </ng-template>
                <ng-template ngbPaginationNext>
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.553357 0.493848C0.897937 0.149268 1.45661 0.149268 1.80119 0.493848L8.30746 7.00012L1.80119 13.5064C1.45661 13.851 0.897937 13.851 0.553357 13.5064C0.208777 13.1618 0.208777 12.6031 0.553357 12.2586L5.81179 7.00012L0.553357 1.74168C0.208777 1.3971 0.208777 0.838428 0.553357 0.493848Z" />
                    </svg>                        
                </ng-template>
            </ngb-pagination>
            </div>
            <div class="wrapper-no-results" style="margin-top: 80px">
                <p>{{lang === 'pt' ? 'Ainda não encontrou?' : '¿Aún no lo has encontrado?'}}<br>
                </p>
                <a style="color: #58595B;text-decoration: underline;font-size: 20px;" [href]="lang === 'pt' ? '/pt/atendimento' : '/es/atendimento'">
                    {{lang === 'pt' ? 'Entre em contato com nosso atendimento.' : 'Póngase en contacto con nuestro servicio de atención al cliente.'}}
                </a>
            </div>
        </div>
        <div class="container" *ngIf="!loading && citiesNear.length">
            <div class="wrapper-no-results">
                <h4>{{lang === 'pt' ? 'Não encontramos parceiros na sua cidade no momento.' : 'No podemos encontrar socios en su ciudad en este momento.'}}</h4>
                <p>{{lang === 'pt' ? 'Verifique as seguintes opções, e veja se atendem às suas necessidades:' : 'Marque las siguientes opciones y vea si satisfacen sus necesidades:'}}</p>
                <div class="wrapper-cities-near">
                    <div class="cities-near" *ngFor="let item of citiesNear; index as i">
                        <input hidden [id]="i" type="radio" [(ngModel)]="hasCitiesNear" (change)="onChangeCitiesNear(hasCitiesNear)" [value]="item">
                        <label [for]="i">{{item}}</label>
                    </div>
                </div>
                <div class="wrapper-no-results" style="margin-top: 80px">
                    <p>{{lang === 'pt' ? 'Ainda não encontrou?' : '¿Aún no lo has encontrado?'}}<br>
                    </p>
                    <a style="color: #58595B;text-decoration: underline;font-size: 20px;" [href]="lang === 'pt' ? '/pt/atendimento' : '/es/atendimento'">
                        {{lang === 'pt' ? 'Entre em contato com nosso atendimento.' : 'Póngase en contacto con nuestro servicio de atención al cliente.'}}
                    </a>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="!loading && noResults && !citiesNear.length && !result.length">
            <div class="wrapper-no-results">
                <h4>
                    {{lang === 'pt' ? 'Atualmente, não temos parceiros disponíveis na sua cidade ou nas proximidades.' : 'Actualmente no tenemos socios disponibles en su ciudad o cerca.'}}               
                </h4>
                <br>
                <p>
                    <a style="color: #58595B;text-decoration: underline;font-size: 20px;" [href]="lang === 'pt' ? '/pt/atendimento' : '/es/atendimento'">Entre em contato com nosso atendimento.</a>
                </p>
            </div>
        </div>
    </div>
</div>
