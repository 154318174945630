import {
	Component,
	ElementRef,
	HostListener,
	Input,
	OnInit,
} from '@angular/core'

@Component({
	selector: 'app-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
	@Input() cards: any = []

	constructor(private el: ElementRef) {}

	@Input() type: string = ''

	ngOnInit(): void {}

	deltaX = 0
	deltaY = 0

	onMouseMove(event: MouseEvent) {
		const rect = this.el.nativeElement.getBoundingClientRect()
		const x = event.clientX - rect.left
		const y = event.clientY - rect.top
		const centerX = rect.width / 2
		const centerY = rect.height / 2
		this.deltaX = (x - centerX) / 10
		this.deltaY = (y - centerY) / 10
	}

	onMouseLeave() {
		this.deltaX = 0
		this.deltaY = 0
	}
}
