<div class="economy">
    <div class="container">
        <div class="title">
            <p class="body1" [innerText]="economyTitle">
            </p>
        </div>
        <div class="form">
            <div class="dropdown-card">
                <div class="dropdown-card-header">
                    <p class="body1">{{ economyGallons }}</p>
                </div>
                <div class="dropdown-card-wave"></div>
                <div class="dropdown-card-gota">
                    <input type="text" placeholder="0" [(ngModel)]="galoes" name="galoes"
                        (ngModelChange)="validarNumeroNegativo(); atualizarResultado()" pattern="[0-9]{1,3}"
                        [mask]="'000'" min="0">
                </div>
            </div>
            <div>
                <p class="body1 signal">x</p>
            </div>
            <div class="dropdown-card">
                <div class="dropdown-card-header">
                    <p class="body1">{{ economyWeeks }}</p>
                </div>
                <div class="dropdown-card-wave"></div>
                <div class="dropdown-card-gota">
                    52
                </div>
            </div>
            <div>
                <p class="body1 signal">=</p>
            </div>
            <div class="dropdown-card">
                <div class="dropdown-card-header">
                    <p class="body1">{{ economyGallonsForYear }}</p>
                </div>
                <div class="dropdown-card-wave"></div>
                <div class="dropdown-card-gota">
                    {{media}}
                </div>
            </div>
            <div>
                <p class="body1 signal">x</p>
            </div>
            <div class="dropdown-card dropdown-card-square">
                <div class="dropdown-card-header">
                    <p class="body1">{{ economyGallonsCost }}</p>
                </div>
                <div class="dropdown-card-wave"></div>
                <div class="dropdown-card-main">
                    <input class="custo" type="text" placeholder="R$ 00,00" [(ngModel)]="preco"
                        (ngModelChange)="atualizarMedia()" prefix="R$" mask='separator.2' decimalMarker=","
                        thousandSeparator="." separatorLimit="1000">
                </div>
            </div>
        </div>
        <div class="result">
            <p class="body1">{{ economyResultText1 }}</p>
            <p class="body1 media">{{formatarReal(valor)}}</p>
            <p class="body1 body2">{{ economyResultText2 }}</p>
        </div>
        <div class="footer">
            <p class="body1 body-footer">
                {{ economyFooterText }}
            </p>
            <app-mybutton text="{{ economyFooterButton }}" type="large"
                [routerLink]="'/' + lang + '/purificadores'"></app-mybutton>
        </div>
    </div>
</div>