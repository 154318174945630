import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-steps',
  templateUrl: './card-steps.component.html',
  styleUrls: ['./card-steps.component.scss']
})
export class CardStepsComponent implements OnInit {
  @Input() data:any
  @Input() index:any
  constructor() { }

  ngOnInit(): void {
  }

}
