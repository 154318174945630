<div class="page">
  <app-whatsapp></app-whatsapp>
  <app-banner-internal title="{{ contactBannerTitle }}"></app-banner-internal>
  <div class="text">
    <h2>{{ contactText }}</h2>
  </div>
  <div class="form container">
    <form action='#' [autocomplete]="'off'" (ngSubmit)='onSubmit()' [formGroup]='form'>
      <div class="row">
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-fullName' name='name'
            labelName='{{ contactInputName }}' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-input [placeholder]="''" [type]="'contact'" id='contact-email' name='email' typeInput='email'
            labelName='{{  contactInputEmail }}' [form]='form'>
          </app-input>
        </div>
        <div class="col-md-12">
          <app-select [type]="'contact'" id='contact-subject' name='subject' labelName='{{ contactInputSubject }}'
            placeholder='{{ contactInputSubjectPlaceholder }}' [form]='form' [options]="options">
          </app-select>
        </div>
        <div class="col-md-12">
          <app-text-area id='contact-message' name='message' [placeholder]="contactInputMessagePlaceholder"
            labelName="{{ contactInputMessage }}" [form]='form'>
          </app-text-area>
        </div>
      </div>
      <button type='submit' [disabled]='form.invalid'>{{ contactFormButton }}</button>
    </form>
  </div>
</div>