import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-banner-cta',
  templateUrl: './banner-cta.component.html',
  styleUrls: ['./banner-cta.component.scss']
})
export class BannerCtaComponent implements OnInit {
    @Input() data: any
  constructor() { }

  ngOnInit(): void {
  }

}
