import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grid-text-pantanal',
  templateUrl: './grid-text-pantanal.component.html',
  styleUrls: ['./grid-text-pantanal.component.scss']
})
export class GridTextPantanalComponent implements OnInit {
    @Input() data: any = []
  constructor() { }

  ngOnInit(): void {
  }

}
