import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-internal',
  templateUrl: './banner-internal.component.html',
  styleUrls: ['./banner-internal.component.scss']
})
export class BannerInternalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() title: string = ''
  @Input() subtitle: string = ''
  @Input() type: string = ''

}
