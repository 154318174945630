<div class="container">
    <div class="ocean">
<!--        <div class="search">-->
<!--            <h3>Ainda não encontrou?<br>-->
<!--                Procure aqui</h3>-->
<!--            <div class="search-input">-->
<!--                <input type="text">-->
<!--                <button class="search-button">-->
<!--                    <app-image path="/assets/images/search-icon.png" title=""></app-image>-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>

