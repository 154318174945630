<div class="modal-container">
	<div class="modal-close" (click)="close()">
		<button class="close">
			<img src="./assets/images/Cross.svg" alt="" />
		</button>
	</div>
	<div class="modal-header">
	</div>
	<div class="modal-body">
        <div class="row">
            <div class="col">
                <app-gallery [data]="data[0]"></app-gallery>
            </div>
            <div class="col">
                <div class="wrapper">
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/producao.svg" [alt]="dictionary.production">
                        <span>{{dictionary.production}}: {{data[0].prodution}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/deposito.svg" alt="Depósito">
                        <span>Depósito: {{data[0].deposit_capacity}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/dimensao.svg" [alt]="dictionary.dimensions">
                        <span>{{dictionary.dimensions}}: {{data[0].dimension}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/peso.svg" [alt]="dictionary.size">
                        <span>{{dictionary.size}}: {{data[0].weight}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/tensao.svg" [alt]="dictionary.voltage">
                        <span>{{dictionary.voltage}}: {{data[0].voltage}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/potencia.svg" [alt]="dictionary.power">
                        <span>{{dictionary.power}}: {{data[0].power}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/hp.svg" alt="HP/nominal">
                        <span>HP/nominal: {{data[0].hp_nominal}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/frequencia.svg" [alt]="dictionary.frequency">
                        <span>{{dictionary.frequency}}: {{data[0].frequency}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/gabinte.svg" [alt]="dictionary.stainlessSteel">
                        <span>{{dictionary.stainlessSteel}}: {{data[0].stainless_steel}}</span>
                    </div>
                    <div class="wrapper-items">
                        <img src="./assets/images/icons/gas.svg" [alt]="dictionary.refrigerantGas">
                        <span>{{dictionary.refrigerantGas}}: {{data[0].refrigerant_gas}}</span>
                    </div>
                </div>
                <div class="wrapper-production">
                    <p class="text-production">{{lang === 'pt' ? 'Produção kg/24h por temperatura ambiente' : 'Producción kg/24h a temperatura ambiente'}}</p>
                    <div class="wrapper-temperature">
                        <div>
                            <div class="degrees">
                                22ºC
                            </div>
                            <div class="quantily">
                                {{data[0].production_quantity}}
                            </div>
                        </div>
                        <div>
                            <div class="degrees">
                                27ºC
                            </div>
                            <div class="quantily">
                                {{data[0].production_quantity_27}}
                            </div>
                        </div>
                        <div>
                            <div class="degrees">
                                32ºC
                            </div>
                            <div class="quantily">
                                {{data[0].production_quantity_32}}
                            </div>
                        </div>
                        <div>
                            <div class="degrees">
                                37ºC
                            </div>
                            <div class="quantily">
                                {{data[0].production_quantity_37}}
                            </div>
                        </div>
                    </div>
                    <p class="text-temperature">{{lang === 'pt' ? 'Produção garantida para entrada de água 5ºC inferior à temperatura ambiente.' : 'Producción garantizada para un aporte de agua 5ºC inferior a la temperatura ambiente.                        '}}</p>
                </div>
            </div>
        </div>
	</div>
    <div class="modal-footer">
        <div class="wrapper-items">
            <div class="item">
                <img src="./assets/images/icon-1.svg" [alt]="lang === 'pt' ? 'Produção automática de gelo' : 'Producción automática de hielo'">
                <p>{{lang === 'pt' ? 'Produção automática de gelo' : 'Producción automática de hielo'}}</p>
            </div>
            <div class="item">
                <img src="./assets/images/icon-2.svg" [alt]="lang === 'pt' ? 'Baixo consumo' : 'Bajo consumo'">
                <p>{{lang === 'pt' ? 'Baixo consumo' : 'Bajo consumo'}}</p>
            </div>
            <div class="item">
                <img src="./assets/images/icon-3.svg" [alt]="lang === 'pt' ? 'Excelente performance de produção' : 'Excelente rendimiento de producción'">
                <p>{{lang === 'pt' ? 'Excelente performance de produção' : 'Excelente rendimiento de producción'}}</p>
            </div>
            <div class="item">
                <img src="./assets/images/icon-4.svg"  [alt]="lang === 'pt' ? 'Alta qualidade e pureza' : 'Alta calidad y pureza'">
                <p>{{lang === 'pt' ? 'Alta qualidade e pureza' : 'Alta calidad y pureza'}}</p>
            </div>
        </div>
    </div>
</div>
