import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gota-filtrante',
  templateUrl: './gota-filtrante.component.html',
  styleUrls: ['./gota-filtrante.component.scss']
})
export class GotaFiltranteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() data: any;

}
