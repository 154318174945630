import { Component, OnInit } from '@angular/core'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-certifications',
	templateUrl: './certifications-ice-machine.component.html',
	styleUrls: ['./certifications-ice-machine.component.scss'],
})
export class CertificationsIceMachineComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}

	card = {
		title: 'Pode confiar!',
		body: 'Nosso produtos são certificados e possuem garantia de qualidade das instituições mais respeitadas do mercado. Obter certificados emitidos por órgãos independentes é uma forma de atestar que os produtos Everest seguem todas as normas de segurança, de purificação e refrigeração. É uma garantia para a empresa,   colaboradores, fornecedores e consumidores. ',
	}

	tabs = [
		{
			text: 'Purificadores',
			block: [
				{
					name: 'Lorem ipsum dolor sit amet consectetur.',
					pdf: '../../../assets/pdfs/UC01.pdf',
				},
				{
					name: 'Lorem ipsum dolor sit amet consectetur.',
					pdf: '../../../assets/pdfs/Feminicídio e Alcoolismo.pdf',
				},
			],
		},
		{
			text: 'Máquinas de gelo',
			block: [],
		},
	]

	lang = 'pt'

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		this.getCertifications(this.lang)
		this.getTabs(this.lang)
	}

	dataCertifications: any
	dataDocuments: any
	dataTabs: any

	getCertifications(lang: string) {
		const self = this
		this.content.getPage('pages/?type=cms.Certifications&fields=*&', lang, {
			fnSuccess(data) {
				self.dataCertifications = data.items[0]
                self.dataDocuments = self.dataCertifications.documents.filter((item: any) => item.tipo === 'ICE_MACHINE')
				self.title.setTitle(
					data.items[0].meta.seo_title || 'Certificações'
				)
				self.meta.addTag({
					name: 'description',
					content: data.items[0].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[0].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					},
					"property='og:title'"
				)
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}

	getTabs(lang: string) {
		const self = this
		this.content.getCategories('categories/?', {
			fnSuccess(data) {
				self.dataTabs = data.items.filter(
					(item: any) => item.name == "Máquinas de Gelo Everest"
				)
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
}
