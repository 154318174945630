import { Component, OnInit, Renderer2, Input  } from '@angular/core'
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms'
import { ContentService } from 'src/app/services/content/content.service'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from '../modals/modal-default/modal-default.component'
import { ActivatedRoute } from '@angular/router'
import { AddressService } from 'src/app/services/address/address.service'

@Component({
    selector: 'app-form-revendedor',
    templateUrl: './form-revendedor.component.html',
    styleUrls: ['./form-revendedor.component.scss'],
})
export class FormRevendedorComponent implements OnInit {
    @Input() lang:any

    step: number = 1
    disabled = true
    form: FormGroup
    typeAddress: string = ''
    address: string = ''
    state: string = ''
    city: string = ''
    fullAddress: string = ''
    cep = ''
    hasCitiesNear: ''
	ufSelected = '';
    cityOptions: any = []
    ufOptions: any[] = []
    hasCity: any = ''
    selectedBusiness: string[] = [];
    dropdownOpen = false;
    businessModels = [
        { value: 'Revenda de Purificador de Água', labelPt: 'Revenda de Purificador de Água', labelEs: 'Reventa de Purificadores de Agua' },
        { value: 'Revenda de Máquina de Gelo', labelPt: 'Revenda de Máquina de Gelo', labelEs: 'Reventa de Máquinas de Hielo' },
        { value: 'Assistência Técnica', labelPt: 'Assistência Técnica', labelEs: 'Asistencia Técnica' },
      ];

    searchCep(cep: any) {
        this.content.getMap(`cep/?cep=${cep}`, {
            fnSuccess: (data: any) => {
                this.typeAddress = data.tipo_logradouro
                this.address = data.logradouro
                this.state = data.uf.toUpperCase()
                this.city = data.cidade
                if(data.logradouro !== null) {
                    if (this.typeAddress !== null) {
                        this.fullAddress = this.typeAddress + ' ' + this.address
                    } else this.fullAddress = this.address
                }
            },
            fnError: (error: any) => {
                console.warn(error);
            },
        });
    }

    constructor(
        private formBuilder: FormBuilder,
        private content: ContentService,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private renderer2: Renderer2,
        private addressService: AddressService,
    ) {
        this.form = this.formBuilder.group({
            full_name: [
                null,
                [Validators.required, GenericValidatorService.completeName],
            ],
            cpf: [
                null,
                [Validators.required, GenericValidatorService.isValidCpf],
            ],
            _id: [null, []],
            phone: [
                null,
                [Validators.required, GenericValidatorService.numbersOnly],
            ],
            email: [null, [Validators.required, Validators.email]],
            city: [null, [Validators.required, GenericValidatorService.lettersOnly]],
            state: [null, [Validators.required, GenericValidatorService.lettersOnly]],
            privacy_policy: [null, [Validators.requiredTrue,Validators.required]],
            cnpj_state_registration: [null, [GenericValidatorService.isValidCnpj, GenericValidatorService.numbersOnly]],
            business_model: this.formBuilder.array([], Validators.required),
        })

        // this.watchChangesForm()
    }

    // isFieldValid(field: string): boolean {
    // 	const form = this.form
    // 	return form.get(field)!.valid
    // }

    // watchChangesForm() {
    // 	const self = this
    // 	this.form.get('has_company')?.valueChanges.subscribe((value) => {
    // 		if (value == 'false') {
    // 			this.form.get('social_reason')?.setValidators([])
    // 			this.form.get('fantasy_name')?.setValidators([])
    // 			this.form.get('cnpj_state_registration')?.setValidators([])
    // 			this.form.get('ruc')?.setValidators([])

    // 			const social = this.form.get('social_reason')
    // 			const fantasy = this.form.get('fantasy_name')
    // 			const ruc = this.form.get('ruc')
    // 			const cnpj = this.form.get('cnpj_state_registration')
    // 			social!.updateValueAndValidity()
    // 			fantasy!.updateValueAndValidity()
    // 			ruc!.updateValueAndValidity()
    // 			cnpj!.updateValueAndValidity()
    // 		} else if (value == 'true') {
    // 			this.form
    // 				.get('social_reason')
    // 				?.setValidators(Validators.required)
    // 			this.form
    // 				.get('fantasy_name')
    // 				?.setValidators(Validators.required)
    // 			if (this.lang == 'pt') {
    // 				this.form
    // 					.get('cnpj_state_registration')
    // 					?.setValidators([
    // 						GenericValidatorService.isValidCnpj,
    // 						Validators.required,
    // 					])
    // 			}
    // 			if (this.lang !== 'pt') {
    // 				this.form.get('ruc')?.setValidators([Validators.required])
    // 			}
    // 			const social = this.form.get('social_reason')
    // 			const fantasy = this.form.get('fantasy_name')
    // 			const ruc = this.form.get('ruc')
    // 			const cnpj = this.form.get('cnpj_state_registration')
    // 			social!.updateValueAndValidity()
    // 			fantasy!.updateValueAndValidity()
    // 			fantasy!.updateValueAndValidity()
    // 			cnpj!.updateValueAndValidity()
    // 			ruc!.updateValueAndValidity()
    // 		}
    // 	})
    // 	self.form.valueChanges.subscribe({
    // 		next: () => {
    // 			if (
    // 				self.step === 1 &&
    // 				self.isFieldValid('full_name') &&
    // 				self.isFieldValid('cpf') &&
    // 				self.isFieldValid('_id') &&
    // 				self.isFieldValid('phone') &&
    // 				self.isFieldValid('email') &&
    // 				self.isFieldValid('state') &&
    // 				self.isFieldValid('city')
    // 			) {
    // 				self.disabled = false
    // 			} else if (
    // 				self.step === 2 &&
    // 				self.isFieldValid('business_type') &&
    // 				self.isFieldValid('has_company') &&
    // 				self.isFieldValid('social_reason') &&
    // 				self.isFieldValid('fantasy_name') &&
    // 				self.isFieldValid('cnpj_state_registration') &&
    // 				self.isFieldValid('ruc') &&
    // 				self.isFieldValid('business_address')
    // 			) {
    // 				self.disabled = false
    // 			} else if (
    // 				self.step === 3 &&
    // 				self.isFieldValid('business_experience') &&
    // 				self.isFieldValid('is_water_tracking') &&
    // 				self.isFieldValid('brands')
    // 			) {
    // 				self.disabled = false
    // 			} else if (
    // 				self.step === 4 &&
    // 				self.isFieldValid('sellers_amount') &&
    // 				self.isFieldValid('installer_technicians_amount') &&
    // 				self.isFieldValid('refrigeration_technicians_amount') &&
    // 				self.isFieldValid('cars_amount')
    // 			) {
    // 				self.disabled = false
    // 			} else if (
    // 				self.step === 5 &&
    // 				self.isFieldValid('other_products') &&
    // 				self.isFieldValid('investment_availability') &&
    // 				self.isFieldValid('meet_us')
    // 			) {
    // 				self.disabled = false
    // 			} else this.disabled = true
    // 		},
    // 	})
    // }

    // nextStep(e: Event): void {
    // 	e.preventDefault()
    // 	if (this.step !== 5) {
    // 		this.step++
    // 	}
    // 	const self = this
    // 	if (
    // 		self.step === 1 &&
    // 		self.isFieldValid('full_name') &&
    // 		self.isFieldValid('cpf') &&
    // 		self.isFieldValid('_id') &&
    // 		self.isFieldValid('phone') &&
    // 		self.isFieldValid('email') &&
    // 		self.isFieldValid('state') &&
    // 		self.isFieldValid('city')
    // 	) {
    // 		self.disabled = false
    // 	} else if (
    // 		self.step === 2 &&
    // 		self.isFieldValid('business_type') &&
    // 		self.isFieldValid('has_company') &&
    // 		self.isFieldValid('social_reason') &&
    // 		self.isFieldValid('fantasy_name') &&
    // 		self.isFieldValid('cnpj_state_registration') &&
    // 		self.isFieldValid('ruc') &&
    // 		self.isFieldValid('business_address')
    // 	) {
    // 		self.disabled = false
    // 	} else if (
    // 		self.step === 3 &&
    // 		self.isFieldValid('business_experience') &&
    // 		self.isFieldValid('is_water_tracking') &&
    // 		self.isFieldValid('brands')
    // 	) {
    // 		self.disabled = false
    // 	} else if (
    // 		self.step === 4 &&
    // 		self.isFieldValid('sellers_amount') &&
    // 		self.isFieldValid('installer_technicians_amount') &&
    // 		self.isFieldValid('refrigeration_technicians_amount') &&
    // 		self.isFieldValid('cars_amount')
    // 	) {
    // 		self.disabled = false
    // 	} else if (
    // 		self.step === 5 &&
    // 		self.isFieldValid('other_products') &&
    // 		self.isFieldValid('investment_availability') &&
    // 		self.isFieldValid('meet_us')
    // 	) {
    // 		self.disabled = false
    // 	} else this.disabled = true
    // }

    // selectStep(step: number) {
    // 	if (step < this.step) {
    // 		this.step = step
    // 		this.disabled = false
    // 	}

    // 	if (this.form.valid) {
    // 		this.step = step
    // 	}
    // }

    textPattern = /^[^0-9]+$/

    validText(control: AbstractControl): ValidationErrors | null {
        const { value } = control
        if (value) {
            if (/^[^0-9]+$/.test(value)) {
                return null
            }
        }
        return { textInvalid: true }
    }

    validNonNegativeNumber(control: AbstractControl): ValidationErrors | null {
        const { value } = control
        if (value) {
            if (value >= 0) {
                return null
            }
        }
        return { negativeNumber: true }
    }

    validNonFloatNumber(control: AbstractControl): ValidationErrors | null {
        const { value } = control
        if (value) {
            if (Number.isInteger(+value)) {
                return null
            }
        }
        return { floatNumber: true }
    }

    inputCep = 'CEP'
    inputLogradouro = 'Logradouro'
    inputNumber = 'Número'
    resellerFormTitle = 'Seja um revendedor'
    resellerInputFullName = 'Nome Completo'
    resellerInputBirthDate = 'Data de nascimento'
    resellerInputFullCPF = 'CPF'
    resellerInputEmail = 'E-mail'
    resellerInputState = 'Estado'
    inputComplement = 'Complemento'
    resellerInputCity = 'Cidade'
    resellerInputSchooling = 'Escolaridade'
    resellerInputBusinessType = 'Atividade profissional'
    resellerInputHasCompany = 'Possui empresa?'
    resellerInputCNPJ = 'CNPJ'
    resellerInputBusinessAddress = 'Endereço comercial'
    resellerInputStoreFormat = 'Formato de loja?'
    resellerInputProfessionalExperience = 'Qual sua experiência profissional?'
    resellerInputBusinessExperience = 'Possui experiência empresarial? Qual?'
    resellerInputIsWaterTracking =
        'Trabalha no segmento de purificador de água?'
    resellerInputHowLong = 'Há quanto tempo?'
    resellerInputBrands = 'Quais marcas?'
    resellerInputCompanyStructure = 'Como é sua estrutura atual?'
    resellerInputSellersAmount = 'Nº de vendedores'
    resellerInputTelemarketingAmount = 'Nº de operador telemarketing'
    resellerInputTechniciansAmount = 'Nº técnicos instaladores'
    resellerInputRefrigerationTechnicians_amount = 'Nº técnicos de refrigeração'
    resellerInputMotorcyclesAmount = 'Nº motos'
    resellerInputCarsAmount = 'Nº veículos'
    resellerInputServiceCity = 'Qual cidade sua empresa atende?'
    resellerInputOtherProducts =
        'Trabalha com produtos de outros segmentos? Quais?'
    resellerInputPromotionActions =
        'Realiza divulgação por alguma mídia online ou offline? Quais?'
    resellerInputOtherBusiness = 'Opera outro negócio? Qual?'
    resellerInputInvestmentAvailability =
        'Qual a disponibilidade de investimento?'
    resellerInputMeetUs = 'Como conheceu a Everest?'
    resellerInputCommercialInterest =
        'Por que deseja se tornar um revendedor Everest?'
    resellerNextButton = 'Próximo'
    resellerSendButton = 'Enviar'
    resellerInputSocialReason = 'Razão Social'
    resellerInputFantasyName = 'Nome Fantasia'
    resellerInputId = 'Digite o Id'
    resellerInputRuc = 'Digite o ruc'
    resellerInputPhone = 'Telefone para contato'
    yesOpt = ''
    noOpt = ''
    onlyNumbersPlaceHolder = ''
    img:any

    ngOnInit(): void {
        this.getStates()
        this.img = this.renderer2.createElement('img');
        this.lang = this.route.snapshot.params.lang
        window.localStorage.setItem('lang', this.lang)

        if (this.lang !== 'pt') {
            this.form.get('phone')?.setValidators([])
            const phone = this.form.get('phone')
            phone?.updateValueAndValidity()

            this.form.get('cpf')?.setValidators([])
            const cpf = this.form.get('cpf')
            cpf?.updateValueAndValidity()


            this.form.get('_id')?.setValidators([Validators.required])
            const id = this.form.get('_id')
            id?.updateValueAndValidity()
            this.form.get('phoneEs')?.setValidators([Validators.required])
        }

        this.content.getPage('dictionary/?limit=9999&', this.lang, {
            fnSuccess: (data: any) => {
                data.items.map((item: any) => {
                    if (item.key === 'modalSuccessTitle') {
                        this.modalSuccessTitle = item.value
                    } else if (item.key === 'resellerModalText') {
                        this.resellerModalText = item.value
                    } else if (item.key === 'resellerInputId') {
                        this.resellerInputId = item.value
                    } else if (item.key === 'resellerInputRuc') {
                        this.resellerInputRuc = item.value
                    } else if (item.key === 'inputCep') {
                        this.inputCep = item.value
                    } else if (item.key === 'onlyNumbersPlaceHolder') {
                        this.onlyNumbersPlaceHolder = item.value
                    } else if (item.key === 'inputLogradouro') {
                        this.inputLogradouro = item.value
                    } else if (item.key === 'resellerModalButton') {
                        this.resellerModalButton = item.value
                    } else if (item.key === 'modalErrorTitle') {
                        this.modalErrorTitle = item.value
                    } else if (item.key === 'modalErrorText') {
                        this.modalErrorText = item.value
                    } else if (item.key === 'resellerFormTitle') {
                        this.resellerFormTitle = item.value
                    } else if (item.key === 'resellerInputFullName') {
                        this.resellerInputFullName = item.value
                    } else if (item.key === 'resellerInputBirthDate') {
                        this.resellerInputBirthDate = item.value
                    } else if (item.key === 'resellerInputFullCPF') {
                        this.resellerInputFullCPF = item.value
                    } else if (item.key === 'resellerInputEmail') {
                        this.resellerInputEmail = item.value
                    } else if (item.key === 'resellerInputPhone') {
                        this.resellerInputPhone = item.value
                    } else if (item.key === 'resellerInputState') {
                        this.resellerInputState = item.value
                    }else if (item.key === 'inputComplement') {
                        this.inputComplement = item.value
                    } else if (item.key === 'resellerInputCity') {
                        this.resellerInputCity = item.value
                    } else if (item.key === 'resellerInputSchooling') {
                        this.resellerInputSchooling = item.value
                    } else if (item.key === 'resellerInputBusinessType') {
                        this.resellerInputBusinessType = item.value
                    } else if (item.key === 'resellerInputHasCompany') {
                        this.resellerInputHasCompany = item.value
                    } else if (item.key === 'resellerInputCNPJ') {
                        this.resellerInputCNPJ = item.value
                    } else if (item.key === 'resellerInputBusinessAddress') {
                        this.resellerInputBusinessAddress = item.value
                    } else if (item.key === 'resellerInputStoreFormat') {
                        this.resellerInputStoreFormat = item.value
                    } else if (
                        item.key === 'resellerInputProfessionalExperience'
                    ) {
                        this.resellerInputProfessionalExperience = item.value
                    } else if (item.key === 'resellerInputBusinessExperience') {
                        this.resellerInputBusinessExperience = item.value
                    } else if (item.key === 'resellerInputIsWaterTracking') {
                        this.resellerInputIsWaterTracking = item.value
                    } else if (item.key === 'resellerInputHowLong') {
                        this.resellerInputHowLong = item.value
                    } else if (item.key === 'resellerInputBrands') {
                        this.resellerInputBrands = item.value
                    } else if (item.key === 'resellerInputCompanyStructure') {
                        this.resellerInputCompanyStructure = item.value
                    } else if (item.key === 'resellerInputSellersAmount') {
                        this.resellerInputSellersAmount = item.value
                    } else if (
                        item.key === 'resellerInputTelemarketingAmount'
                    ) {
                        this.resellerInputTelemarketingAmount = item.value
                    } else if (item.key === 'resellerInputTechniciansAmount') {
                        this.resellerInputTechniciansAmount = item.value
                    } else if (
                        item.key ===
                        'resellerInputRefrigerationTechnicians_amount'
                    ) {
                        this.resellerInputRefrigerationTechnicians_amount =
                            item.value
                    } else if (item.key === 'resellerInputMotorcyclesAmount') {
                        this.resellerInputMotorcyclesAmount = item.value
                    } else if (item.key === 'resellerInputCarsAmount') {
                        this.resellerInputCarsAmount = item.value
                    } else if (item.key === 'resellerInputServiceCity') {
                        this.resellerInputServiceCity = item.value
                    } else if (item.key === 'resellerInputOtherProducts') {
                        this.resellerInputOtherProducts = item.value
                    } else if (item.key === 'resellerInputPromotionActions') {
                        this.resellerInputPromotionActions = item.value
                    } else if (item.key === 'resellerInputOtherBusiness') {
                        this.resellerInputOtherBusiness = item.value
                    } else if (
                        item.key === 'resellerInputInvestmentAvailability'
                    ) {
                        this.resellerInputInvestmentAvailability = item.value
                    } else if (item.key === 'resellerInputMeetUs') {
                        this.resellerInputMeetUs = item.value
                    } else if (item.key === 'resellerInputCommercialInterest') {
                        this.resellerInputCommercialInterest = item.value
                    } else if (item.key === 'resellerNextButton') {
                        this.resellerNextButton = item.value
                    } else if (item.key === 'resellerSendButton') {
                        this.resellerSendButton = item.value
                    } else if (item.key === 'resellerInputSocialReason') {
                        this.resellerInputSocialReason = item.value
                    } else if (item.key === 'yesOpt') {
                        this.yesOpt = item.value
                    } else if (item.key === 'noOpt') {
                        this.noOpt = item.value
                    } else if (item.key === 'resellerInputFantasyName') {
                        this.resellerInputFantasyName = item.value
                    } else if (item.key === 'modalErrorAlreadyExists') {
                        this.ModalErrorAlreadyExists = item.value
                    } else if (item.key === 'inputNotRequired') {
                        this.inputNotRequired = item.value
                    }
                    this.options = [
                        {
                            value: true,
                            label: this.yesOpt,
                        },
                        {
                            value: false,
                            label: this.noOpt,
                        },
                    ]
                })
            },
            fnError: (error: any) => console.warn(error),
        })
    }

    modalSuccessTitle: string = ''
    resellerModalText: string = ''
    resellerModalButton: string = ''
    modalSuccessText: string = ''
    modalErrorTitle: string = ''
    modalErrorText: string = ''
    ModalErrorAlreadyExists: string = ''
    inputNotRequired: string = ''
    validationCheckbox:Boolean = false
    validationCheckboxModelBusiness:Boolean = false

    getStates(): void {
        const self = this
        self.addressService.getStates({
            fnSuccess(response?: any) {
                self.ufOptions = response.map((state: any) => ({
                    label: state.name,
                    value: state.uf,
                }))

            },
            fnError(responseError) {
                console.log('err')
            }
        })
    }
    getCities(cityCep: string = ''): void {
        const self = this
        if (self.form.value.state) {
            self.addressService.getCities(self.form.value.state, {
                fnSuccess(response?: any) {
                    self.cityOptions = response
                },
                fnError(responseError) {
                    console.log('err')
                }
            })
        }
    }
    onChange(hasCity: any) {
		this.hasCity = hasCity
	}
    onCheckboxChange(event: any): void {
        const businessModelArray = this.form.get('business_model') as FormArray;
        if (event.target.checked) {
          if (!businessModelArray.value.includes(event.target.value)) {
            businessModelArray.push(this.formBuilder.control(event.target.value));
          }
        } else {
          const index = businessModelArray.controls.findIndex(
            (control) => control.value === event.target.value
          );
          if (index !== -1) {
            businessModelArray.removeAt(index);
          }
        }
      }
    
      isChecked(value: string): boolean {
        const businessModelArray = this.form.get('business_model') as FormArray;
        return businessModelArray.value.includes(value);
      }
    
      get selectedBusinessModels(): string[] {
        return this.form.value.business_model || [];
      }
    validateCheckbox() {
        console.log(this.form.value.business_model)
        if(!this.form.value.privacy_policy) {
            this.validationCheckbox = true
        }
        if(this.form.value.business_model.length <= 0) {
            this.validationCheckboxModelBusiness = true
        } else {
            this.validationCheckboxModelBusiness = false
        }
    }
    validateChecked() {
        if(this.form.value.privacy_policy) {
            this.validationCheckbox = false
        }
        if(this.form.value.business_model.length > 0) {
            this.validationCheckboxModelBusiness = false
        }
    }

    onSubmit() {
        const self = this
        const selectedModels = this.form.value.business_model || [];
        const formattedModels = selectedModels?.join(', ') || '';
        if (this.form.valid) {
            const {
                full_name,
                cpf,
                phone,
                email,
                state,
                city,
                number,
                cnpj_state_registration,
                ruc,
                _id,    
                business_model,
            } = this.form.value
            this.content.sendForm('registration_form', {
                payload: {
                    full_name,
                    cpf,
                    phone,
                    email,
                    state,
                    city,
                    number,
                    cnpj_state_registration,
                    ruc,
                    _id,
                    business_model: formattedModels,
                },
                fnSuccess(data: any) {
                    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                    checkboxes.forEach(checkbox => {
                      (checkbox as HTMLInputElement).checked = false;
                    });
                    self.openModal(
                        self.modalSuccessTitle,
                        self.resellerModalText,
                        self.resellerModalButton
                    )
                    window.dataLayer.push({
                        event: 'sendBeReseller',
                        eventCategory: 'lead',
                        eventAction: 'send',
                        eventLabel: '',
                    })
                    self.renderer2.setStyle(self.img, 'display', 'none');
                    self.renderer2.setProperty(self.img, 'src', 'https://immakers.go2cloud.org/aff_l?offer_id=161&adv_sub=form_revenda');
                    self.renderer2.appendChild(document.body, self.img);
                    self.form.reset()

                    self.selectedBusiness = [];
                    self.form.value.business_model = []
                    console.log(self.form.value.business_model)
                },
                fnError(err: any) {
                    if(err.status === 400) {
                        self.openModal(self.modalErrorTitle, self.ModalErrorAlreadyExists)
                    }else {
                        self.openModal(
                            self.modalErrorTitle,
                            self.modalErrorText,
                            self.resellerModalButton
                        )
                        console.warn(err)
                    }

                },
            })
        }else {
        }
        this.form.reset()
    }
    options = [
        {
            value: true,
            label: 'Sim',
        },
        {
            value: false,
            label: 'Não',
        },
    ]

    openModal(
        title: string = '',
        description: string = '',
        buttonLabel: string = 'confirmar'
    ): void {
        const modalRef = this.modal.open(ModalDefaultComponent)
        modalRef.componentInstance.title = title || 'Opa!'
        modalRef.componentInstance.desc =
            description ||
            'Algo não aconteceu como deveria, por favor tente novamente.'
        modalRef.componentInstance.labelButtonConfirmation =
            buttonLabel || 'Confirmar'
        modalRef.componentInstance.showFooter = false
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }
}
