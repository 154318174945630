import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
	selector: 'app-text-area',
	templateUrl: './text-area.component.html',
	styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {
	public hasValue: boolean = false

	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() placeholder?: string
	@Input() labelName?: string
	@Input() callout?: string
	@Input() inputModel: any
	@Output() emmitEvent: EventEmitter<any> = new EventEmitter()

	ngOnInit(): void {
		if (this.inputModel) {
			this.hasValue = this.inputModel.length > 0
		}
	}

	onChange(event: any, value: string): void {
		this.hasValue = value.length > 0
		if (event.keyCode !== 8 && event.keyCode !== 46)
			this.emmitEvent.emit(value)
	}

	constructor() {}
}
