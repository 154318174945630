import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { Location } from '@angular/common'
import { animate, state, style, transition, trigger } from '@angular/animations'
import {
	debounceTime,
	distinctUntilChanged,
	map,
	Observable,
	OperatorFunction,
} from 'rxjs'

const states = [
	'Alabama',
	'Alaska',
	'American Samoa',
	'São Paulo',
	'North Dakota',
	'Northern Mariana Islands',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Palau',
	'Pennsylvania',
	'Puerto Rico',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virgin Islands',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
]

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		trigger('fade', [
			state('void', style({ opacity: 0 })),
			transition(':enter', [animate(200)]),
			transition(':leave', [animate(150)]),
		]),
		trigger('slideInOut', [
			state(
				'void',
				style({ opacity: 0, transform: 'translateY(-100%)' })
			),
			transition(':enter', [
				style({ transform: 'translateY(-100%)' }),
				animate(
					'100ms ease-in',
					style({ opacity: 1, transform: 'translateY(0)' })
				),
			]),
			transition(':leave', [
				animate(
					'100ms ease-out',
					style({ opacity: 0, transform: 'translateY(-100%)' })
				),
			]),
		]),
	],
})
export class HeaderComponent implements OnInit {
	@Input() langSelected: string = 'pt'
	@Input() dataMenu: any
	@Input() dataHelp: any
	@Input() currentPath: string = ''
	showMenu: boolean = false
	submenuOpen: any = null

	constructor(private route: Router) {}

	languages: any[] = [
		{
			icon: 'brazil.svg',
			iconActive: 'brazil.svg',
			lang: 'pt',
            title: 'Português'
		},
		{
			icon: 'spanish.svg',
			iconActive: 'spanish.svg',
			lang: 'es',
            title: 'Espanhol'
		},
		// {
		// 	icon: 'enUs.png',
		// 	iconActive: 'enUs-active.png',
		// 	lang: 'en',
		// },
	]

	model: any
	formatter = (result: string) => result.toUpperCase()
	search: OperatorFunction<string, readonly string[]> = (
		text$: Observable<string>
	) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map((term) =>
				term === ''
					? []
					: states
							.filter(
								(v) =>
									v
										.toLowerCase()
										.indexOf(term.toLowerCase()) > -1
							)
							.slice(0, 10)
			)
		)

	ngOnInit(): void {}

	openMenu(): void {
		document.body.style.overflow = 'hidden'
		this.showMenu = true
	}
	toggleSubMenu(index: any): void {
		if (index === this.submenuOpen) {
			this.submenuOpen = null
		} else {
			this.submenuOpen = index
		}
	}
	closeMenu(): void {
		document.body.style.overflow = ''
		this.showMenu = false
	}

	changeLang(lang: string) {
		this.langSelected = lang
		this.route.navigate([`${this.langSelected}/${this.currentPath}`])
		setTimeout(() => {
			window.location.reload()
		}, 1)
	}
}
