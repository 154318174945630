import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.scss']
})
export class DetailsProductComponent implements OnInit {

  constructor() { }

    @Input() data: any = [];

    slideConfig = {
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: 'calc((100% - 481px) / 2)',
        dots: false,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        mobileFirst: true,
        swipeToSlide: true,
        touchThreshold: 4,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.1,
                },
            },
        ]
    }

    progressBarValue = 33;

    onAfterChange(event: any) {
        const slideCount = this.data.images_list.length;
        const currentIndex = event.currentSlide;
        const progressPercentage = ((currentIndex + 1) / slideCount) * 100;
        this.progressBarValue = progressPercentage;
    }


  ngOnInit(): void {
  }

}
