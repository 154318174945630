<div class="page">
	<app-banner-internal
		[title]="whereToFindPageTitle"
		[subtitle]="whereToFindResellerPageSubtitle"
	></app-banner-internal>
	<div class="text">
		<h2 [innerText]="whereToFindResellerText"></h2>
	</div>
	<app-tabs [type]="'whereToFind'">
		<app-tab
			*ngFor="let tab of dataTabs; let i = index"
			[tabTitle]="
				lang == 'pt'
					? tab.name
					: lang == 'es'
					? tab.name_es
					: tab.name_en
			"
			[active]="i === 0"
		>
			<app-map
				(btnClick)="onOpen($event)"
				[type]="i == 0 ? 'revenda-purificador' : 'revenda-gelo'"
				[mapsTextButton]="mapsTextButton"
				[placeHolderCep]="whereToFindPagePostal"
				[findAddress]="findAddress"
				[mapsAddress]="mapsAddress"
				[mapsCity]="mapsCity"
				[mapsNeighborhood]="mapsNeighborhood"
				[mapsState]="mapsState"
				[notKnowCep]="notKnowCep"
				[mapsDistance]="mapsDistance"
			></app-map>
		</app-tab>
	</app-tabs>
	<div *ngIf="modalActive" class="modal-wrapper">
		<div class="modal-card">
			<button (click)="closeModal()" class="close">
				<img src="./assets/images/Cross.png" />
			</button>
			<div class="frm">
				<img src="./assets/images/snip.png" />
				<input type="text" [(ngModel)]="routeLocalAddress" />
				<input type="text" [(ngModel)]="routeAddress" />
				<button (click)="getRoute()">{{ mapsTextButton }}</button>
			</div>
		</div>
	</div>
</div>
