<div class="container" *ngIf="data">
	<!-- <ngx-slick-carousel class="carousel" [config]="slickConfig"> -->
	<div class="cards">
		<div *ngFor="let card of data.cards; index as i">
			<app-card-download
				[data]="card"
				[ngClass]="{
					bigMarginBottom: i == 1
				}"
			>
			</app-card-download>
		</div>
	</div>
	<!-- </ngx-slick-carousel> -->
</div>
