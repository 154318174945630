import { Injectable } from '@angular/core'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'

@Injectable({
	providedIn: 'root',
})
export class HelpersService {
	constructor() {}

	private controlMessageError(
		typeError: string,
		control?: string,
		ctx?: any
	): string {
		const lang = window.localStorage.getItem('lang')
		if (lang == 'es') {
			switch (typeError) {
				case 'required':
					return '¡Este campo es obligatorio!'
				case 'minlength':
					return 'Mínimo de ${ctx?.minlength?.requiredLength} caracteres.'
				case 'maxlength':
					return 'Máximo de ${ctx?.maxlength?.requiredLength} caracteres.'
				case 'email':
					return ' ¡Email no válido!'
				case 'completeNameInvalid':
					return '¡Por favor, introduzca el nombre completo!'
				case 'textInvalid':
					return 'Por favor, introduzca sólo letras!'
				case 'numberInvalid':
					return '¡Introduzca sólo números!'
				case 'negativeNumber':
					return '¡Introduzca sólo números positivos!'
				case 'floatNumber':
					return '¡Introduzca sólo números enteros!'
				case 'validateCepInvalid':
					return '¡Código postal no válido!'
				case 'dateInvalid':
					return '¡Fecha no válida!'
				case 'phoneInvalid':
					return '¡Teléfono no válido!'
				case 'cpfInvalid':
					return '¡CPF no válido!'
				case 'cnpjInvalid':
					return '¡Identificación del contribuyente no válida!'
				case 'passwordCustomInvalid':
					return '¡Contraseña inválida!'
				case 'atLeastOneLowerLetterInvalid':
					return 'Al menos una letra minúscula.'
				case 'atLeastOneUpperLetterInvalid':
					return 'Al menos una letra mayúscula.'
				case 'atLeastOneNumberInvalid':
					return 'Al menos un número.'
				case 'atLeastOneSymbolInvalid':
					return 'Al menos un carácter especial.'
				case 'ContraseñaNoCompatible':
					return '¡La confirmación de contraseña no coincide!.'
				default:
					return ''
			}
		} else if (lang == 'en') {
			switch (typeError) {
				case 'required':
					return `Este campo é obrigatório!`
				case 'minlength':
					return `Mínimo de ${ctx?.minlength?.requiredLength} caracteres`
				case 'maxlength':
					return `Máximo de ${ctx?.maxlength?.requiredLength} caracteres`
				case 'email':
					return `E-mail inválido!`
				case 'completeNameInvalid':
					return `Por favor, inserir nome completo!`
				case 'textInvalid':
					return `Por favor, digite apenas letras!`
				case 'numberInvalid':
					return `Por favor, digite apenas números!`
				case 'negativeNumber':
					return `Por favor, digite apenas números positivos!`
				case 'floatNumber':
					return `Por favor, digite apenas números inteiros!`
				case 'validateCepInvalid':
					return `CEP inválido!`
				case 'dateInvalid':
					return `Data inválida!`
				case 'phoneInvalid':
					return `Telefone inválido!`
				case 'cpfInvalid':
					return `CPF inválido!`
				case 'cnpjInvalid':
					return `CNPJ inválido!`
				case 'passwordCustomInvalid':
					return `Senha inválida!`
				case 'atLeastOneLowerLetterInvalid':
					return `Pelo menos uma letra minúscula`
				case 'atLeastOneUpperLetterInvalid':
					return `Pelo menos uma letra maiúscula`
				case 'atLeastOneNumberInvalid':
					return `Pelo menos um número`
				case 'atLeastOneSymbolInvalid':
					return `Pelo menos um caracter especial`
				case 'notSamePassword':
					return `A confirmação da senha não corresponde!`
				default:
					return ''
			}
		} else {
			switch (typeError) {
				case 'required':
					return `Este campo é obrigatório!`
				case 'minlength':
					return `Mínimo de ${ctx?.minlength?.requiredLength} caracteres`
				case 'maxlength':
					return `Máximo de ${ctx?.maxlength?.requiredLength} caracteres`
				case 'email':
					return `E-mail inválido!`
				case 'completeNameInvalid':
					return `Por favor, inserir nome completo!`
				case 'textInvalid':
					return `Por favor, digite apenas letras!`
				case 'numberInvalid':
					return `Por favor, digite apenas números!`
				case 'negativeNumber':
					return `Por favor, digite apenas números positivos!`
				case 'floatNumber':
					return `Por favor, digite apenas números inteiros!`
				case 'validateCepInvalid':
					return `CEP inválido!`
				case 'dateInvalid':
					return `Data inválida!`
				case 'phoneInvalid':
					return `Telefone inválido!`
				case 'cpfInvalid':
					return `CPF inválido!`
				case 'cnpjInvalid':
					return `CNPJ inválido!`
				case 'passwordCustomInvalid':
					return `Senha inválida!`
				case 'atLeastOneLowerLetterInvalid':
					return `Pelo menos uma letra minúscula`
				case 'atLeastOneUpperLetterInvalid':
					return `Pelo menos uma letra maiúscula`
				case 'atLeastOneNumberInvalid':
					return `Pelo menos um número`
				case 'atLeastOneSymbolInvalid':
					return `Pelo menos um caracter especial`
				case 'notSamePassword':
					return `A confirmação da senha não corresponde!`
				default:
					return ''
			}
		}
	}

	public getControlMessageError(objError: any, control?: string): string {
		const typeError = Object.keys(objError).length
			? Object.keys(objError)[0]
			: ''
		return this.controlMessageError(typeError, control, objError)
	}

	public converterSnakeToCamelCase(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[
					d.replace(/(\_\w)/g, (k) => {
						return k[1].toUpperCase()
					})
				] = obj[d]
			}
		}
		return newObj
	}

	public converterCamelCaseToSnake(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[this.camelToUnderscore(d)] = obj[d]
			}
		}
		return newObj
	}

	public camelToUnderscore(key: string): string {
		const result = key.replace(/([A-Z])/g, ' $1')
		return result.split(' ').join('_').toLowerCase()
	}

	decodePayloadJWT(token: string): any {
		try {
			return jwtDecode(token)
		} catch (Error) {
			return null
		}
	}

	public getDuration(date: Date | string): string {
		const end = moment()
		const init = moment.utc(date).local()
		const duration = moment.duration(end.diff(init))
		const durationYears = duration.years()
		const durationMonths = duration.months()
		const durationDays = duration.days()
		const durationHours = duration.hours()
		const durationMinutes = duration.minutes()
		let msg = `__YEARS____MONTHS____DAYS____HOURS____MINUTES__`

		const msgYear = durationYears ? durationYears + 'ano(s) ' : ''
		const msgMonth = durationMonths ? durationMonths + 'mês(es) ' : ''
		const msgDays = durationDays ? durationDays + 'd ' : ''
		const msgHours = durationHours ? durationHours + 'h ' : ''
		const msgMinutes = durationMinutes ? durationMinutes + 'm ' : '0m'

		if (date) {
			return msg
				.replace('__YEARS__', msgYear)
				.replace('__MONTHS__', msgMonth)
				.replace('__DAYS__', msgDays)
				.replace('__HOURS__', msgHours)
				.replace('__MINUTES__', msgMinutes)
		}
		return '-- --'
	}

	public getDateToAge(date: Date | string): string {
		if (!date) return '--'
		return `${moment().diff(moment.utc(date).local(), 'years')}`
	}

	public getDate(date: Date | string, withHours: boolean = false): string {
		if (!date) return '--'
		let format = withHours ? 'DD/MM/YYYY -- HH:mm' : 'DD/MM/YYYY'
		let dateString = moment.utc(date).local().format(format)
		return dateString.replace('--', 'às')
	}

	public getPrice(price: any ){
		price = parseFloat(price).toFixed(2);
		price = price.replace(".", ",");
    	price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    	return price;
	}

	public converterTsInDate(
		date: Date | string,
		isTs: boolean = false
	): string {
		if (!date) return '--'
		const today = moment()
		const yesterday = moment().add(-1, 'days')
		let newDate = moment.utc(date).local()
		if (isTs) {
			newDate = moment.utc(date, 'X').local()
		}
		let dateString = `${newDate.format('DD/MM/YYYY -- HH:mm')}`

		if (newDate.format('DD/MM/YYYY') === today.format('DD/MM/YYYY')) {
			dateString = `hoje ${newDate.format('-- HH:mm')}`
		} else if (
			newDate.format('DD/MM/YYYY') === yesterday.format('DD/MM/YYYY')
		) {
			dateString = `ontem ${newDate.format('-- HH:mm')}`
		}

		return dateString.replace('--', 'às')
	}

	public transformDateUS(date: Date | string): string {
		if (!date) return moment().format('YYYY-MM-DD')
		return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
	}

	public transformDateBr(date: Date | string): string {
		if (!date) return moment().format('DD/MM/YYYY')
		return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
	}

	public scrollToElement($element: any): void {
		$element.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest',
		})
	}

	public getQueryVariable(variable: string): string {
		const query = window.location.search.substring(1)
		const vars = query.split('&')
		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=')
			if (pair[0] == variable) return pair[1]
		}
		return ''
	}

	public transformNumber(n: string, toFixed = 2) {
		return (Math.round(parseFloat(n) * 100) / 100).toFixed(toFixed)
	}

	public transformString(value: any, valueDefault: string = ''): string {
		return value !== null && value !== undefined ? `${value}` : valueDefault
	}

	public removeNullValuesFromObject(obj: any) {
		Object.keys(obj).forEach((key) => {
			if (obj[key] === null || obj[key] === undefined) {
				delete obj[key]
			}
		})

		return obj
	}

	public addMaskCpf(v: string) {
		if (!v) return '--'
		v = v.replace(/\D/g, '')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d)/, '$1.$2')
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		return v
	}
	public removeMaskCpfCnpj(v: string): string {
		return v
			.replace('.', '')
			.replace('.', '')
			.replace('-', '')
			.replace('/', '')
	}

	public removeMaskCpf(v: string): string {
		return v.replace('.', '').replace('.', '').replace('-', '')
	}

	parseUA = (() => {
		const part = /\s*([^\s/]+)(\/(\S+)|)(\s+\(([^)]+)\)|)/g
		const delim = /;\s*/
		const single = [
			/^([^,]+),\s*([^,]+)$/,
			/^(\S+)\s+(\S+)$/,
			/^([^:]+):([^:]+)$/,
			/^([^/]+)\/([^/]+)$/,
			/^(.NET CLR|Windows)\s+(.+)$/,
		]
		const many = / +/
		const link = /^\+(.+)$/

		const inner = (properties: any, property: any) => {
			let tmp

			if ((tmp = property.match(link))) {
				properties.link = tmp[1]
			} else if (
				(tmp = single.reduce(
					(match, regex) => match || property.match(regex),
					null
				))
			) {
				properties[tmp[1]] = tmp[2]
			} else if (many.test(property)) {
				if (!properties.properties) properties.properties = []
				properties.properties.push(property)
			} else {
				properties[property] = true
			}

			return properties
		}

		return (input: any) => {
			const output: any = {}
			for (let match; (match = part.exec(input)); '') {
				output[match[1]] = {
					...(match[5] && match[5].split(delim).reduce(inner, {})),
					...(match[3] && { version: match[3] }),
				}
			}
			return output
		}
	})()
}
