import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-certification-list',
	templateUrl: './certification-list.component.html',
	styleUrls: ['./certification-list.component.scss'],
})
export class CertificationListComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	@Input() items: any = []
	@Input() buttonLabel: any = []
	pdfAtual: string
	selectedPdf: any

	isDisable() {
		if (this.pdfAtual == '') {
			return true
		} else {
			return false
		}
	}

	changeImage(item: any) {
		this.pdfAtual = item.document.url
		this.selectedPdf = item
	}
}
