<a *ngIf="typeBtn !== 'purchase'">
    <button
        [type]="type"
        (click)="onClick()"
        class="button"
        [attr.data-btnLabel]="ga"
        [attr.data-btnProduct]="gaProduct"
        [disabled]="disable"
        [ngClass]="{
    'button-white': type === 'white',
    'button-white button-white--border': type === 'white-border',
    'button-product': type === 'product',
    'responsive': type === 'responsive',
    'centered': center === 'center',
    'button-large': type == 'large',
    'button-link': type == 'link',
    'button-large-white': type == 'white-large',
    'filter': type == 'filter',
    'ga-product': gaProduct,
    'ga-btn': ga}">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" *ngIf="download">
            <path d="M2.625 20C1.90313 20 1.28494 19.755 0.77044 19.265C0.25594 18.775 -0.000872772 18.1867 2.22835e-06 17.5V13.75H2.625V17.5H18.375V13.75H21V17.5C21 18.1875 20.7428 18.7762 20.2283 19.2662C19.7138 19.7562 19.096 20.0008 18.375 20H2.625ZM10.5 15L3.9375 8.75L5.775 6.9375L9.1875 10.1875V0H11.8125V10.1875L15.225 6.9375L17.0625 8.75L10.5 15Z" fill="currentColor"/>
        </svg>
        <span [ngClass]="{
             'ga-product': gaProduct,
             'ga-btn': ga}"
              [attr.data-btnLabel]="ga"
              [attr.data-btnProduct]="gaProduct">{{ label }} </span>
    </button>
</a>

<a *ngIf="typeBtn == 'purchase'" [href]="href" target="_blank">
    <button
        [type]="type"
        (click)="onClick()"
        class="button"
        [attr.data-btnLabel]="ga"
        [attr.data-btnProduct]="gaProduct"
        [ngClass]="{
    'button-white': type === 'white',
    'button-product': type === 'product',
    'responsive': type === 'responsive',
    'centered': center === 'center',
    'button-large': type == 'large',
    'button-large-white': type == 'white-large',
    'filter': type == 'filter',
    'ga-product': gaProduct,
    'ga-btn': ga}">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" *ngIf="download">
            <path d="M2.625 20C1.90313 20 1.28494 19.755 0.77044 19.265C0.25594 18.775 -0.000872772 18.1867 2.22835e-06 17.5V13.75H2.625V17.5H18.375V13.75H21V17.5C21 18.1875 20.7428 18.7762 20.2283 19.2662C19.7138 19.7562 19.096 20.0008 18.375 20H2.625ZM10.5 15L3.9375 8.75L5.775 6.9375L9.1875 10.1875V0H11.8125V10.1875L15.225 6.9375L17.0625 8.75L10.5 15Z" fill="currentColor"/>
        </svg>
        <span [ngClass]="{
             'ga-product': gaProduct,
             'ga-btn': ga}"
              [attr.data-btnLabel]="ga"
              [attr.data-btnProduct]="gaProduct">{{ label }}</span>
    </button>
</a>
