import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-trust-banner',
  templateUrl: './trust-banner.component.html',
  styleUrls: ['./trust-banner.component.scss']
})
export class TrustBannerComponent implements OnInit {

  constructor() { }

    @Input() card: any;

  ngOnInit(): void {
  }

}
