<div class="page">
	<app-purifiers-banner
		title="Sala de Imprensa"
		subtitle="Veja abaixo os releases com as novidades sobre a Everest"
	></app-purifiers-banner>
    <div>
        <div class="container">
            <h3 class="sky title">
                Ultimas notícias
            </h3>
            <div class=""></div>
            <div class="news">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="row">
                            <div *ngIf="dataLastNews[0]" class="border-bottom">
                                <app-news [lang]="lang" [data]="dataLastNews[0]"></app-news>
                            </div>
                            <div *ngIf="dataLastNews[1]" class="border-bottom">
                                <app-news  [lang]="lang" [data]="dataLastNews[1]"></app-news>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <app-news *ngIf="dataLastNews[2]" type="small" [lang]="lang" [data]="dataLastNews[2]"></app-news>
                            </div>
                            <div class="col-lg-6">
                                <app-news *ngIf="dataLastNews[3]" type="small" [lang]="lang" [data]="dataLastNews[3]"></app-news>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="highlights">
                            <h5 class="highlights__title">
                                {{ lang === 'pt' ? 'Destaques' : 'Reflejos'}}
                            </h5>
                            <div class="highlights__items" *ngFor="let item of dataPage.highlights">
                                <app-news type="highlight" [lang]="lang" [data]="item.news"></app-news>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="dataLastNews.length >= 4">
                <h3 class="sky title-2 text-center">
                    {{ lang === 'pt' ? 'Veja mais aqui' : 'Ver más aquí'}}

                </h3>
                <div class="wrapper-news">
                    <div *ngFor="let item of dataNews | slice: (page-1) * pageSize : page * pageSize">
                        <app-news [lang]="lang" [data]="item" type="card"></app-news>
                    </div>
                </div>
                <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [maxSize]="3"
                    [collectionSize]="dataNews.length">
                    <ng-template ngbPaginationFirst>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m13.707 4.707-1.414-1.414L3.586 12l8.707 8.707 1.414-1.414L6.414 12l7.293-7.293z"/><path d="m19.707 4.707-1.414-1.414L9.586 12l8.707 8.707 1.414-1.414L12.414 12l7.293-7.293z"/></svg>   
                    </ng-template>
                    <ng-template ngbPaginationLast>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m11.707 3.293-1.414 1.414L17.586 12l-7.293 7.293 1.414 1.414L20.414 12l-8.707-8.707z"/><path d="M5.707 3.293 4.293 4.707 11.586 12l-7.293 7.293 1.414 1.414L14.414 12 5.707 3.293z"/></svg>
                    </ng-template>
                    <ng-template ngbPaginationPrevious>
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.85875 13.5062C7.51417 13.8507 6.9555 13.8507 6.61092 13.5062L0.104646 6.99988L6.61092 0.493609C6.9555 0.149029 7.51417 0.149029 7.85875 0.493609C8.20333 0.838189 8.20333 1.39686 7.85875 1.74144L2.60032 6.99988L7.85875 12.2583C8.20333 12.6029 8.20333 13.1616 7.85875 13.5062Z" />
                        </svg>                        
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.553357 0.493848C0.897937 0.149268 1.45661 0.149268 1.80119 0.493848L8.30746 7.00012L1.80119 13.5064C1.45661 13.851 0.897937 13.851 0.553357 13.5064C0.208777 13.1618 0.208777 12.6031 0.553357 12.2586L5.81179 7.00012L0.553357 1.74168C0.208777 1.3971 0.208777 0.838428 0.553357 0.493848Z" />
                        </svg>                        
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="banner-printed">
            <app-image [path]="dataPage.banner_info_imagem.url"></app-image>
                <div class="content">
                    <p class="text">{{dataPage.banner_info_titulo}}</p>
                    <app-image [path]="dataPage.banner_info_logo.url"></app-image>
                    <div class="wrapper-contact">
                        <div *ngFor="let item of dataPage.info_field_company_room">
                            <div class="contact">
                                <span>{{item.name}}</span>
                                <span>{{item.phone}}</span>
                                <a [href]="'mailto:'+item.email">{{item.email}}</a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        <div class="container py-5" style="font-size: 24px" [innerHTML]="dataPage.banner_pre_title"></div>
        <app-banner-cta [data]="dataPage"></app-banner-cta>
    </div>
</div>
