<div class="filters__card" [ngClass]="{'open': isOpen, 'closed': !isOpen}">
  <div class="filters__card__header" (click)="openToggle()">
    <div class="filters__card__header__img">
      <app-image [path]="icon"></app-image>
    </div>
    <div class="filters__card__header__text">
      <p>{{ placeHolder }}</p>
      <div class="filters__card__header__text__bottom">
        <span>{{ placeHolderSelect }}</span>
        <div class="img">
          <img [src]="'/assets/images/blue-arrowDown.svg'"/>
        </div>
      </div>
    </div>
  </div>
  <div class="filters__card__body">
    <div class="filters__card__body__item">
      <span (click)="select('')">{{ allOpt }}</span>
    </div>
    <div class="filters__card__body__item" *ngFor="let opt of data">
      <span (click)="select(opt)">{{ opt }}</span>
    </div>
  </div>
</div>