<div class="reseller">
    <div class="reseller__content container">
        <img [src]="'/assets/images/revendedor-min.jpg'" alt="" />
        <div class="reseller__body">
            <h1 class="title" [innerHTML]="data.reseller_title"></h1>
            <p class="body1" [innerHTML]="data.reseller_description"></p>
            <a *ngIf="data.reseller_cta_link" [routerLink]="data.reseller_cta_link" class="button" [attr.data-btnLabel]="data.reseller_title + ' - ' + data.reseller_cta_label">{{ data.reseller_cta_label }}</a>
        </div>
    </div>
</div>
