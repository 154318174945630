import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ContentService } from 'src/app/services/content/content.service'
import { ModalDefaultComponent } from '../../modals/modal-default/modal-default.component'
import { ActivatedRoute } from '@angular/router'
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() langSelected: string = 'pt'
    @Input() dataMenuFooter: any
    @Input() dataSocials: any
    @Input() dataContact: any
    form: FormGroup

    constructor(
        private formBuilder: FormBuilder,
        private content: ContentService,
        private http: HttpClient,
        private modal: NgbModal,
        private route: ActivatedRoute
    ) {
        this.form = this.formBuilder.group({
            email: [null, [Validators.required, Validators.email]],
        })
    }

    @Input() footerBottom: string = ''
    @Input() newsletterSubText: string = ''
    @Input() sacAlert: string = ''
    @Input() sacDays: string = ''
    @Input() newsletterButton: string = ''
    @Input() modalSuccessTitle: string = ''
    @Input() modalSuccessText: string = ''
    @Input() modalSuccessNewsletter: string = ''
    @Input() modalErrorTitle: string = ''
    @Input() modalErrorText: string = ''
    @Input() ModalErrorAlreadyExists: string = ''

    ngOnInit() {
        window.localStorage.setItem('lang', this.langSelected)
    }

    onSubmit() {
        const self = this
        if (this.form.valid) {
            const { email } = this.form.value
            this.content.sendForm('newsletter', {
                payload: { email },
                fnSuccess(data: any) {
                    self.openModal(
                        self.modalSuccessTitle,
                        self.modalSuccessNewsletter
                    )
                    self.form.reset()
                    self.enviarDados(email)
                },
                fnError(err: any) {
                    if(err.status === 400) {
                        self.openModal(self.modalErrorTitle, self.ModalErrorAlreadyExists)
                    } else {
                        self.openModal(self.modalErrorTitle, self.modalErrorText)
                        console.warn(err)
                    }
                },
            })
        }
    }

    enviarDados(email: any) {
        const url = 'https://api.rd.services/platform/conversions?api_key=efdb9c6b99ce079daa0a2a61a813a060';
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'accept': 'application/json'
          })
        };

        const payload = {
          event_type: 'CONVERSION',
          event_family: 'CDP',
          payload: { conversion_identifier: 'newsletter-institucional', email }
        };

        this.http.post(url, payload, options)
          .subscribe(
            (data) => {
              console.log('Sucesso:', data);
              // Faça algo com a resposta bem-sucedida, se necessário
            },
            (error) => {
              console.error('Erro:', error);
              // Trate o erro conforme necessário
            }
          );
      }

    openModal(
        title: string = '',
        description: string = '',
        icon: string = 'alert.svg'
    ): void {
        const modalRef = this.modal.open(ModalDefaultComponent)
        modalRef.componentInstance.title = title || 'Opa!'
        modalRef.componentInstance.desc =
            description ||
            'Algo não aconteceu como deveria, por favor tente novamente.'
        modalRef.componentInstance.figure = '/assets/images/' + icon
        modalRef.componentInstance.showFooter = false
    }
}
