import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-mybutton',
	templateUrl: './mybutton.component.html',
	styleUrls: ['./mybutton.component.scss'],
})
export class MybuttonComponent implements OnInit {
	public label = ''
	@Input()
	set text(name: string) {
		this.label = name
	}
	get name(): string {
		return this.label
	}

  	@Input() disable: boolean = false
	@Input() type: string = 'button'
	@Input() center: string = 'default'
	@Input() ga: string | null = null
	@Input() gaProduct: string | null = null
	@Output() btnClick = new EventEmitter()
	@Input() href: string = ''
	@Input()
	@Input()
	download: boolean = false
	@Input() linkDownload: string
	@Input() typeBtn: string = ''

	onClick() {
		this.btnClick.emit()
	}

	constructor() {}

	ngOnInit(): void {}
}
