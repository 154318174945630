import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-list-of-products',
	templateUrl: './list-of-products.component.html',
	styleUrls: ['./list-of-products.component.scss'],
})
export class ListOfProductsComponent implements OnInit {
	constructor() {}

	// slickConfig = {
	// 	slidesToShow: 2, // Exibe 2 slides ao mesmo tempo
	// 	slidesToScroll: 2,

	// 	responsive: [
	// 		{
	// 			breakpoint: 950, // Limite de largura para o dispositivo móvel
	// 			settings: {
	// 				slidesToShow: 1, // Exibe 2 slides no desktop
	// 				slidesToScroll: 1,
	// 				dots: true, // Remove os pontos de navegação
	// 			},
	// 		},
	// 	],
	// }

	dataFiltered: any = []
	search: string = ''

	ngOnInit(): void {
		this.dataFiltered = this.data
	}

	numCardsDisplayed: number = 4

	loadMore() {
		this.numCardsDisplayed += 2
	}

	setSearch() {
		if (this.search) {
			this.dataFiltered = this.data.filter((item: any) => {
				if (
					this.resetString(item.name).includes(
						this.resetString(this.search)
					)
				) {
					return item
				}
			})
		} else {
			this.dataFiltered = this.data
		}
	}

	resetString(str: any) {
		return str
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036F]/g, '')
	}

	@Input() data: any
}
