import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from 'src/app/services/content/content.service'

@Component({
	selector: 'app-sustentabilidade',
	templateUrl: './sustentabilidade.component.html',
	styleUrls: ['./sustentabilidade.component.scss'],
})
export class SustentabilidadeComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}
	data: any

	card = {
		title: 'Pode confiar!',
		body: 'Nosso produtos são certificados e possuem garantia de qualidade das instituições mais respeitadas do mercado. Obter certificados emitidos por órgãos independentes é uma forma de atestar que os produtos Everest seguem todas as normas de segurança, de purificação e refrigeração. É uma garantia para a empresa,   colaboradores, fornecedores e consumidores. ',
	}

	tabs = [
		{
			text: 'Purificadores',
			block: [
				{
					name: 'Lorem ipsum dolor sit amet consectetur.',
					pdf: '../../../assets/pdfs/UC01.pdf',
				},
				{
					name: 'Lorem ipsum dolor sit amet consectetur.',
					pdf: '../../../assets/pdfs/Feminicídio e Alcoolismo.pdf',
				},
			],
		},
		{
			text: 'Máquinas de gelo',
			block: [],
		},
	]

	ngOnInit(): void {
		const lang = this.route.snapshot.params.lang
		const self = this
		this.content.getPage('pages/?type=cms.Sustainability&fields=*&', lang, {
			fnSuccess(data) {
				self.data = data.items[0]
				self.title.setTitle(
					data.items[0].meta.seo_title || 'Sustentabilidade'
				)
				self.meta.addTag({
					name: 'description',
					content: data.items[0].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[0].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[0].meta.seo_title,
					},
					"property='og:title'"
				)
			},
			fnError(error) {
				console.warn(error)
			},
		})
	}
}
