<div id="wrapper-loading">
    <div class="backdrop"></div>
    <div class="glass">
        <div class="load">
            <img src="/assets/images/logo.png" alt="Everest">
        </div>
        <svg class=" water" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"  version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
             viewBox="0 0 14399 13426"
             xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Layer_x0020_1">
                <path fill="#079ee8" d="M14 13426l14385 0 0 -12524 0 -887 0 -8c-518,6 -1034,247 -1406,695 -66,79 -92,132 -165,209 -80,85 -119,113 -187,184 -785,827 -1415,-150 -1742,-491 -213,-223 -456,-442 -863,-544 -831,-206 -1490,173 -1950,784 -555,735 -1176,762 -1744,13 -600,-790 -1599,-1135 -2441,-598 -688,438 -1168,1767 -2149,844 -364,-343 -746,-1111 -1738,-1088 -32,4460 0,8943 0,13411z"/>
            </g>
        </svg>
    <div>
</div>
