import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from 'src/app/components/modals/modal-default/modal-default.component'
import { ContentService } from 'src/app/services/content/content.service'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'

@Component({
	selector: 'app-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
	form: FormGroup
	constructor(
		private formBuilder: FormBuilder,
		private content: ContentService,
		private modal: NgbModal,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {
		this.form = this.formBuilder.group({
			name: [
				null,
				[Validators.required, GenericValidatorService.completeName],
			],
			subject: [null, [Validators.required]],
			message: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
		})
	}
	options: any[] = [
		{ value: 'everest plus', label: 'Everest Plus' },
		{ value: 'everest star', label: 'Everest Star' },
		{ value: 'everest slim', label: 'Everest Slim' },
		{ value: 'everest fit', label: 'Everest Fit' },
		{ value: 'maquinas de gelo', label: 'Máquinas de gelo' },
	]

	onSubmit() {
		const self = this
		if (this.form.valid) {
			const { name, email, subject, message } = this.form.value
			this.content.sendForm('contact_us', {
				payload: {
					name,
					email,
					subject,
					message,
				},
				fnSuccess(data: any) {
					self.form.reset()
					self.openModal(
						self.modalSuccessTitle,
						self.modalSuccessText
					)
					window.dataLayer.push({
						event: 'sendContactUs',
						eventCategory: 'lead',
						eventAction: 'send',
						eventLabel: '',
					})
				},
				fnError(err: Error) {
					self.openModal(self.modalErrorTitle, self.modalErrorText)
					console.warn(err)
				},
			})
		}
	}

	openModal(
		title: string = '',
		description: string = '',
		icon: string = 'alert.svg'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.figure = '/assets/images/' + icon
		modalRef.componentInstance.showFooter = false
	}

	lang: string = 'pt'
	modalSuccessTitle: string = ''
	modalSuccessText: string = ''
	modalErrorTitle: string = ''
	modalErrorText: string = ''
	contactBannerTitle: string = 'Atendimento'
	contactText: string =
		'É essencial que o contato com nossos consumidores seja claro e transparente. Esse espaço serve para nos conectarmos. Aqui você pode dar sugestões, tirar dúvidas, fazer comentários ou reclamações.'
	contactInputName: string = 'Nome'
	contactInputEmail: string = 'E-mail'
	contactInputSubject: string = 'Sobre o que quer falar?'
	contactInputSubjectPlaceholder: string = 'selecione um assunto'
	contactInputMessage: string = 'Mensagem'
	contactInputMessagePlaceholder: string = 'Digite a sua mensagem aqui'
	contactFormButton: string = 'Enviar'

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		window.localStorage.setItem('lang', this.lang)

		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'modalSuccessTitle') {
						this.modalSuccessTitle = item.value
					} else if (item.key === 'modalSuccessText') {
						this.modalSuccessText = item.value
					} else if (item.key === 'modalErrorTitle') {
						this.modalErrorTitle = item.value
					} else if (item.key === 'modalErrorText') {
						this.modalErrorText = item.value
					} else if (item.key === 'contactBannerTitle') {
						this.contactBannerTitle = item.value
					} else if (item.key === 'contactText') {
						this.contactText = item.value
					} else if (item.key === 'contactInputName') {
						this.contactInputName = item.value
					} else if (item.key === 'contactInputEmail') {
						this.contactInputEmail = item.value
					} else if (item.key === 'contactInputSubject') {
						this.contactInputSubject = item.value
					} else if (item.key === 'contactInputSubjectPlaceholder') {
						this.contactInputSubjectPlaceholder = item.value
					} else if (item.key === 'contactInputMessage') {
						this.contactInputMessage = item.value
					} else if (item.key === 'contactInputMessagePlaceholder') {
						this.contactInputMessagePlaceholder = item.value
					} else if (item.key === 'contactFormButton') {
						this.contactFormButton = item.value
					}
				})
			},
			fnError: (error) => console.warn(error),
		})

        setTimeout(() => {
            this.title.setTitle(this.contactBannerTitle)
            this.meta.addTag({
                name: 'description',
                content: this.contactText,
            })
        }, 600)
	}
}
