import { Component, OnInit } from '@angular/core'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-downloads',
	templateUrl: './downloads-ice-machine.component.html',
	styleUrls: ['./downloads-ice-machine.component.scss'],
})
export class DownloadsIceMachineComponent implements OnInit {
	constructor(
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {}

	dataDownload: any
	downloadTitle =
		'Aqui você encontra os manuais e as certificações de nossos purificadores de água e máquinas de gelo.'
	dataTabs: any
	lang = 'pt'

	ngOnInit(): void {
		this.lang = this.route.snapshot.params.lang
		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'downloadsText') {
						this.downloadTitle = item.value
					}
				})
			},
			fnError: (error) => console.warn(error),
		})
		const self = this
		this.content.getPage('pages/?type=cms.Downloads&fields=*&', this.lang, {
			fnSuccess(data) {
				self.title.setTitle(data.items[1].meta.seo_title || 'Downloads')
				self.meta.addTag({
					name: 'description',
					content: data.items[1].meta.search_description,
				})
				self.meta.updateTag(
					{
						property: 'og:description',
						content: data.items[1].meta.search_description,
					},
					"property='og:description'"
				)
				self.meta.updateTag(
					{
						property: 'og:title',
						content: data.items[1].meta.seo_title,
					},
					"property='og:title'"
				)
				self.dataDownload = data.items[1]
			},
			fnError(err) {
				console.warn(err)
			},
		})

		this.content.getCategories('categories/?', {
			fnSuccess(data) {
				self.dataTabs = data.items.filter(
					(item: any) => item.name == 'Máquinas de Gelo Everest'
				)
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
}
