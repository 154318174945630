<figure [ngClass]="{'banner': type === 'banner', 'banner-internal': type === 'banner-internal', 'compare' :type === 'compare', 'width': width}">
	<img
		*ngIf="path"
		[defaultImage]="load ? '/assets/images/placeholder.gif' : ''"
		errorImage="../../assets/images/error-image.png"
		[lazyLoad]="path"
		[alt]="title"
		[title]="title"
        [offset]="100"
        loading="lazy"
	/>
</figure>
