import { Component, OnInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel'
import {HiddenLoading, ShowLoading} from "../../state/loading/loading.action";
import {ActivatedRoute} from "@angular/router";
import {ContentService} from "../../services/content/content.service";
import {DomSanitizer, Meta, SafeResourceUrl, Title} from "@angular/platform-browser";
@Component({
  selector: 'app-pantanal',
  templateUrl: './pantanal.component.html',
  styleUrls: ['./pantanal.component.scss']
})
export class PantanalComponent implements OnInit {

    lang: string = 'pt'
    data: any
    dataUrlVideo: SafeResourceUrl
    dataHighlight: any
    constructor(
        private activeRoute: ActivatedRoute,
        private content: ContentService,
        private title: Title,
        private meta: Meta,
        private sanitizer: DomSanitizer
    ) {
        this.lang = this.activeRoute.snapshot.params.lang
    }
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                },
            },
        ],
    }


  ngOnInit(): void {
      const self = this
      this.content.getPage(
          'pages/?type=cms.HomePantanalSosPage&fields=%2A&',
          this.lang,
          {
              fnSuccess(data) {
                  self.data = data.items[0]
                  self.dataUrlVideo = data.items[0].section_video_text_url
                  self.dataUrlVideo =
                      self.sanitizer.bypassSecurityTrustResourceUrl(
                          `${self.data.section_video_text_url}`
                      )
                  self.dataHighlight = [
                      {
                          bannerDesktop: self.data.highlight_banner_desktop,
                          bannerMobile:  self.data.highlight_banner_mobile,
                          text: self.data.highlight_text,
                          title: self.data.highlight_text_title,
                          logo: self.data.highlight_banner_logo

                      }
                  ];
                  self.title.setTitle(
                      data.items[0].meta.seo_title || 'SOS Pantanal'
                  )
                  self.meta.addTag({
                      name: 'description',
                      content: data.items[0].meta.search_description,
                  })
                  self.meta.updateTag(
                      {
                          property: 'og:description',
                          content: data.items[0].meta.search_description,
                      },
                      "property='og:description'"
                  )
                  self.meta.updateTag(
                      {
                          property: 'og:title',
                          content: data.items[0].meta.seo_title,
                      },
                      "property='og:title'"
                  )
              },
              fnError(err) {
                  console.warn(err)
              },
          }
      )
  }

}
