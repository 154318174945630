<div class="post">
	<div
		class="container"
		[ngClass]="{ 'row-reverse': type === 'reverse' }"
		(mousemove)="onMouseMove($event)"
		(mouseleave)="onMouseLeave()"
	>
		<!--        <app-image-->
		<!--            class="post-image"-->
		<!--            path="{{ cards.img }}"-->
		<!--            title=""></app-image>-->
		<figure class="post-image">
			<img [src]="cards.img" [alt]="cards.title" />
		</figure>
		<div
			class="post-blog"
			[ngStyle]="{
				transform: 'translate(' + deltaX + 'px, ' + deltaY + 'px)'
			}"
		>
			<app-white-card class="card-position" [cards]="cards" type="small">
			</app-white-card>
		</div>
	</div>
</div>
