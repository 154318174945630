<div class="container">
  <div class="highlight">
    <div class="highlight-desc">
      <h2>
        <span [innerHTML]="data.product_title"></span>
      </h2>
      <p [innerHTML]="data.product_description"></p>
    </div>
    <div class="highlight-img">
      <img loading="lazy" [src]="data.product_image.url">
    </div>
    <div class="button">
      <div>
        <a [routerLink]="data.product_cta_link" class="white">{{ data.product_cta_label }}</a>
      </div>
      <div>
        <a [href]="data.product_link" class="blue" target="_blank">{{ data.product_label }}</a>
      </div>
    </div>
  </div>
</div>