<div>
    <div class="card-container card-container desktop">
        <div class="card-dealer" *ngFor="let item of data">
            <figure>
                <img *ngIf="item.img" [src]="item.img.url" [alt]="item.title">
            </figure>
            <div class="content-text">
                <h4 class="title">{{item.title}}</h4>
                <h6 class="sub-title">{{item.subTitle}}</h6>
                <p class="text">{{item.text}}</p>
            </div>
        </div>
    </div>
    <div class="carousel mobile">
        <div class="container">
            <ngx-slick-carousel
                #slickModal="slick-carousel"
                [config]="slideConfig"
            >
                <div class="card-dealer" *ngFor="let item of data" ngxSlickItem>
                    <figure>
                        <img *ngIf="item.img" [src]="item.img.url" [alt]="item.title">
                    </figure>
                    <div class="content-text">
                        <h4 class="title">{{item.title}}</h4>
                        <h6 class="sub-title">{{item.subTitle}}</h6>
                        <p class="text">{{item.text}}</p>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>