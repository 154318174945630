<div class="container">
  <app-tabs>
    <app-tab
      *ngFor="let tab of data; let i = index"
      [tabTitle]="tab.title"
      [active]="i === 0"
    >
    <div class="products products__{{i}}">
      <div class="card" *ngFor="let item of tab.products; let i = index">
        <div class="card__img" *ngIf="item.product.gallery[0].image">
          <img [src]="item.product.gallery[0].image.url" alt="">
        </div>
        <div class="line"></div>
        <div class="card__title">
          <p>{{ item.product.name }}</p>
        </div>
          <button type="button" (click)="onClick($event, item.product.name)">
              <app-mybutton
                  type="responsive"
                  [typeBtn]="'purchase'"
                  text="Saiba Mais"
              ></app-mybutton>
          </button>
      </div>
    </div>
    <app-carousel-ice-products class="carousel" [data]="tab.products" [dataDictionary]="dataDictionary" [lang]="lang"></app-carousel-ice-products>
    </app-tab>
  </app-tabs>
</div>
