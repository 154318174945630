import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ContentService } from '../../services/content/content.service'
import { Meta, Title } from '@angular/platform-browser'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
	selector: 'app-history',
	templateUrl: './history.component.html',
	styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
	lang: string = 'pt'
	constructor(
		private activeRoute: ActivatedRoute,
		private content: ContentService,
		private title: Title,
		private meta: Meta,
		private sanitizer: DomSanitizer
	) {
		this.lang = this.activeRoute.snapshot.params.lang
	}

	dataHistory: any
	videoId: string
	videoUrl: SafeResourceUrl
	ngOnInit(): void {
		const self = this
		this.content.getPage(
			'pages/?type=cms.OurHistory&fields=%2A&',
			this.lang,
			{
				fnSuccess(data) {
					self.videoId = data.items[0].quality_video.split('v=')[1]
					self.videoUrl =
						self.sanitizer.bypassSecurityTrustResourceUrl(
							`https://www.youtube.com/embed/${self.videoId}`
						)
					self.dataHistory = data.items[0]
					self.title.setTitle(
						data.items[0].meta.seo_title || 'Nossa História'
					)
					self.meta.addTag({
						name: 'description',
						content: data.items[0].meta.search_description,
					})
					self.meta.updateTag(
						{
							property: 'og:description',
							content: data.items[0].meta.search_description,
						},
						"property='og:description'"
					)
					self.meta.updateTag(
						{
							property: 'og:title',
							content: data.items[0].meta.seo_title,
						},
						"property='og:title'"
					)
				},
				fnError(err) {
					console.warn(err)
				},
			}
		)
	}
}
