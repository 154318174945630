<div class="container" *ngIf="dataProduct">
    <div class="banner mt-5">
        <div class="banner-content">
            <div class="banner-image desktop mt-5">
                <app-image
                    *ngIf="dataProduct.banner_image !== null"
                    [path]="dataProduct.banner_primary_image.url"
                >
                </app-image>
            </div>
            <div class="banner-text">
                <div class="logo mb-5">
                    <app-image
                        *ngIf="dataProduct.banner_primary_logo !== null"
                        [path]="dataProduct.banner_primary_logo.url"
                    >
                    </app-image>
                </div>
                <div class="title" [innerHTML]="dataProduct.banner_primary_title"></div>
                <div class="banner-image mobile">
                    <app-image *ngIf="dataProduct.banner_image !== null"
                               [path]="dataProduct.banner_primary_image.url"></app-image>
                </div>
                <div id="btn">
                    <app-mybutton
                        *ngIf="dataProduct.banner_primary_link || dataProduct.banner_primary_label"
                        [text]="dataProduct.banner_primary_label" [ngStyle]="{'display': isPlus ? 'none' : 'block'}" [typeBtn]="'purchase'"
                        [href]="dataProduct.banner_primary_link">
                    </app-mybutton>
                </div>

            </div>
        </div>
    </div>
    <app-details-product [data]="dataProduct"></app-details-product>
    <div class="description">
        <app-intern-highlight-product *ngIf="dataProduct.banner_image !== null" [title]="dataProduct.first_content_title" [description]="dataProduct.first_content_text"></app-intern-highlight-product>
    </div>
    <div class="banner-blue">
        <div class="banner-blue-content">
            <div class="banner-blue-image">
                <app-image *ngIf="dataProduct.banner_image !== null"
                           [path]="dataProduct.banner_image.url"></app-image>
            </div>
            <div class="banner-blue-text">
                <div class="title"  [innerHTML]="dataProduct.banner_title"></div>
                <div class="body1" [innerHTML]="dataProduct.banner_description"></div>
            </div>
        </div>
    </div>
    <app-intern-highlight-product *ngIf="dataProduct.banner_image !== null" [title]="dataProduct.second_content_title" [description]="dataProduct.second_content_text" [highlightTitle]="dataProduct.featured_title" [image]="dataProduct.featured_image.url"></app-intern-highlight-product>
    <div class="product-footer">
        <div class="product-footer-content">
            <div class="product-footer-description">
                <p class="body1 font-weight-bold">{{ dataProduct.list_title }}</p>
                <p class="body1">{{ dataProduct.list_description }}</p>
            </div>
            <div class="product-footer-items row">
                <div class="item col-12 col-sm-6" *ngFor="let item of dataProduct.icons_list">
                    <app-image *ngIf="dataProduct.banner_image !== null"
                               [path]="item.icon.url || './assets/images/footer-product-icon.png'" [width]="true"></app-image>
                    <p class="body1">{{ item.text }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
