<section class="sustentabilidade-wrapper" *ngIf="data">
    <img class="leaf-left" src="./assets/images/left-leaft.png">
    <div class="container-sustentabilidade">
        <div class="left">
            <div class="left-wrapper">
                <img src="./assets/images/SELO_LOGÍSTICA.png" class="logo" />
                <p class="desc-title" [innerHTML]="data.banner_title"></p>
                <p class="desc" [innerHTML]="data.banner_text"></p>
                <a href="https://abree.org.br/" target="_blank">
                    <app-mybutton [text]="data.banner_button_label" type="large"></app-mybutton>
                </a>
            </div>
        </div>
        <div class="right">
            <img src="./assets/images/right-earth.png">
        </div>
    </div>
    <img src="./assets/images/leaf-blue.png" class="interception">
    <div class="logistica-wrapper">
        <div class="logistica-container">
            <div class="left">
                <h6 [innerHTML]="data.eco_title"></h6>
                <p>{{ data.eco_text }}</p>
                <img src="./assets/images/image-logistica.png">
            </div>
            <div class="right">
                <div *ngFor="let item of data.list_items">
                    <img [src]="item.icon.url || './assets/images/footer-product-icon.png'">
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.text }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="world-wrapper">
        <img src="./assets/images/leaf-right.png" class="leaf-right">
        <div class="world-container">
            <div class="left">
                <h2>{{ data.better_word_title }}</h2>
                <p>{{ data.better_word_text }}</p>
            </div>
            <div class="right">
                <img [src]="data.better_word_image || './assets/images/gela.png'">
            </div>
        </div>
    </div>
    <div class="saiba-wrapper">
        <img [src]="data.abree_icon || './assets/images/pingo.png'">
        <p [innerText]="data.abree_text"></p>
        <img src="./assets/images/SELO_LOGÍSTICA.png" class="logo" width="100px" />
        <!-- <h2>ABREE</h2> -->
    </div>
</section>
