import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-compare-table',
	templateUrl: './compare-table.component.html',
	styleUrls: ['./compare-table.component.scss'],
})
export class CompareTableComponent implements OnInit {
	@Output() tableClose: EventEmitter<boolean> = new EventEmitter()
	@Input() list: any[] = []
	@Input() showTable = true
	@Input() lang = 'pt'
	@Input() iceMachinePage = false
	@Input() fields = {
		compareTableTitle: 'Comparação de produtos:',
		compareTableName: 'Nome do produto',
		compareTableModel: 'Modelo',
		compareTableIceShape: 'Tipo de gelo',
		compareTableProduction: 'Produção kg/24h',
		compareTableDeposit: 'Capacidade de armazenamento',
		compareTableWeight: 'Peso líquido',
		compareTableWeightBrute: 'Peso bruto',
		compareTableVolumes: 'Volumes',
		compareTableCabinet: 'Gabinete',
		compareTableHp: 'HP Nominal',
		compareTableColor: 'Cor',
		compareTableTemperature: 'Temperatura da água',
		compareTableSize: 'Tamanho',
		compareTableHeight: 'Altura',
		compareTableWidth: 'Largura',
		compareTableDepth: 'Profundidade',
		compareTableTechnicalSpecification: 'Informações Adicionais',
		compareTableBuyButton: 'Comprar agora',
		compareTableKnowButton: 'Conhecer produto',
	}

	closeTable() {
		this.showTable = false
		this.tableClose.emit(false)
	}

	constructor() {}

	ngOnInit(): void {
	}
}
